<template>
  <div :style="funnyProps" class="overlay-cookie-banner-content">
    <div class="overlay-cookie-banner-content__wrap">
      <div class="overlay-cookie-banner-content__inner">
        {{ $t('cookie_banner.text') }}
        <button
          class="overlay-cookie-banner-content__btn btn btn--primary btn--small"
          @click="onAccept"
        >
          {{ $t('cookie_banner.accept_label') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import { randomNumber } from '@/assets/js/util/misc/number'

  export default {
    props: {},
    data() {
      return {
        top: 0,
        left: 0,
        rotate: 0,
      }
    },
    computed: {
      ...mapState('viewport', { viewportWidth: 'width', viewportHeight: 'height' }),
      funnyProps() {
        return {
          top: `${this.top}px`,
          left: `${this.left}px`,
          transform: `rotateZ(${this.rotate}deg)`,
        }
      },
    },
    watch: {
      viewportWidth() {
        this.updateFunnyProps()
      },
      viewportHeight() {
        this.updateFunnyProps()
      },
    },
    mounted() {
      this.setFunnyProps()
    },
    methods: {
      ...mapActions('cookie-banner', ['acceptCookies']),
      onAccept() {
        this.acceptCookies()
      },
      setFunnyProps() {
        const { width, height } = this.$el.getBoundingClientRect()

        this.top = randomNumber(0, window.innerHeight - height)
        this.left = randomNumber(0, window.innerWidth - width)
        this.rotate = randomNumber(-20, 20)
      },
      updateFunnyProps() {
        const { width, height } = this.$el.getBoundingClientRect()

        this.top = Math.max(0, Math.min(this.top, this.viewportHeight - height))
        this.left = Math.max(0, Math.min(this.left, this.viewportWidth - width))
      },
    },
  }
</script>

<style lang="scss" src="./_overlay-cookie-banner-content.scss" />
