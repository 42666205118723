import { wizardStepIds, wizardStepParams } from '@/assets/js/model/route'
import { personaIds } from '@/assets/js/model/persona'
import { mapConfigIds } from '@/assets/js/model/map-config'
import { layerIds } from '@/assets/js/model/layer'
import { projectionIds } from '@/assets/js/model/projection'
import { renderingAdapterIds } from '@/assets/js/model/export'
import { mapThemeIds } from '@/assets/js/model/map-theme'

export const wizardStepList = [
  {
    id: wizardStepIds.PERSONA_WIZARD_AMUNDSEN_STEP_1,
    personaId: personaIds.AMUNDSEN,
    label: `data_model.persona.${personaIds.AMUNDSEN}.stations[0]`,
    param: wizardStepParams[wizardStepIds.PERSONA_WIZARD_AMUNDSEN_STEP_1],
    icon: '/images/wizard_step_icons/amundsen/01_nav_image_streng_ausblick_50_50@3x.png',
    inNav: true,
    component: () => import('@/components/wizard-step-persona-content/wizard-step-persona-content'),
    mapConfig: {
      id: mapConfigIds.PERSONA_WIZARD,
      layerIds: [
        layerIds.CLIP_PATH,
        layerIds.SPHERE,
        layerIds.GRATICULES,
        layerIds.LAND,
        layerIds.OCEAN,
        layerIds.COASTLINE,
        layerIds.PERSONA_AMUNDSEN_JOURNEY,
        layerIds.PERSONA_AMUNDSEN_STATIONS,
      ],
      projectionId: projectionIds.FAHEY,
      renderingAdapterId: renderingAdapterIds.SVG,
      mapThemeId: mapThemeIds.PERSONA_AMUNDSEN_INITIAL,
      center: [0, 0],
      rotation: [-10.75630187988281, -59.9113202152997, 0],
      zoom: 1,
      wizardStepProgressIdx: 0,
      version: '1.0.0',
    },
  },
  {
    id: wizardStepIds.PERSONA_WIZARD_AMUNDSEN_STEP_2,
    personaId: personaIds.AMUNDSEN,
    label: `data_model.persona.${personaIds.AMUNDSEN}.stations[1]`,
    param: wizardStepParams[wizardStepIds.PERSONA_WIZARD_AMUNDSEN_STEP_2],
    icon: '/images/wizard_step_icons/amundsen/02_nav_image_amundsen_seiloffen_50_50@3x.png',
    inNav: true,
    component: () => import('@/components/wizard-step-persona-content/wizard-step-persona-content'),
    mapConfig: {
      id: mapConfigIds.PERSONA_WIZARD,
      layerIds: [
        layerIds.CLIP_PATH,
        layerIds.SPHERE,
        layerIds.GRATICULES,
        layerIds.LAND,
        layerIds.OCEAN,
        layerIds.COASTLINE,
        layerIds.PERSONA_AMUNDSEN_JOURNEY,
        layerIds.PERSONA_AMUNDSEN_STATIONS,
      ],
      projectionId: projectionIds.AZIMUTHAL_EQUIDISTANT,
      renderingAdapterId: renderingAdapterIds.SVG,
      mapThemeId: mapThemeIds.PERSONA_AMUNDSEN_INITIAL,
      center: [0, 0],
      rotation: [16.90933227539062, -32.646313145909126, 0],
      zoom: 1,
      wizardStepProgressIdx: 0,
      version: '1.0.0',
    },
  },
  {
    id: wizardStepIds.PERSONA_WIZARD_AMUNDSEN_STEP_3,
    personaId: personaIds.AMUNDSEN,
    label: `data_model.persona.${personaIds.AMUNDSEN}.stations[2]`,
    param: wizardStepParams[wizardStepIds.PERSONA_WIZARD_AMUNDSEN_STEP_3],
    icon: '/images/wizard_step_icons/amundsen/03_nav_image_amundsen_ausblick_50_50@3x.png',
    inNav: true,
    component: () => import('@/components/wizard-step-persona-content/wizard-step-persona-content'),
    mapConfig: {
      id: mapConfigIds.PERSONA_WIZARD,
      layerIds: [
        layerIds.CLIP_PATH,
        layerIds.SPHERE,
        layerIds.GRATICULES,
        layerIds.LAND,
        layerIds.OCEAN,
        layerIds.COASTLINE,
        layerIds.PERSONA_AMUNDSEN_JOURNEY,
        layerIds.PERSONA_AMUNDSEN_STATIONS,
      ],
      projectionId: projectionIds.AZIMUTHAL_EQUIDISTANT,
      renderingAdapterId: renderingAdapterIds.SVG,
      mapThemeId: mapThemeIds.PERSONA_AMUNDSEN,
      center: [0, 0],
      rotation: [164.0478515625, 78.9545598577521, 0],
      zoom: 1.7,
      wizardStepProgressIdx: 0,
      version: '1.0.0',
    },
  },
  {
    id: wizardStepIds.PERSONA_WIZARD_AMUNDSEN_STEP_4,
    personaId: personaIds.AMUNDSEN,
    label: `data_model.persona.${personaIds.AMUNDSEN}.stations[3]`,
    param: wizardStepParams[wizardStepIds.PERSONA_WIZARD_AMUNDSEN_STEP_4],
    icon: '/images/wizard_step_icons/amundsen/04_nav_image_amundsen_ausblick_50_50@3x.png',
    inNav: true,
    component: () => import('@/components/wizard-step-persona-content/wizard-step-persona-content'),
    mapConfig: {
      id: mapConfigIds.PERSONA_WIZARD,
      layerIds: [
        layerIds.CLIP_PATH,
        layerIds.SPHERE,
        layerIds.GRATICULES,
        layerIds.LAND,
        layerIds.OCEAN,
        layerIds.COASTLINE,
        layerIds.PERSONA_AMUNDSEN_JOURNEY,
        layerIds.PERSONA_AMUNDSEN_STATIONS,
      ],
      projectionId: projectionIds.AZIMUTHAL_EQUIDISTANT,
      renderingAdapterId: renderingAdapterIds.SVG,
      mapThemeId: mapThemeIds.PERSONA_AMUNDSEN,
      center: [0, 0],
      rotation: [45, 90, -135],
      zoom: 5,
      wizardStepProgressIdx: 0,
      version: '1.0.0',
    },
  },
  {
    id: wizardStepIds.PERSONA_WIZARD_AMUNDSEN_STEP_5,
    personaId: personaIds.AMUNDSEN,
    label: `data_model.persona.${personaIds.AMUNDSEN}.stations[4]`,
    param: wizardStepParams[wizardStepIds.PERSONA_WIZARD_AMUNDSEN_STEP_5],
    icon: '/images/wizard_step_icons/amundsen/05_nav_image_amundsen_ausblick_150_150@3x.png',
    inNav: true,
    component: () => import('@/components/wizard-step-persona-content/wizard-step-persona-content'),
    mapConfig: {
      id: mapConfigIds.PERSONA_WIZARD,
      layerIds: [
        layerIds.CLIP_PATH,
        layerIds.SPHERE,
        layerIds.GRATICULES,
        layerIds.LAND,
        layerIds.OCEAN,
        layerIds.COASTLINE,
        layerIds.LAKES,
        layerIds.RIVERS,
        layerIds.PERSONA_AMUNDSEN_JOURNEY,
        layerIds.PERSONA_AMUNDSEN_STATIONS,
      ],
      projectionId: projectionIds.AZIMUTHAL_EQUIDISTANT,
      renderingAdapterId: renderingAdapterIds.SVG,
      mapThemeId: mapThemeIds.PERSONA_AMUNDSEN,
      center: [0, 0],
      rotation: [212.64587402343747, 42.884014670442525, 0],
      zoom: 2,
      wizardStepProgressIdx: 0,
      version: '1.0.0',
    },
  },
  {
    id: wizardStepIds.PERSONA_WIZARD_AMUNDSEN_STEP_6,
    personaId: personaIds.AMUNDSEN,
    label: `data_model.persona.${personaIds.AMUNDSEN}.stations[5]`,
    param: wizardStepParams[wizardStepIds.PERSONA_WIZARD_AMUNDSEN_STEP_6],
    icon: '/images/wizard_step_icons/amundsen/06_nav_image_amundsen_ausblick_50_50@3x.png',
    inNav: true,
    component: () => import('@/components/wizard-step-persona-content/wizard-step-persona-content'),
    mapConfig: {
      id: mapConfigIds.PERSONA_WIZARD,
      layerIds: [
        layerIds.CLIP_PATH,
        layerIds.SPHERE,
        layerIds.GRATICULES,
        layerIds.LAND,
        layerIds.OCEAN,
        layerIds.COASTLINE,
        layerIds.LAKES,
        layerIds.RIVERS,
        layerIds.PERSONA_AMUNDSEN_JOURNEY,
        layerIds.PERSONA_AMUNDSEN_STATIONS,
      ],
      projectionId: projectionIds.AZIMUTHAL_EQUIDISTANT,
      renderingAdapterId: renderingAdapterIds.SVG,
      mapThemeId: mapThemeIds.PERSONA_AMUNDSEN,
      center: [0, 0],
      rotation: [-10.75630187988281, -59.9113202152997, 0],
      zoom: 1,
      wizardStepProgressIdx: 0,
      version: '1.0.0',
    },
  },
]
