<template>
  <div :class="bemFacets" class="interstitial-content">
    <div class="interstitial-content__layout layout layout--primary">
      <div class="interstitial-content__layout-container layout__container">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="interstitial-content__text-wrap" v-html="$t(text)" />
        <div class="interstitial-content__image-wrap">
          <div v-if="forwardLabel" class="interstitial-content__forward-wrap">
            <button class="u-reset interstitial-content__forward-btn" @click="onForwardClick">
              <span class="interstitial-content__forward-btn-text">
                {{ $t(forwardLabel) }}
              </span>
              <responsive-img
                :width="21"
                alt=""
                class="interstitial-content__forward-btn-image interstitial-content__forward-btn-image--large"
                src="/images/arrow_icons/custom/arrow_down_neg@3x.png"
              />
              <responsive-img
                :width="41"
                alt=""
                class="interstitial-content__forward-btn-image interstitial-content__forward-btn-image--small"
                src="/images/arrow_icons/custom/arrow_right_neg@3x.png"
              />
            </button>
          </div>
          <responsive-img
            v-if="image"
            :src="image"
            :width="660"
            alt=""
            class="interstitial-content__image"
          />
        </div>
        <div class="interstitial-content__cta-wrap">
          <slot name="cta-link" />
        </div>
      </div>
    </div>
    <div class="interstitial-content__timer-bar" />
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import bemMixin from '@/assets/js/mixin/bem'
  import { getImage } from '@/assets/js/util/misc/image'
  import ResponsiveImg from '~/components/responsive-img/responsive-img'

  export default {
    components: { ResponsiveImg },
    mixins: [bemMixin('interstitial-content')],
    props: {
      text: {
        type: String,
        default: null,
      },
      forwardLabel: {
        type: String,
        default: null,
      },
      image: {
        type: String,
        required: true,
      },
      hasForwardBtn: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        isMounted: false,
      }
    },
    mounted() {
      setTimeout(() => {
        this.isMounted = true
      }, 1)
    },
    methods: {
      ...mapActions('interstitial', ['setInterstitial']),
      onForwardClick() {
        this.setInterstitial({
          isOpen: false,
        })
      },
    },
    getImage,
  }
</script>

<style lang="scss" src="./_interstitial-content.scss" />
