import * as wizardCustom from './wizard-steps/wizard-custom'
import * as wizardPersonaAmundsen from './wizard-steps/wizard-persona-amundsen'
import * as wizardPersonaJack from './wizard-steps/wizard-persona-jack'
import * as wizardPersonaMerian from './wizard-steps/wizard-persona-merian'

export { wizardStepIds } from '@/assets/js/model/route'

export const wizardStepList = [
  ...wizardCustom.wizardStepList,
  ...wizardPersonaAmundsen.wizardStepList,
  ...wizardPersonaJack.wizardStepList,
  ...wizardPersonaMerian.wizardStepList,
]
