import { pack, unpack } from '@/assets/js/util/misc/json-data'

export function mapConfig() {
  if (!process.client) {
    return
  }

  if ($nuxt.$route.query.config) {
    return unpack($nuxt.$route.query.config)
  }
}

export async function createImageHash(mapConfig) {
  const buffer = new TextEncoder().encode(pack(mapConfig))
  const digest = await crypto.subtle.digest('SHA-1', buffer)

  return Array.from(new Uint8Array(digest))
    .map((x) => x.toString(16).padStart(2, '0'))
    .join('')
}
