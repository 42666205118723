import { layerIds } from '@/assets/js/model/layer'
import { styleAttributeIds } from '@/assets/js/model/style-attributes'
import { mapThemeTypeIds } from '@/assets/js/model/map-theme-type'

export const mapThemeIds = {
  EARTH_TONES_1: 'EARTH_TONES_1',
  EARTH_TONES_2: 'EARTH_TONES_2',
  EARTH_TONES_3: 'EARTH_TONES_3',
  EARTH_TONES_4: 'EARTH_TONES_4',
  EARTH_TONES_5: 'EARTH_TONES_5',
  EARTH_TONES_6: 'EARTH_TONES_6',
}

export const mapThemeList = [
  {
    id: mapThemeIds.EARTH_TONES_1,
    mapThemeType: mapThemeTypeIds.EARTH_TONES,
    label: `data_model.map_theme.${mapThemeIds.EARTH_TONES_1}`,
    layerStyleList: [
      {
        layerIds: [layerIds.BACKGROUND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#382f32',
        },
      },
      {
        layerIds: [layerIds.SPHERE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#c7b570',
          [styleAttributeIds.LINE_WIDTH]: 4,
        },
      },
      {
        layerIds: [layerIds.LINES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#0a3542',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.GRATICULES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#c6cdc7',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
          [styleAttributeIds.LINE_DASH]: [2, 2],
        },
      },
      {
        layerIds: [layerIds.LAND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#c7b570',
        },
      },
      {
        layerIds: [layerIds.OCEAN],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#507282',
        },
      },
      {
        layerIds: [layerIds.COASTLINE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#aa7455',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
        },
      },
      {
        layerIds: [layerIds.LAKES],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#507282',
          [styleAttributeIds.LINE_COLOR]: '#507282',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.RIVERS],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#507282',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.COUNTRIES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#0a3542',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.TISSOT_INDICATRIX],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#ffffffcc',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
          [styleAttributeIds.FILL_COLOR]: '#ffffff4d',
        },
      },
    ],
  },
  {
    id: mapThemeIds.EARTH_TONES_2,
    mapThemeType: mapThemeTypeIds.EARTH_TONES,
    label: `data_model.map_theme.${mapThemeIds.EARTH_TONES_2}`,
    layerStyleList: [
      {
        layerIds: [layerIds.BACKGROUND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#382f32',
        },
      },
      {
        layerIds: [layerIds.SPHERE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#aa7455',
          [styleAttributeIds.LINE_WIDTH]: 4,
        },
      },
      {
        layerIds: [layerIds.LINES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#0a3542',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.GRATICULES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#c6cdc7',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
          [styleAttributeIds.LINE_DASH]: [2, 2],
        },
      },
      {
        layerIds: [layerIds.LAND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#aa7455',
        },
      },
      {
        layerIds: [layerIds.OCEAN],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#7895a4',
        },
      },
      {
        layerIds: [layerIds.COASTLINE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#0a3542',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
        },
      },
      {
        layerIds: [layerIds.LAKES],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#7895a4',
          [styleAttributeIds.LINE_COLOR]: '#7895a4',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.RIVERS],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#7895a4',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.COUNTRIES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#0a3542',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.TISSOT_INDICATRIX],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#ffffffcc',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
          [styleAttributeIds.FILL_COLOR]: '#ffffff4d',
        },
      },
    ],
  },
  {
    id: mapThemeIds.EARTH_TONES_3,
    mapThemeType: mapThemeTypeIds.EARTH_TONES,
    label: `data_model.map_theme.${mapThemeIds.EARTH_TONES_3}`,
    layerStyleList: [
      {
        layerIds: [layerIds.BACKGROUND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#382f32',
        },
      },
      {
        layerIds: [layerIds.SPHERE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#c7b570',
          [styleAttributeIds.LINE_WIDTH]: 4,
        },
      },
      {
        layerIds: [layerIds.LINES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#0a3542',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.GRATICULES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#fec77c',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
          [styleAttributeIds.LINE_DASH]: [2, 2],
        },
      },
      {
        layerIds: [layerIds.LAND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#c7b570',
        },
      },
      {
        layerIds: [layerIds.OCEAN],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#774f3c',
        },
      },
      {
        layerIds: [layerIds.COASTLINE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#0a3542',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
        },
      },
      {
        layerIds: [layerIds.LAKES],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#774f3c',
          [styleAttributeIds.LINE_COLOR]: '#774f3c',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.RIVERS],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#774f3c',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.COUNTRIES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#0a3542',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.TISSOT_INDICATRIX],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#ffffffcc',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
          [styleAttributeIds.FILL_COLOR]: '#ffffff4d',
        },
      },
    ],
  },
  {
    id: mapThemeIds.EARTH_TONES_4,
    mapThemeType: mapThemeTypeIds.EARTH_TONES,
    label: `data_model.map_theme.${mapThemeIds.EARTH_TONES_4}`,
    layerStyleList: [
      {
        layerIds: [layerIds.BACKGROUND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#382f32',
        },
      },
      {
        layerIds: [layerIds.SPHERE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#c7b570',
          [styleAttributeIds.LINE_WIDTH]: 4,
        },
      },
      {
        layerIds: [layerIds.LINES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#6e2405',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.GRATICULES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#c6cdc7',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
          [styleAttributeIds.LINE_DASH]: [2, 2],
        },
      },
      {
        layerIds: [layerIds.LAND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#c7b570',
        },
      },
      {
        layerIds: [layerIds.OCEAN],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#0a3542',
        },
      },
      {
        layerIds: [layerIds.COASTLINE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#6e2405',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
        },
      },
      {
        layerIds: [layerIds.LAKES],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#0a3542',
          [styleAttributeIds.LINE_COLOR]: '#0a3542',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.RIVERS],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#0a3542',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.COUNTRIES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#6e2405',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.TISSOT_INDICATRIX],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#ffffffcc',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
          [styleAttributeIds.FILL_COLOR]: '#ffffff4d',
        },
      },
    ],
  },
  {
    id: mapThemeIds.EARTH_TONES_5,
    mapThemeType: mapThemeTypeIds.EARTH_TONES,
    label: `data_model.map_theme.${mapThemeIds.EARTH_TONES_5}`,
    layerStyleList: [
      {
        layerIds: [layerIds.BACKGROUND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#382f32',
        },
      },
      {
        layerIds: [layerIds.SPHERE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#774f3c',
          [styleAttributeIds.LINE_WIDTH]: 4,
        },
      },
      {
        layerIds: [layerIds.LINES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#0a3542',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.GRATICULES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#6e2405',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
          [styleAttributeIds.LINE_DASH]: [2, 2],
        },
      },
      {
        layerIds: [layerIds.LAND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#774f3c',
        },
      },
      {
        layerIds: [layerIds.OCEAN],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#e2d2a6',
        },
      },
      {
        layerIds: [layerIds.COASTLINE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#0a3542',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
        },
      },
      {
        layerIds: [layerIds.LAKES],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#e2d2a6',
          [styleAttributeIds.LINE_COLOR]: '#e2d2a6',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.RIVERS],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#e2d2a6',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.COUNTRIES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#c6cdc7',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.TISSOT_INDICATRIX],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#ffffffcc',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
          [styleAttributeIds.FILL_COLOR]: '#ffffff4d',
        },
      },
    ],
  },
  {
    id: mapThemeIds.EARTH_TONES_6,
    mapThemeType: mapThemeTypeIds.EARTH_TONES,
    label: `data_model.map_theme.${mapThemeIds.EARTH_TONES_6}`,
    layerStyleList: [
      {
        layerIds: [layerIds.BACKGROUND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#382f32',
        },
      },
      {
        layerIds: [layerIds.SPHERE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#e2d2a6',
          [styleAttributeIds.LINE_WIDTH]: 4,
        },
      },
      {
        layerIds: [layerIds.LINES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#0a3542',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.GRATICULES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#e2d2a6',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
          [styleAttributeIds.LINE_DASH]: [2, 2],
        },
      },
      {
        layerIds: [layerIds.LAND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#e2d2a6',
        },
      },
      {
        layerIds: [layerIds.OCEAN],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#7895a4',
        },
      },
      {
        layerIds: [layerIds.COASTLINE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#0a3542',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
        },
      },
      {
        layerIds: [layerIds.LAKES],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#7895a4',
          [styleAttributeIds.LINE_COLOR]: '#7895a4',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.RIVERS],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#7895a4',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.COUNTRIES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#aa7455',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.TISSOT_INDICATRIX],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#ffffffcc',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
          [styleAttributeIds.FILL_COLOR]: '#ffffff4d',
        },
      },
    ],
  },
]
