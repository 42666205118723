<template>
  <img v-bind="rootAttrs" :loading="loading" />
</template>

<script>
  export default {
    props: {
      src: {
        type: String,
        required: true,
      },
      width: {
        type: Number,
        required: true,
      },
      loading: {
        type: String,
        default: 'auto',
        validator(value) {
          return ['auto', 'eager', 'lazy'].includes(value)
        },
      },
      resolutions: {
        type: Array,
        default() {
          return [1, 2, 3]
        },
      },
    },
    computed: {
      maxResolution() {
        return Math.max(...this.resolutions)
      },
      rootAttrs() {
        return this.getResponsiveImage(this.src, this.width)
      },
    },
    methods: {
      getResponsiveImage(path, w) {
        let sizes
        const src = `/${path.replace(/(^\/)|(\/$)/g, '')}`
        const srcset = this.resolutions
          .map((m) => `${src}?nf_resize=fit&w=${w * m} ${w * m}w`)
          .join(', ')

        if (this.$config.displayDevImages) {
          const dppx = process.client ? window.devicePixelRatio : 1
          sizes = `${(w / this.maxResolution) * dppx}px`
        } else {
          sizes = `${w}px`
        }

        return {
          srcset,
          sizes,
          src,
        }
      },
    },
  }
</script>
