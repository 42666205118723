import { personaIds, personaList } from '@/assets/js/model/persona'
import { pageThemeIds, pageThemes } from '@/assets/js/model/page-theme'
import { wizardStepList } from '@/assets/js/model/wizard-steps'

const defaultPersona = personaList.find(({ id }) => id === personaIds.CUSTOM)
const defaultPageTheme = pageThemes.find(({ id }) => id === pageThemeIds.CUSTOM)

/**
 * Get the active persona
 * @param {function} _ - See vuex documentation
 * @param {function} __ - See vuex documentation
 * @param {function} rootState - See vuex documentation
 * @return {any} The active persona
 */
export const activePersona = (_, __, rootState) => {
  return personaList.find(({ param }) => param === rootState.route.params.persona)
}

/**
 * Get the active page theme
 * @param {function} _ - See vuex documentation
 * @param {function} getters - See vuex documentation
 * @return {any}
 */
export const activePageTheme = (_, getters) => {
  if (getters.activePersona) {
    return pageThemes.find(({ id }) => id === getters.activePersona.pageThemeId)
  }

  return defaultPageTheme
}

/**
 * Get the active wizard step list
 * @param {function} _ - See vuex documentation
 * @param {function} getters - See vuex documentation
 * @return {any}
 */
export const activeWizardStepList = (_, getters) => {
  return wizardStepList.filter(({ personaId }) => {
    return personaId === (getters.activePersona ? getters.activePersona.id : defaultPersona.id)
  })
}

/**
 * Get the active wizard step
 * @param {function} _ - See vuex documentation
 * @param {function} getters - See vuex documentation
 * @param {function} rootState - See vuex documentation
 * @return {any}
 */
export const activeWizardStep = (_, getters, rootState) => {
  return getters.activeWizardStepList.find(({ param }) => param === rootState.route.params.step)
}
