<template>
  <component :is="name" :class="rootClasses" class="ui-icon" />
</template>

<script>
  import { kebabCase } from 'lodash-es'
  import bemMixin from '@/assets/js/mixin/bem'
  import CheckboxTick from '@/assets/svg/checkbox_tick.svg?inline'
  import Close from '@/assets/svg/close.svg?inline'

  const components = {
    CheckboxTick,
    Close,
  }

  export default {
    components,
    mixins: [bemMixin('ui-icon')],
    props: {
      name: {
        type: String,
        required: true,
        validator(value) {
          return Object.keys(components).some((component) => {
            return component === value || kebabCase(component) === value
          })
        },
      },
      size: {
        type: String,
        default: null,
      },
    },
    computed: {
      rootClasses() {
        return [...this.bemFacets, this.bemAdd(this.size)]
      },
    },
  }
</script>

<style lang="scss" src="./_ui-icon.scss" />
