export const pageThemeIds = {
  CUSTOM: 'CUSTOM',
  MERIAN: 'MERIAN',
  AMUNDSEN: 'AMUNDSEN',
  JACK: 'JACK',
}

export const pageThemes = [
  {
    id: pageThemeIds.CUSTOM,
    arrow: {
      dir: '/images/arrow_icons/custom',
      use: {
        page_header: 'neg',
        intro_link: 'pos',
        wizard_navigation: 'pos',
      },
    },
    cssVariables: {
      mainColor: '#382f32',
      textColor: '#fffcdb',
      tooltipColor: '#fffcdb',
      wizardNavColor: '#d1e78e',
    },
  },
  {
    id: pageThemeIds.AMUNDSEN,
    arrow: {
      dir: '/images/arrow_icons/amundsen',
      use: {
        page_header: 'pos',
        intro_link: 'pos',
        wizard_navigation: 'pos',
      },
    },
    cssVariables: {
      mainColor: '#fffcdb',
      textColor: '#382f32',
      tooltipColor: '#fffcdb',
      wizardNavColor: '#ce4b46',
    },
  },
  {
    id: pageThemeIds.JACK,
    arrow: {
      dir: '/images/arrow_icons/jack',
      use: {
        page_header: 'neg',
        intro_link: 'pos',
        wizard_navigation: 'pos',
      },
    },
    cssVariables: {
      mainColor: '#0093d4',
      textColor: '#fffcdb',
      tooltipColor: '#0093d4',
      wizardNavColor: '#ffe869',
    },
  },
  {
    id: pageThemeIds.MERIAN,
    arrow: {
      dir: '/images/arrow_icons/custom',
      use: {
        page_header: 'pos',
        intro_link: 'pos',
        wizard_navigation: 'pos',
      },
    },
    cssVariables: {
      mainColor: '#d1e78e',
      textColor: '#382f32',
      tooltipColor: '#d1e78e',
      wizardNavColor: '#d1e78e',
    },
  },
]
