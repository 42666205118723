import { gsap } from 'gsap'

export function randomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const randomRotation = gsap.utils.random(-180, 180, 1, true)

export function roundToDecimals(n, places) {
  return Math.round((n + Number.EPSILON) * 10 ** places) / 10 ** places
}
