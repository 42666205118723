import Cookies from 'js-cookie'

export const INTERSTITIAL_CONTENT_COOKIE_NAME = 'INTERSTITIAL_CONTENT_OPENED_OVERLAY_LIST'

export function parseInterstitialCookie() {
  const interstitialContentCookie = Cookies.get(INTERSTITIAL_CONTENT_COOKIE_NAME)

  if (!interstitialContentCookie) {
    return []
  }

  const interstitialContentList = JSON.parse(interstitialContentCookie)

  if (Array.isArray(interstitialContentList)) {
    return interstitialContentList
  }

  return []
}
