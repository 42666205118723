import * as mapThemeBlueTones from './map-theme/blue-tones'
import * as mapThemeCustomWizard from './map-theme/custom-wizard'
import * as mapThemeNeon from './map-theme/neon'
import * as mapThemePersona from './map-theme/persona'
import * as mapThemeColorful from './map-theme/colorful'
import * as mapThemeToneInTone from './map-theme/tone-in-tone'
import * as mapThemeEarthTones from './map-theme/earth-tones'

export const mapThemeIds = {
  ...mapThemePersona.mapThemeIds,
  ...mapThemeCustomWizard.mapThemeIds,
  ...mapThemeNeon.mapThemeIds,
  ...mapThemeBlueTones.mapThemeIds,
  ...mapThemeColorful.mapThemeIds,
  ...mapThemeToneInTone.mapThemeIds,
  ...mapThemeEarthTones.mapThemeIds,
}

export const mapThemeList = [
  ...mapThemePersona.mapThemeList,
  ...mapThemeCustomWizard.mapThemeList,
  ...mapThemeNeon.mapThemeList,
  ...mapThemeBlueTones.mapThemeList,
  ...mapThemeColorful.mapThemeList,
  ...mapThemeToneInTone.mapThemeList,
  ...mapThemeEarthTones.mapThemeList,
]
