import { wizardStepIds } from '@/assets/js/model/route'
import { personaIds } from '@/assets/js/model/persona'

export const interstitialContentIds = {
  PERSONA_WIZARD_AMUNDSEN_0: 'PERSONA_WIZARD_AMUNDSEN_0',
  PERSONA_WIZARD_AMUNDSEN_1: 'PERSONA_WIZARD_AMUNDSEN_1',
  PERSONA_WIZARD_AMUNDSEN_2: 'PERSONA_WIZARD_AMUNDSEN_2',
  PERSONA_WIZARD_AMUNDSEN_3: 'PERSONA_WIZARD_AMUNDSEN_3',
  PERSONA_WIZARD_AMUNDSEN_4: 'PERSONA_WIZARD_AMUNDSEN_4',
  PERSONA_WIZARD_AMUNDSEN_5: 'PERSONA_WIZARD_AMUNDSEN_5',
  PERSONA_WIZARD_AMUNDSEN_6: 'PERSONA_WIZARD_AMUNDSEN_6',
}

export const interstitialContentList = [
  {
    id: interstitialContentIds.PERSONA_WIZARD_AMUNDSEN_0,
    text: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_AMUNDSEN_0}.text`,
    forwardLabel: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_AMUNDSEN_0}.forwardLabel`,
    hasForwardBtn: false,
    image: '/images/wizard_interstitial_images/amundsen/00_eingangsbild_amundsen_641_757@3x.png',
    wizardStepId: null,
    wizardStepIdx: null,
    personaId: personaIds.AMUNDSEN,
  },
  {
    id: interstitialContentIds.PERSONA_WIZARD_AMUNDSEN_1,
    text: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_AMUNDSEN_1}.text`,
    forwardLabel: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_AMUNDSEN_1}.forwardLabel`,
    image: '/images/wizard_interstitial_images/amundsen/01_amundsen_pelz_446_500@3x.png',
    wizardStepId: wizardStepIds.PERSONA_WIZARD_AMUNDSEN_STEP_1,
    wizardStepIdx: 0,
    personaId: personaIds.AMUNDSEN,
  },
  {
    id: interstitialContentIds.PERSONA_WIZARD_AMUNDSEN_2,
    text: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_AMUNDSEN_2}.text`,
    forwardLabel: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_AMUNDSEN_2}.forwardLabel`,
    image: '/images/wizard_interstitial_images/amundsen/02_amundsen_seil_446_500@3x.png',
    wizardStepId: wizardStepIds.PERSONA_WIZARD_AMUNDSEN_STEP_2,
    wizardStepIdx: 1,
    personaId: personaIds.AMUNDSEN,
  },
  {
    id: interstitialContentIds.PERSONA_WIZARD_AMUNDSEN_3,
    text: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_AMUNDSEN_3}.text`,
    forwardLabel: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_AMUNDSEN_3}.forwardLabel`,
    image: '/images/wizard_interstitial_images/amundsen/03_amundsen_seil_446_500@3x.png',
    wizardStepId: wizardStepIds.PERSONA_WIZARD_AMUNDSEN_STEP_3,
    wizardStepIdx: 2,
    personaId: personaIds.AMUNDSEN,
  },
  {
    id: interstitialContentIds.PERSONA_WIZARD_AMUNDSEN_4,
    text: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_AMUNDSEN_4}.text`,
    forwardLabel: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_AMUNDSEN_4}.forwardLabel`,
    image: '/images/wizard_interstitial_images/amundsen/04_amundsen_brille_446_500@3x.png',
    wizardStepId: wizardStepIds.PERSONA_WIZARD_AMUNDSEN_STEP_4,
    wizardStepIdx: 3,
    personaId: personaIds.AMUNDSEN,
  },
  {
    id: interstitialContentIds.PERSONA_WIZARD_AMUNDSEN_5,
    text: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_AMUNDSEN_5}.text`,
    forwardLabel: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_AMUNDSEN_5}.forwardLabel`,
    image: '/images/wizard_interstitial_images/amundsen/05_amundsen_fernrohr_446_500@3x.png',
    wizardStepId: wizardStepIds.PERSONA_WIZARD_AMUNDSEN_STEP_5,
    wizardStepIdx: 4,
    personaId: personaIds.AMUNDSEN,
  },
  {
    id: interstitialContentIds.PERSONA_WIZARD_AMUNDSEN_6,
    text: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_AMUNDSEN_6}.text`,
    forwardLabel: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_AMUNDSEN_6}.forwardLabel`,
    image: '/images/wizard_interstitial_images/amundsen/06_amundsen_seil_446_500_copy@3x.png',
    wizardStepId: wizardStepIds.PERSONA_WIZARD_AMUNDSEN_STEP_6,
    wizardStepIdx: 5,
    personaId: personaIds.AMUNDSEN,
  },
]
