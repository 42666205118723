export const socialMediaGroupIds = {
  CONTACT: 'CONTACT',
  DOWNLOAD: 'DOWNLOAD',
  NEWSLETTER: 'NEWSLETTER',
  SOCIAL_MEDIA: 'SOCIAL_MEDIA',
}

export const socialMediaIds = {
  EMAIL: 'EMAIL',
  DOWNLOAD: 'DOWNLOAD',
  FACEBOOK: 'FACEBOOK',
  INSTAGRAM: 'INSTAGRAM',
  NEWSLETTER: 'NEWSLETTER',
  TWITTER: 'TWITTER',
}

export const socialMediaGroupList = [
  {
    id: socialMediaGroupIds.CONTACT,
    inNavigation: true,
    label: `data_model.social_media_group.${socialMediaGroupIds.CONTACT}`,
  },
  {
    id: socialMediaGroupIds.NEWSLETTER,
    inNavigation: true,
    label: `data_model.social_media_group.${socialMediaGroupIds.NEWSLETTER}`,
  },
  {
    id: socialMediaGroupIds.SOCIAL_MEDIA,
    inNavigation: true,
    label: `data_model.social_media_group.${socialMediaGroupIds.SOCIAL_MEDIA}`,
  },
  {
    id: socialMediaGroupIds.DOWNLOAD,
    inNavigation: false,
    label: `data_model.social_media_group.${socialMediaGroupIds.DOWNLOAD}`,
  },
]

export const socialMediaList = [
  {
    socialMediaGroupId: socialMediaGroupIds.CONTACT,
    icon: {
      base: '/images/social_media_icons/email_base.png',
      active: '/images/social_media_icons/email_active.png',
    },
    id: socialMediaIds.EMAIL,
    link: 'mailto:via@juliamia.ch',
  },
  {
    socialMediaGroupId: socialMediaGroupIds.NEWSLETTER,
    icon: {
      base: '/images/social_media_icons/newsletter_base.png',
      active: '/images/social_media_icons/newsletter_active.png',
    },
    id: socialMediaIds.NEWSLETTER,
    link: 'http://eepurl.com/dBMncP',
  },
  {
    socialMediaGroupId: socialMediaGroupIds.SOCIAL_MEDIA,
    icon: {
      base: '/images/social_media_icons/facebook_base.png',
      active: '/images/social_media_icons/facebook_active.png',
    },
    id: socialMediaIds.FACEBOOK,
    link: 'https://www.facebook.com/Worldmapgenerator',
  },
  {
    socialMediaGroupId: socialMediaGroupIds.SOCIAL_MEDIA,
    icon: {
      base: '/images/social_media_icons/instagram_base.png',
      active: '/images/social_media_icons/instagram_active.png',
    },
    id: socialMediaIds.INSTAGRAM,
    link: 'https://www.instagram.com/worldmapgenerator',
  },
  {
    socialMediaGroupId: socialMediaGroupIds.SOCIAL_MEDIA,
    icon: {
      base: '/images/social_media_icons/twitter_base.png',
      active: '/images/social_media_icons/twitter_active.png',
    },
    id: socialMediaIds.TWITTER,
    link: 'https://twitter.com/JuliamiaGrafik',
  },
  {
    socialMediaGroupId: socialMediaIds.DOWNLOAD,
    id: socialMediaIds.DOWNLOAD,
    icon: {
      base: '/images/social_media_icons/download_base.png',
      active: '/images/social_media_icons/download_active.png',
    },
    link: '',
  },
]
