import { layerIds } from '@/assets/js/model/layer'
import { styleAttributeIds } from '@/assets/js/model/style-attributes'
import { mapThemeTypeIds } from '@/assets/js/model/map-theme-type'

export const mapThemeIds = {
  NEON_OUTLINE_BLUE: 'NEON_OUTLINE_BLUE',
  NEON_OUTLINE_MAGENTA: 'NEON_OUTLINE_MAGENTA',
  NEON_OUTLINE_YELLOW: 'NEON_OUTLINE_YELLOW',
  NEON_PLAIN_GREEN: 'NEON_PLAIN_GREEN',
  NEON_PLAIN_MAGENTA: 'NEON_PLAIN_MAGENTA',
  NEON_PLAIN_YELLOW: 'NEON_PLAIN_YELLOW',
}

export const mapThemeList = [
  {
    id: mapThemeIds.NEON_OUTLINE_BLUE,
    mapThemeType: mapThemeTypeIds.NEON,
    label: `data_model.map_theme.${mapThemeIds.NEON_OUTLINE_BLUE}`,
    layerStyleList: [
      {
        layerIds: [layerIds.BACKGROUND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#382f32',
        },
      },
      {
        layerIds: [layerIds.SPHERE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#f9ff60',
          [styleAttributeIds.LINE_WIDTH]: 4,
        },
      },
      {
        layerIds: [layerIds.LINES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#44fa32',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.GRATICULES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#f9ff60',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.LAND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#000000',
        },
      },
      {
        layerIds: [layerIds.OCEAN],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#000000',
        },
      },
      {
        layerIds: [layerIds.COASTLINE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#0041ff',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.LAKES],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#0041ff',
          [styleAttributeIds.LINE_COLOR]: '#0041ff',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.RIVERS],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#0041ff',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.COUNTRIES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#f90060',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.TISSOT_INDICATRIX],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#ffffffcc',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
          [styleAttributeIds.FILL_COLOR]: '#ffffff4d',
        },
      },
    ],
  },
  {
    id: mapThemeIds.NEON_OUTLINE_MAGENTA,
    mapThemeType: mapThemeTypeIds.NEON,
    label: `data_model.map_theme.${mapThemeIds.NEON_OUTLINE_MAGENTA}`,
    layerStyleList: [
      {
        layerIds: [layerIds.BACKGROUND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#382f32',
        },
      },
      {
        layerIds: [layerIds.SPHERE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#44fa32',
          [styleAttributeIds.LINE_WIDTH]: 4,
        },
      },
      {
        layerIds: [layerIds.LINES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#0041ff',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.GRATICULES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#44fa32',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.LAND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#000000',
        },
      },
      {
        layerIds: [layerIds.OCEAN],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#000000',
        },
      },
      {
        layerIds: [layerIds.COASTLINE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#f90060',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.LAKES],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#f90060',
          [styleAttributeIds.LINE_COLOR]: '#f90060',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.RIVERS],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#f90060',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.COUNTRIES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#f9ff60',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.TISSOT_INDICATRIX],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#ffffffcc',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
          [styleAttributeIds.FILL_COLOR]: '#ffffff4d',
        },
      },
    ],
  },
  {
    id: mapThemeIds.NEON_OUTLINE_YELLOW,
    mapThemeType: mapThemeTypeIds.NEON,
    label: `data_model.map_theme.${mapThemeIds.NEON_OUTLINE_YELLOW}`,
    layerStyleList: [
      {
        layerIds: [layerIds.BACKGROUND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#382f32',
        },
      },
      {
        layerIds: [layerIds.SPHERE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#0041ff',
          [styleAttributeIds.LINE_WIDTH]: 4,
        },
      },
      {
        layerIds: [layerIds.LINES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#0041ff',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.GRATICULES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#f90060',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.LAND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#000000',
        },
      },
      {
        layerIds: [layerIds.OCEAN],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#000000',
        },
      },
      {
        layerIds: [layerIds.COASTLINE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#f9ff60',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.LAKES],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#f9ff60',
          [styleAttributeIds.LINE_COLOR]: '#f9ff60',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.RIVERS],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#f9ff60',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.COUNTRIES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#44fa32',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.TISSOT_INDICATRIX],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#ffffffcc',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
          [styleAttributeIds.FILL_COLOR]: '#ffffff4d',
        },
      },
    ],
  },
  {
    id: mapThemeIds.NEON_PLAIN_GREEN,
    mapThemeType: mapThemeTypeIds.NEON,
    label: `data_model.map_theme.${mapThemeIds.NEON_PLAIN_GREEN}`,
    layerStyleList: [
      {
        layerIds: [layerIds.BACKGROUND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#382f32',
        },
      },
      {
        layerIds: [layerIds.SPHERE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#44fa32',
          [styleAttributeIds.LINE_WIDTH]: 4,
        },
      },
      {
        layerIds: [layerIds.LINES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#44fa32',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.GRATICULES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#44fa32',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
        },
      },
      {
        layerIds: [layerIds.LAND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#44fa32',
        },
      },
      {
        layerIds: [layerIds.OCEAN],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#0041ff',
        },
      },
      {
        layerIds: [layerIds.COASTLINE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#000000',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.LAKES],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#0041ff',
          [styleAttributeIds.LINE_COLOR]: '#0041ff',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.RIVERS],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#0041ff',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.COUNTRIES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#0041ff',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.TISSOT_INDICATRIX],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#000000cc',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
          [styleAttributeIds.FILL_COLOR]: '#0000004d',
        },
      },
    ],
  },
  {
    id: mapThemeIds.NEON_PLAIN_MAGENTA,
    mapThemeType: mapThemeTypeIds.NEON,
    label: `data_model.map_theme.${mapThemeIds.NEON_PLAIN_MAGENTA}`,
    layerStyleList: [
      {
        layerIds: [layerIds.BACKGROUND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#382f32',
        },
      },
      {
        layerIds: [layerIds.SPHERE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#f90060',
          [styleAttributeIds.LINE_WIDTH]: 4,
        },
      },
      {
        layerIds: [layerIds.LINES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#f90060',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.GRATICULES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#f90060',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
        },
      },
      {
        layerIds: [layerIds.LAND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#f90060',
        },
      },
      {
        layerIds: [layerIds.OCEAN],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#0041ff',
        },
      },
      {
        layerIds: [layerIds.COASTLINE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#000000',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.LAKES],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#0041ff',
          [styleAttributeIds.LINE_COLOR]: '#0041ff',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.RIVERS],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#0041ff',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.COUNTRIES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#0041ff',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.TISSOT_INDICATRIX],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#000000cc',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
          [styleAttributeIds.FILL_COLOR]: '#0000004d',
        },
      },
    ],
  },
  {
    id: mapThemeIds.NEON_PLAIN_YELLOW,
    mapThemeType: mapThemeTypeIds.NEON,
    label: `data_model.map_theme.${mapThemeIds.NEON_PLAIN_YELLOW}`,
    layerStyleList: [
      {
        layerIds: [layerIds.BACKGROUND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#382f32',
        },
      },
      {
        layerIds: [layerIds.SPHERE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#f9ff60',
          [styleAttributeIds.LINE_WIDTH]: 4,
        },
      },
      {
        layerIds: [layerIds.LINES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#f9ff60',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.GRATICULES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#f9ff60',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
        },
      },
      {
        layerIds: [layerIds.LAND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#f9ff60',
        },
      },
      {
        layerIds: [layerIds.OCEAN],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#0041ff',
        },
      },
      {
        layerIds: [layerIds.COASTLINE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#000000',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.LAKES],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#0041ff',
          [styleAttributeIds.LINE_COLOR]: '#0041ff',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.RIVERS],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#0041ff',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.COUNTRIES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#0041ff',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.TISSOT_INDICATRIX],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#000000cc',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
          [styleAttributeIds.FILL_COLOR]: '#0000004d',
        },
      },
    ],
  },
]
