import {
  BASE_URL,
  BASE_URL_WITH_TRAILING_SLASH,
  SHARING_IMAGE_URL,
} from '@/assets/js/util/nuxt/seo/util'
import { getFacebookMeta } from '~/assets/js/util/nuxt/seo/facebook-meta'
import { getTwitterMeta } from '~/assets/js/util/nuxt/seo/twitter-meta'

export { BASE_URL, BASE_URL_WITH_TRAILING_SLASH, SHARING_IMAGE_URL }

export function createHeadSeoData() {
  const { htmlAttrs, meta, link } = this.$nuxtI18nSeo()
  const hasTitle = this.meta && (this.meta.title || this.meta.titleKey)

  const titleBase = this.meta && (this.meta.title || this.$t(this.meta.titleKey))
  const titleSuffix = this.$t('meta.suffix')
  const title = hasTitle ? `${titleBase} – ${titleSuffix}` : this.$t('meta.default.title')

  return {
    title,
    htmlAttrs: {
      ...htmlAttrs,
    },
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: this.$t('meta.default.description'),
      },
      {
        hid: 'og:site_name',
        property: 'og:site_name',
        content: title,
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: title,
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: this.$t('meta.default.description'),
      },

      {
        hid: 'twitter:title',
        name: 'twitter:title',
        content: title,
      },
      {
        hid: 'twitter:description',
        name: 'twitter:description',
        content: this.$t('meta.default.description'),
      },
      ...getFacebookMeta.call(this),
      ...getTwitterMeta.call(this),
      ...meta,
    ],
    link: [...link],
  }
}
