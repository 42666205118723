import { projectionIds } from '@/assets/js/model/projection'
import { layerIds } from '@/assets/js/model/layer'
import { mapThemeIds } from '@/assets/js/model/map-theme'
import { renderingAdapterIds } from '@/assets/js/model/export'

export const mapConfigIds = {
  CUSTOM_WIZARD: 'CUSTOM_WIZARD',
  SPLASH_SCREEN: 'SPLASH_SCREEN',
  WIZARD_STEP_CENTERING: 'WIZARD_STEP_CENTERING',
  PERSONA_WIZARD: 'PERSONA_WIZARD',
}

export const mapConfigList = [
  {
    id: mapConfigIds.CUSTOM_WIZARD,
    layerIds: [
      layerIds.CLIP_PATH,
      layerIds.BACKGROUND,
      layerIds.SPHERE,
      layerIds.GRATICULES,
      layerIds.LAND,
      layerIds.OCEAN,
    ],
    projectionId: projectionIds.FAHEY,
    renderingAdapterId: renderingAdapterIds.CANVAS,
    mapThemeId: mapThemeIds.DEFAULT,
    center: [0, 0],
    rotation: [0, 0, 0],
    zoom: 0.9,
    wizardStepProgressIdx: 0,
    version: '1.0.0',
  },
  {
    id: mapConfigIds.SPLASH_SCREEN,
    layerIds: [
      layerIds.CLIP_PATH,
      layerIds.SPHERE,
      layerIds.SPHERE_BACKGROUND,
      layerIds.GRATICULES,
      layerIds.LAND,
    ],
    projectionId: projectionIds.ORTHOGRAPHIC,
    renderingAdapterId: renderingAdapterIds.CANVAS,
    mapThemeId: mapThemeIds.SPLASH_SCREEN,
    center: [0, 0],
    rotation: [0, 0, 0],
    zoom: 0,
    wizardStepProgressIdx: null,
    version: '1.0.0',
  },
  {
    id: mapConfigIds.WIZARD_STEP_CENTERING,
    layerIds: [
      layerIds.CLIP_PATH,
      layerIds.SPHERE,
      layerIds.GRATICULES,
      layerIds.LAND,
      layerIds.OCEAN,
    ],
    projectionId: projectionIds.ORTHOGRAPHIC,
    renderingAdapterId: renderingAdapterIds.CANVAS,
    mapThemeId: mapThemeIds.WIZARD_STEP_CENTERING,
    center: [0, 0],
    rotation: [0, 0, 0],
    zoom: 1,
    wizardStepProgressIdx: null,
    version: '1.0.0',
  },
]
