<template>
  <interstitial-content v-bind="interstitialContent" />
</template>

<script>
  import { interstitialContentList } from '@/assets/js/model/interstitial-content'
  import InterstitialContent from '@/components/interstitial-content/interstitial-content'

  export default {
    components: { InterstitialContent },
    props: {
      interstitialContentId: {
        type: String,
        required: true,
      },
    },
    computed: {
      interstitialContent() {
        const content = interstitialContentList.find(({ id }) => {
          return id === this.interstitialContentId
        })

        return {
          ...content,
          facets: ['as-overlay'],
        }
      },
    },
  }
</script>
