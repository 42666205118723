import { debounce } from 'lodash-es'
import {
  SET_LAYER_DATA,
  SET_MAP_CONFIG,
  SET_PROJECTION_DATA,
  SET_RENDERING_ADAPTER_DATA,
} from '@/store/world-map/mutation-types'
import { layerIds, layerList } from '@/assets/js/model/layer'
import { projectionIds, projectionList } from '@/assets/js/model/projection'
import { renderingAdapterIds, renderingAdapterList } from '@/assets/js/model/export'
import { mapConfigIds } from '@/assets/js/model/map-config'
import { pack } from '@/assets/js/util/misc/json-data'
import { reduceRotationPrecision } from '~/store/world-map/util'

const DEBOUNCE_WAIT = 500
const DEBOUNCE_OPTIONS = { maxWait: 1000 }

/**
 * Find the id in a list
 * @param {Array} items - The haystack
 * @param {string} id - The needle
 * @return {any} The found element
 */
function findId(items, id) {
  return items.find((item) => item.id === id)
}

/**
 * Update the config query param
 * @param {any} config
 */
function updateConfigQueryParam(config) {
  $nuxt.$router.replace({
    ...$nuxt.$router.currentRoute,
    query: { ...$nuxt.$router.currentRoute.query, config },
  })
}

const debouncedUpdateConfigQueryParam = debounce(
  updateConfigQueryParam,
  DEBOUNCE_WAIT,
  DEBOUNCE_OPTIONS,
)

/**
 * Asynchronously load a webpack module
 * @param {function} loader - The function containing the import
 * @return {any} The loaded webpack module
 */
async function loadModule(loader) {
  const m = await loader()
  return m.__esModule ? m.default : m
}

/**
 * Load a map data layer and return it to the requester
 * @param {function} commit - See vuex documentation
 * @param {function} state - See vuex documentation
 * @param {string} id - The data layer id
 * @return {object} The requested layer data
 */
export async function loadLayer({ commit, state }, { id }) {
  if (!Object.values(layerIds).includes(id)) {
    return
  }

  if (!state.layerData[id]) {
    const { layerDataLoader } = findId(layerList, id)
    const data = await layerDataLoader()

    commit(SET_LAYER_DATA, { id, data: Object.freeze(data) })
  }

  return state.layerData[id]
}

/**
 * Load a projection and return it to the requester
 * @param {function} commit - See vuex documentation
 * @param {function} state - See vuex documentation
 * @param {string} id - The projection id
 * @return {function} The projection method
 */
export async function loadProjection({ commit, state }, { id }) {
  if (!Object.values(projectionIds).includes(id)) {
    throw new Error('Unknown projection id.')
  }

  if (!state.projectionData[id]) {
    const { isMorphable, projectionMethodLoader } = findId(projectionList, id)
    const { module, raw } = await loadModule(projectionMethodLoader)

    commit(SET_PROJECTION_DATA, {
      id,
      isMorphable,
      data: Object.freeze(module()),
      raw,
    })
  }

  return state.projectionData[id]
}

/**
 * Load a rendering adapter
 * @param {function} commit - See vuex documentation
 * @param {function} state - See vuex documentation
 * @param {string} id - The rendering adapter id
 * @return {Promise<any>}
 */
export async function loadRenderingAdapter({ commit, state }, { id }) {
  if (!Object.values(renderingAdapterIds).includes(id)) {
    throw new Error('Unknown rendering mode id.')
  }

  if (!state.renderingAdapterData[id]) {
    const { renderingAdapterLoader } = findId(renderingAdapterList, id)
    const data = await renderingAdapterLoader()

    commit(SET_RENDERING_ADAPTER_DATA, { id, data })
  }

  return state.renderingAdapterData[id]
}

/**
 * Set a map component's config
 * @param {function} commit - See vuex documentation
 * @param {function} dispatch - See vuex documentation
 * @param {any} mapConfig - The map config
 */
export function setMapConfig({ commit, dispatch }, mapConfig) {
  const rotation = reduceRotationPrecision(mapConfig.rotation)

  commit(SET_MAP_CONFIG, mapConfig)

  if (mapConfig.id === mapConfigIds.CUSTOM_WIZARD && process.client) {
    dispatch('world-map/syncConfigQueryParam', { ...mapConfig, rotation }, { root: true })
  }
}

/**
 * Update a map component's config
 * @param {function} commit - See vuex documentation
 * @param {function} dispatch - See vuex documentation
 * @param {any} partialMapConfig - A partial map config
 */
export function updateMapConfig({ dispatch, state }, partialMapConfig) {
  if (!partialMapConfig.id) {
    throw new Error('Map config needs a valid "id" argument. None was found!')
  }

  dispatch(
    'world-map/setMapConfig',
    { ...state.mapConfigs[partialMapConfig.id], ...partialMapConfig },
    { root: true },
  )
}

/**
 * Update the route query param
 * @param {any} _ - Vuex context
 * @param {any} mapConfig - The map config
 */
export function syncConfigQueryParam(_, mapConfig) {
  const config = pack(mapConfig)

  if ($nuxt.$router.currentRoute.query.config === config) {
    return
  }

  debouncedUpdateConfigQueryParam(config)
}
