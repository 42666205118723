export const renderingAdapterIds = {
  CANVAS: 'CANVAS',
  SVG: 'SVG',
}

export const exportFormatIds = {
  JPG: 'JPG',
  PNG: 'PNG',
  SVG: 'SVG',
}

export const exportSizeIds = {
  TINY: 'TINY',
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
}

export const renderingAdapterList = [
  {
    id: renderingAdapterIds.CANVAS,
    tag: 'canvas',
    renderingAdapterLoader: () => import('@/assets/js/util/rendering-adapter/canvas-adapter'),
  },
  {
    id: renderingAdapterIds.SVG,
    tag: 'svg',
    renderingAdapterLoader: () => import('@/assets/js/util/rendering-adapter/svg-adapter'),
  },
]

export const exportFormatList = [
  {
    id: exportFormatIds.JPG,
    label: `data_model.export_format.${exportFormatIds.JPG}`,
    fileType: 'image/jpeg',
    fileExtension: 'jpg',
    renderingAdapterId: renderingAdapterIds.CANVAS,
  },
  {
    id: exportFormatIds.PNG,
    label: `data_model.export_format.${exportFormatIds.PNG}`,
    fileType: 'image/png',
    fileExtension: 'png',
    renderingAdapterId: renderingAdapterIds.CANVAS,
  },
  {
    id: exportFormatIds.SVG,
    label: `data_model.export_format.${exportFormatIds.SVG}`,
    fileType: 'image/svg',
    fileExtension: 'svg',
    renderingAdapterId: renderingAdapterIds.SVG,
  },
]

export const exportSizeList = [
  {
    id: exportSizeIds.TINY,
    height: 40,
    width: 40,
    label: `data_model.export_size.${exportSizeIds.TINY}`,
  },
  {
    id: exportSizeIds.SMALL,
    height: 300,
    width: 400,
    label: `data_model.export_size.${exportSizeIds.SMALL}`,
  },
  {
    id: exportSizeIds.MEDIUM,
    height: 608,
    width: 1080,
    label: `data_model.export_size.${exportSizeIds.MEDIUM}`,
  },
  {
    id: exportSizeIds.LARGE,
    height: 1080,
    width: 1920,
    label: `data_model.export_size.${exportSizeIds.LARGE}`,
  },
]
