export const wizardStepIds = {
  CUSTOM_WIZARD_CENTERING: 'CUSTOM_WIZARD_CENTERING',
  CUSTOM_WIZARD_PROJECTION: 'CUSTOM_WIZARD_PROJECTION',
  CUSTOM_WIZARD_GEOLAYERS: 'CUSTOM_WIZARD_GEOLAYERS',
  CUSTOM_WIZARD_ZOOM: 'CUSTOM_WIZARD_ZOOM',
  CUSTOM_WIZARD_THEME: 'CUSTOM_WIZARD_THEME',
  CUSTOM_WIZARD_EXPORT: 'CUSTOM_WIZARD_EXPORT',
  CUSTOM_WIZARD_END: 'CUSTOM_WIZARD_END',
}

export const wizardStepParams = {
  [wizardStepIds.CUSTOM_WIZARD_CENTERING]: 'centering',
  [wizardStepIds.CUSTOM_WIZARD_PROJECTION]: 'projection',
  [wizardStepIds.CUSTOM_WIZARD_GEOLAYERS]: 'geolayers',
  [wizardStepIds.CUSTOM_WIZARD_ZOOM]: 'zoom',
  [wizardStepIds.CUSTOM_WIZARD_THEME]: 'theme',
  [wizardStepIds.CUSTOM_WIZARD_EXPORT]: 'export',
  [wizardStepIds.CUSTOM_WIZARD_END]: 'end',
}
