import Vue from 'vue'

import OverlayCookieBannerContent from '@/components/overlay-cookie-banner-content/overlay-cookie-banner-content'
import OverlayDonationContent from '@/components/overlay-donation-content/overlay-donation-content'
import OverlayInterstitialContent from '@/components/overlay-interstitial-content/overlay-interstitial-content'
import OverlayMapStationsContent from '@/components/overlay-map-stations-content/overlay-map-stations-content'
import OverlayTooltipBaseContent from '@/components/overlay-tooltip-base-content/overlay-tooltip-base-content'
import OverlayMenu from '@/components/overlay-menu/overlay-menu'

const components = {
  OverlayCookieBannerContent,
  OverlayDonationContent,
  OverlayInterstitialContent,
  OverlayTooltipBaseContent,
  OverlayMapStationsContent,
  OverlayMenu,
}

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component)
})

export default () => {}
