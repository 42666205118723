import { SET_INTERSTITIAL } from './mutation-types'
import * as actions from './actions'

const mutations = {
  [SET_INTERSTITIAL](state, { isOpen }) {
    state.isOpen = isOpen
  },
}

const state = () => ({
  isOpen: false,
})

export default {
  namespaced: true,
  mutations,
  actions,
  state,
}
