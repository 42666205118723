import Cookies from 'js-cookie'
import { SET_INTERSTITIAL } from './mutation-types'
import { overlayInstanceIds } from '@/assets/js/model/overlay-instance'
import {
  INTERSTITIAL_CONTENT_COOKIE_NAME,
  parseInterstitialCookie,
} from '@/store/interstitial/util'

/**
 * Update menu state
 */
export function setInterstitial({ commit, dispatch }, { isHelp, isOpen, id }) {
  const interstitialContentList = parseInterstitialCookie()
  const wasNotOpened = !interstitialContentList.includes(id)

  if (isOpen && (isHelp || wasNotOpened)) {
    dispatch(
      'overlay/openOverlay',
      {
        id: overlayInstanceIds.INTERSTITIAL_CONTENT,
        transition: 'trs-simple-fade',
        disableScroll: false,
        facets: ['interstitial-content'],
        component: 'overlay-interstitial-content',
        props: {
          interstitialContentId: id,
        },
      },
      { root: true },
    )

    interstitialContentList.push(id)
    Cookies.set(INTERSTITIAL_CONTENT_COOKIE_NAME, interstitialContentList)
  } else {
    dispatch(
      'overlay/closeOverlay',
      {
        id: overlayInstanceIds.INTERSTITIAL_CONTENT,
        transition: 'trs-menu-fade',
        disableScroll: false,
      },
      { root: true },
    )
  }

  commit(SET_INTERSTITIAL, { isOpen })
}
