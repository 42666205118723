<template>
  <div class="overlay-map-stations-content">
    <map-tooltip
      v-for="(station, stationIdx) in stations"
      :key="stationIdx"
      :is-current-station="isCurrentStation(stationIdx)"
      :label="station.label"
      :position="{ x: station.x, y: station.y }"
      :station-idx="stationIdx"
      @tooltip-hovered="onTooltipHovered"
    />
  </div>
</template>

<script>
  import Vue from 'vue'
  import MapTooltip from '@/components/map-tooltip/map-tooltip'

  export default {
    components: { MapTooltip },
    props: {
      stations: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        hoveredTooltipIndices: {},
      }
    },
    methods: {
      isCurrentStation(stationIdx) {
        const hasHoveredTooltips = Object.values(this.hoveredTooltipIndices).some(Boolean)

        if (hasHoveredTooltips) {
          return false
        }

        return parseInt(this.$route.params.step, 10) === stationIdx + 1
      },
      onTooltipHovered(idx, value) {
        Vue.set(this.hoveredTooltipIndices, idx, value)
      },
    },
  }
</script>

<style lang="scss" src="./_overlay-map-stations-content.scss" />
