<template>
  <div :style="rootStyles" class="p-layout p-layout--default u-typography-root">
    <nuxt />
    <main-menu />
    <overlay-root class="u-typography-root" />
    <client-only>
      <scroll-observer />
      <viewport-observer />
    </client-only>
  </div>
</template>

<script>
  import { kebabCase, mapKeys } from 'lodash-es'
  import { mapActions, mapGetters, mapState } from 'vuex'
  import OverlayRoot from '@/components/overlay-root/overlay-root'
  import MainMenu from '@/components/main-menu/main-menu'
  import ScrollObserver from '@/components/scroll-observer/scroll-observer'
  import ViewportObserver from '@/components/viewport-observer/viewport-observer'
  import { pageIds } from '@/assets/js/model/page'
  import { mapConfigIds } from '@/assets/js/model/map-config'
  import { mapThemeList } from '@/assets/js/model/map-theme'
  import { mapConfig } from '@/assets/js/util/misc/map-config'
  import { createHeadSeoData } from '@/assets/js/util/nuxt/seo'

  export default {
    components: { ViewportObserver, ScrollObserver, MainMenu, OverlayRoot },
    head() {
      return createHeadSeoData.call(this)
    },
    computed: {
      ...mapState('world-map', ['mapConfigs']),
      ...mapState('navigation', ['meta']),
      ...mapGetters('navigation', ['activePersona', 'activePageTheme']),
      rootStyles() {
        return mapKeys(
          { ...this.mapThemeKeys, ...this.activePageTheme.cssVariables },
          (_, key) => `--page-theme-${kebabCase(key)}`,
        )
      },
      mapTheme() {
        const activeMapTheme = this.mapConfigs[mapConfigIds.PERSONA_WIZARD]

        if (activeMapTheme) {
          return mapThemeList.find(({ id }) => id === activeMapTheme.mapThemeId)
        }

        return {}
      },
      mapThemeKeys() {
        if (this.meta.pageId === pageIds.PERSONA_WIZARD) {
          return { ...this.mapTheme.cssVariables }
        }

        return {}
      },
    },
    mounted() {
      this.showCookieBanner()
    },
    created() {
      if (this.meta.pageId === pageIds.CUSTOM_WIZARD) {
        const config = mapConfig()

        if (config) {
          this.setMapConfig(config)
        }
      }
    },
    methods: {
      ...mapActions('cookie-banner', ['showCookieBanner']),
      ...mapActions('world-map', ['setMapConfig']),
    },
  }
</script>
