import * as mapThemeCustomWizard from '../map-theme/custom-wizard'
import { layerIds } from '@/assets/js/model/layer'
import { styleAttributeIds } from '@/assets/js/model/style-attributes'
import { mapThemeTypeIds } from '@/assets/js/model/map-theme-type'

const customMapTheme = mapThemeCustomWizard.mapThemeList.find(({ id }) => {
  return id === mapThemeCustomWizard.mapThemeIds.DEFAULT
})

export const mapThemeIds = {
  PERSONA_AMUNDSEN_INITIAL: 'PERSONA_AMUNDSEN_INITIAL',
  PERSONA_JACK_INITIAL: 'PERSONA_JACK_INITIAL',
  PERSONA_MERIAN_INITIAL: 'PERSONA_MERIAN_INITIAL',
  PERSONA_AMUNDSEN: 'PERSONA_AMUNDSEN',
  PERSONA_JACK: 'PERSONA_JACK',
  PERSONA_MERIAN: 'PERSONA_MERIAN',
}

export const mapThemeList = [
  {
    id: mapThemeIds.PERSONA_AMUNDSEN_INITIAL,
    mapThemeType: mapThemeTypeIds.PERSONA_WIZARD,
    label: null,
    cssVariables: {
      mapStationColor: '#f86963',
    },
    layerStyleList: [
      ...customMapTheme.layerStyleList,
      {
        layerIds: [layerIds.PERSONA_AMUNDSEN_JOURNEY],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#f86963',
          [styleAttributeIds.LINE_WIDTH]: 2,
        },
      },
    ],
  },
  {
    id: mapThemeIds.PERSONA_JACK_INITIAL,
    mapThemeType: mapThemeTypeIds.PERSONA_WIZARD,
    label: null,
    cssVariables: {
      mapStationColor: '#0093D4',
    },
    layerStyleList: [
      ...customMapTheme.layerStyleList,
      {
        layerIds: [layerIds.PERSONA_JACK_JOURNEY],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#0093D4',
          [styleAttributeIds.LINE_WIDTH]: 2,
        },
      },
    ],
  },
  {
    id: mapThemeIds.PERSONA_MERIAN_INITIAL,
    mapThemeType: mapThemeTypeIds.PERSONA_WIZARD,
    label: null,
    cssVariables: {
      mapStationColor: '#f86963',
    },
    layerStyleList: [
      ...customMapTheme.layerStyleList,
      {
        layerIds: [layerIds.PERSONA_MERIAN_JOURNEY],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#f86963',
          [styleAttributeIds.LINE_WIDTH]: 2,
        },
      },
    ],
  },
  {
    id: mapThemeIds.PERSONA_AMUNDSEN,
    mapThemeType: mapThemeTypeIds.PERSONA_WIZARD,
    cssVariables: {
      mapStationColor: '#f86963',
    },
    label: null,
    layerStyleList: [
      {
        layerIds: [layerIds.BACKGROUND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#fffcdb',
        },
      },
      {
        layerIds: [layerIds.SPHERE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#ea1d15',
          [styleAttributeIds.LINE_WIDTH]: 4,
        },
      },
      {
        layerIds: [layerIds.GRATICULES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#382f32',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
          [styleAttributeIds.LINE_DASH]: [2, 2],
        },
      },
      {
        layerIds: [layerIds.LAND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#ffffff',
        },
      },
      {
        layerIds: [layerIds.OCEAN],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#fcfaf0',
        },
      },
      {
        layerIds: [layerIds.COASTLINE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#ea1d15',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
        },
      },
      {
        layerIds: [layerIds.LAKES],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#bcbcbc',
          [styleAttributeIds.LINE_COLOR]: '#bcbcbc',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.RIVERS],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#bcbcbc',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.PERSONA_AMUNDSEN_JOURNEY],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#f86963',
          [styleAttributeIds.LINE_WIDTH]: 2,
        },
      },
    ],
  },
  {
    id: mapThemeIds.PERSONA_JACK,
    mapThemeType: mapThemeTypeIds.PERSONA_WIZARD,
    cssVariables: {
      mapStationColor: '#382f32',
    },
    label: null,
    layerStyleList: [
      {
        layerIds: [layerIds.BACKGROUND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#382f32',
        },
      },
      {
        layerIds: [layerIds.SPHERE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#fffcdb',
          [styleAttributeIds.LINE_WIDTH]: 4,
        },
      },
      {
        layerIds: [layerIds.LINES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#fffcdb',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.GRATICULES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#fffcdb',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
          [styleAttributeIds.LINE_DASH]: [2, 2],
        },
      },
      {
        layerIds: [layerIds.LAND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#fffcdb',
        },
      },
      {
        layerIds: [layerIds.OCEAN],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#0093d4',
        },
      },
      {
        layerIds: [layerIds.COASTLINE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#382f32',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
        },
      },
      {
        layerIds: [layerIds.LAKES],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#0093d4',
          [styleAttributeIds.LINE_COLOR]: '#0093d4',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.RIVERS],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#0093d4',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.COUNTRIES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#d1e78e',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.PERSONA_JACK_JOURNEY],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#382f32',
          [styleAttributeIds.LINE_WIDTH]: 2,
        },
      },
    ],
  },
  {
    id: mapThemeIds.PERSONA_MERIAN,
    mapThemeType: mapThemeTypeIds.PERSONA_WIZARD,
    cssVariables: {
      mapStationColor: '#f86963',
    },
    label: null,
    layerStyleList: [
      {
        layerIds: [layerIds.BACKGROUND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#d1e78e',
        },
      },
      {
        layerIds: [layerIds.SPHERE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#ea535f',
          [styleAttributeIds.LINE_WIDTH]: 4,
        },
      },
      {
        layerIds: [layerIds.LINES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#d1e78e',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.GRATICULES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#d1e78e',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
          [styleAttributeIds.LINE_DASH]: [2, 2],
        },
      },
      {
        layerIds: [layerIds.LAND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#edea83',
        },
      },
      {
        layerIds: [layerIds.OCEAN],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#0093d4',
        },
      },
      {
        layerIds: [layerIds.COASTLINE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#ea535f',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
        },
      },
      {
        layerIds: [layerIds.LAKES],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#0093d4',
          [styleAttributeIds.LINE_COLOR]: '#0093d4',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.RIVERS],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#0093d4',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.COUNTRIES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#ea535f',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.PERSONA_MERIAN_JOURNEY],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#f86963',
          [styleAttributeIds.LINE_WIDTH]: 2,
        },
      },
    ],
  },
]
