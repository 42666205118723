import * as wizardCustom from './route/wizard-custom'
import * as wizardPersonalAmundsen from './route/wizard-persona-amundsen'
import * as wizardPersonalJack from './route/wizard-persona-jack'
import * as wizardPersonalMerian from './route/wizard-persona-merian'

export const wizardStepIds = {
  ...wizardCustom.wizardStepIds,
  ...wizardPersonalAmundsen.wizardStepIds,
  ...wizardPersonalJack.wizardStepIds,
  ...wizardPersonalMerian.wizardStepIds,
}

export const wizardStepParams = {
  ...wizardCustom.wizardStepParams,
  ...wizardPersonalAmundsen.wizardStepParams,
  ...wizardPersonalJack.wizardStepParams,
  ...wizardPersonalMerian.wizardStepParams,
}
