import { wizardStepIds, wizardStepParams } from '@/assets/js/model/route'
import { personaIds } from '@/assets/js/model/persona'

export const wizardStepList = [
  {
    id: wizardStepIds.CUSTOM_WIZARD_CENTERING,
    personaId: personaIds.CUSTOM,
    label: 'wizard_step.centering.title',
    param: wizardStepParams[wizardStepIds.CUSTOM_WIZARD_CENTERING],
    icon: '/images/wizard_step_icons/custom/centering_icon@3x.png',
    inNav: true,
    allowRotation: true,
    allowZoom: true,
    component: () => import('@/components/wizard-step-centering/wizard-step-centering'),
    mapConfig: null,
  },
  {
    id: wizardStepIds.CUSTOM_WIZARD_PROJECTION,
    personaId: personaIds.CUSTOM,
    label: 'wizard_step.projection.title',
    param: wizardStepParams[wizardStepIds.CUSTOM_WIZARD_PROJECTION],
    icon: '/images/wizard_step_icons/custom/projection_icon@3x.png',
    inNav: true,
    allowRotation: true,
    allowZoom: true,
    component: () => import('@/components/wizard-step-projection/wizard-step-projection'),
    mapConfig: null,
  },
  {
    id: wizardStepIds.CUSTOM_WIZARD_GEOLAYERS,
    personaId: personaIds.CUSTOM,
    label: 'wizard_step.geo_layers.title',
    param: wizardStepParams[wizardStepIds.CUSTOM_WIZARD_GEOLAYERS],
    icon: '/images/wizard_step_icons/custom/geolayers_icon@3x.png',
    inNav: true,
    allowRotation: true,
    allowZoom: true,
    component: () => import('@/components/wizard-step-geolayers/wizard-step-geolayers'),
    mapConfig: null,
  },
  {
    id: wizardStepIds.CUSTOM_WIZARD_ZOOM,
    personaId: personaIds.CUSTOM,
    label: 'wizard_step.zoom.title',
    param: wizardStepParams[wizardStepIds.CUSTOM_WIZARD_ZOOM],
    icon: '/images/wizard_step_icons/custom/zoom_icon@3x.png',
    inNav: true,
    allowRotation: true,
    allowZoom: true,
    component: () => import('@/components/wizard-step-zoom/wizard-step-zoom'),
    mapConfig: null,
  },
  {
    id: wizardStepIds.CUSTOM_WIZARD_THEME,
    personaId: personaIds.CUSTOM,
    label: 'wizard_step.theme.title',
    param: wizardStepParams[wizardStepIds.CUSTOM_WIZARD_THEME],
    icon: '/images/wizard_step_icons/custom/design_icon@3x.png',
    inNav: true,
    allowRotation: true,
    allowZoom: true,
    component: () => import('@/components/wizard-step-theme/wizard-step-theme'),
    mapConfig: null,
  },
  {
    id: wizardStepIds.CUSTOM_WIZARD_EXPORT,
    personaId: personaIds.CUSTOM,
    label: 'wizard_step.export.title',
    param: wizardStepParams[wizardStepIds.CUSTOM_WIZARD_EXPORT],
    icon: '/images/wizard_step_icons/custom/download_icon@3x.png',
    inNav: true,
    allowRotation: false,
    allowZoom: false,
    component: () => import('@/components/wizard-step-export/wizard-step-export'),
    mapConfig: null,
  },
  {
    id: wizardStepIds.CUSTOM_WIZARD_END,
    personaId: personaIds.CUSTOM,
    label: 'wizard_step.end.title',
    param: wizardStepParams[wizardStepIds.CUSTOM_WIZARD_END],
    icon: '/images/wizard_step_icons/custom/download_icon@3x.png',
    inNav: false,
    allowRotation: false,
    allowZoom: false,
    component: () => import('@/components/wizard-step-end/wizard-step-end'),
    mapConfig: null,
  },
]
