export function getImage(path) {
  return `/images/${path.replace(/^\/|\/$/g, '')}`
}

export function getArrow(theme, direction, componentName) {
  const { dir, use } = theme.arrow

  return {
    src: `${dir}/arrow_${direction}_${use[componentName]}@3x.png`,
    width: ['up', 'down'].includes(direction) ? 21 : 41,
  }
}

export async function createBlobFromDataURL(dataURL) {
  return await (await fetch(dataURL)).blob()
}
