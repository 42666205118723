import { cubic } from '@/assets/js/util/projection/voronoi'

export const projectionTypeIds = {
  CYLINDRICAL: 'CYLINDRICAL',
  AZIMUTHAL: 'AZIMUTHAL',
  CONICAL: 'CONICAL',
  COMPROMISE: 'COMPROMISE',
}

export const projectionShapeIds = {
  ANGULAR_SHAPE: 'ANGULAR_SHAPE',
  CONICAL_SHAPE: 'CONICAL_SHAPE',
  DOUBLE_HEMISPHERE_SHAPE: 'DOUBLE_HEMISPHERE_SHAPE',
  ZICKZACK_SHAPE: 'ZICKZACK_SHAPE',
  CURVED_SHAPE: 'CURVED_SHAPE',
  STAR_SHAPE: 'STAR_SHAPE',
  CIRCLE_SHAPE: 'CIRCLE_SHAPE',
  CONCAVE_SHAPE: 'CONCAVE_SHAPE',
  HEART_SHAPE: 'HEART_SHAPE',
  CURVED_PARALLEL_SHAPE: 'CURVED_PARALLEL_SHAPE',
  OVAL_SHAPE: 'OVAL_SHAPE',
  SINUSOIDAL_SHAPE: 'SINUSOIDAL_SHAPE',
  BUTTERFLY_SHAPE: 'BUTTERFLY_SHAPE',
  RECTANGULAR_SHAPE: 'RECTANGULAR_SHAPE',
}

export const projectionIconIds = {
  ANGULAR_ICON: 'ANGULAR_ICON',
  CONICAL_ICON: 'CONICAL_ICON',
  DOUBLE_HEMISPHERE_ICON: 'DOUBLE_HEMISPHERE_ICON',
  ZICKZACK_ICON: 'ZICKZACK_ICON',
  CURVED_ICON: 'CURVED_ICON',
  STAR_ICON: 'STAR_ICON',
  CIRCLE_ICON: 'CIRCLE_ICON',
  CONCAVE_ICON: 'CONCAVE_ICON',
  HEART_ICON: 'HEART_ICON',
  CURVED_PARALLEL_ICON: 'CURVED_PARALLEL_ICON',
  OVAL_ICON: 'OVAL_ICON',
  SINUSOIDAL_ICON: 'SINUSOIDAL_ICON',
  BUTTERFLY_ICON: 'BUTTERFLY_ICON',
  RECTANGULAR_ICON: 'RECTANGULAR_ICON',
}

export const projectionIds = {
  AITOFF: 'AITOFF',
  ARMADILLO: 'ARMADILLO',
  AUGUST: 'AUGUST',
  AZIMUTHAL_EQUAL_AREA: 'AZIMUTHAL_EQUAL_AREA',
  AZIMUTHAL_EQUIDISTANT: 'AZIMUTHAL_EQUIDISTANT',
  BAKER: 'BAKER',
  BERGHAUS: 'BERGHAUS',
  BONNE: 'BONNE',
  CONIC_EQUAL_AREA: 'CONIC_EQUAL_AREA',
  CONIC_EQUIDISTANT: 'CONIC_EQUIDISTANT',
  CUBIC: 'CUBIC',
  CYLINDRICAL_EQUAL_AREA: 'CYLINDRICAL_EQUAL_AREA',
  CYLINDRICAL_STEREOGRAPHIC: 'CYLINDRICAL_STEREOGRAPHIC',
  ECKERT_1: 'ECKERT_1',
  ECKERT_2: 'ECKERT_2',
  ECKERT_3: 'ECKERT_3',
  ECKERT_4: 'ECKERT_4',
  ECKERT_5: 'ECKERT_5',
  ECKERT_6: 'ECKERT_6',
  EISENLOHR: 'EISENLOHR',
  EQUIRECTANGULAR: 'EQUIRECTANGULAR',
  FAHEY: 'FAHEY',
  FOUCAUT_SINUSOIDAL: 'FOUCAUT_SINUSOIDAL',
  GILBERT: 'GILBERT',
  GINGERY: 'GINGERY',
  GINZBURG_5: 'GINZBURG_5',
  GRINGORTEN: 'GRINGORTEN',
  GUYOU: 'GUYOU',
  HAMMER: 'HAMMER',
  HEALPIX: 'HEALPIX',
  KAVRAYSKIY_7: 'KAVRAYSKIY_7',
  MERCATOR: 'MERCATOR',
  MILLER: 'MILLER',
  MOLLWEIDE: 'MOLLWEIDE',
  NATURAL_EARTH_1: 'NATURAL_EARTH_1',
  NATURAL_EARTH_2: 'NATURAL_EARTH_2',
  ORTHOGRAPHIC: 'ORTHOGRAPHIC',
  PATTERSON: 'PATTERSON',
  ROBINSON: 'ROBINSON',
  SINUSOIDAL: 'SINUSOIDAL',
  STEREOGRAPHIC: 'STEREOGRAPHIC',
  VAN_DER_GRINTEN_4: 'VAN_DER_GRINTEN_4',
  WAGNER: 'WAGNER',
  WAGNER_4: 'WAGNER_4',
  WAGNER_6: 'WAGNER_6',
  WATERMAN: 'WATERMAN',
  WINKEL_3: 'WINKEL_3',
}

export const projectionTypeList = [
  {
    id: projectionTypeIds.CYLINDRICAL,
    label: `data_model.projection_type.${projectionTypeIds.CYLINDRICAL}.label`,
    description: `data_model.projection_type.${projectionTypeIds.CYLINDRICAL}.description`,
    projectionIconId: projectionIconIds.RECTANGULAR_ICON,
  },
  {
    id: projectionTypeIds.AZIMUTHAL,
    label: `data_model.projection_type.${projectionTypeIds.AZIMUTHAL}.label`,
    description: `data_model.projection_type.${projectionTypeIds.AZIMUTHAL}.description`,
    projectionIconId: projectionIconIds.CIRCLE_ICON,
  },
  {
    id: projectionTypeIds.CONICAL,
    label: `data_model.projection_type.${projectionTypeIds.CONICAL}.label`,
    description: `data_model.projection_type.${projectionTypeIds.CONICAL}.description`,
    projectionIconId: projectionIconIds.CONICAL_ICON,
  },
  {
    id: projectionTypeIds.COMPROMISE,
    label: `data_model.projection_type.${projectionTypeIds.COMPROMISE}.label`,
    description: `data_model.projection_type.${projectionTypeIds.COMPROMISE}.description`,
    projectionIconId: projectionIconIds.CURVED_ICON,
  },
]

export const projectionShapeList = [
  {
    id: projectionShapeIds.ANGULAR_SHAPE,
    label: `data_model.projection_shape.${projectionShapeIds.ANGULAR_SHAPE}`,
    projectionIconId: projectionIconIds.ANGULAR_ICON,
  },
  {
    id: projectionShapeIds.CONICAL_SHAPE,
    label: `data_model.projection_shape.${projectionShapeIds.CONICAL_SHAPE}`,
    projectionIconId: projectionIconIds.CONICAL_ICON,
  },
  {
    id: projectionShapeIds.DOUBLE_HEMISPHERE_SHAPE,
    label: `data_model.projection_shape.${projectionShapeIds.DOUBLE_HEMISPHERE_SHAPE}`,
    projectionIconId: projectionIconIds.DOUBLE_HEMISPHERE_ICON,
  },
  {
    id: projectionShapeIds.ZICKZACK_SHAPE,
    label: `data_model.projection_shape.${projectionShapeIds.ZICKZACK_SHAPE}`,
    projectionIconId: projectionIconIds.ZICKZACK_ICON,
  },
  {
    id: projectionShapeIds.CURVED_SHAPE,
    label: `data_model.projection_shape.${projectionShapeIds.CURVED_SHAPE}`,
    projectionIconId: projectionIconIds.CURVED_ICON,
  },
  {
    id: projectionShapeIds.STAR_SHAPE,
    label: `data_model.projection_shape.${projectionShapeIds.STAR_SHAPE}`,
    projectionIconId: projectionIconIds.STAR_ICON,
  },
  {
    id: projectionShapeIds.CIRCLE_SHAPE,
    label: `data_model.projection_shape.${projectionShapeIds.CIRCLE_SHAPE}`,
    projectionIconId: projectionIconIds.CIRCLE_ICON,
  },
  {
    id: projectionShapeIds.CONCAVE_SHAPE,
    label: `data_model.projection_shape.${projectionShapeIds.CONCAVE_SHAPE}`,
    projectionIconId: projectionIconIds.CONCAVE_ICON,
  },
  {
    id: projectionShapeIds.HEART_SHAPE,
    label: `data_model.projection_shape.${projectionShapeIds.HEART_SHAPE}`,
    projectionIconId: projectionIconIds.HEART_ICON,
  },
  {
    id: projectionShapeIds.CURVED_PARALLEL_SHAPE,
    label: `data_model.projection_shape.${projectionShapeIds.CURVED_PARALLEL_SHAPE}`,
    projectionIconId: projectionIconIds.CURVED_PARALLEL_ICON,
  },
  {
    id: projectionShapeIds.OVAL_SHAPE,
    label: `data_model.projection_shape.${projectionShapeIds.OVAL_SHAPE}`,
    projectionIconId: projectionIconIds.OVAL_ICON,
  },
  {
    id: projectionShapeIds.SINUSOIDAL_SHAPE,
    label: `data_model.projection_shape.${projectionShapeIds.SINUSOIDAL_SHAPE}`,
    projectionIconId: projectionIconIds.SINUSOIDAL_ICON,
  },
  {
    id: projectionShapeIds.RECTANGULAR_SHAPE,
    label: `data_model.projection_shape.${projectionShapeIds.RECTANGULAR_SHAPE}`,
    projectionIconId: projectionIconIds.RECTANGULAR_ICON,
  },
  {
    id: projectionShapeIds.BUTTERFLY_SHAPE,
    label: `data_model.projection_shape.${projectionShapeIds.BUTTERFLY_SHAPE}`,
    projectionIconId: projectionIconIds.BUTTERFLY_ICON,
  },
]

export const projectionIconList = [
  {
    id: projectionIconIds.ANGULAR_ICON,
    base: '/images/projection_icons/angular_icon_base@3x.png',
    active: '/images/projection_icons/angular_icon_active@3x.png',
  },
  {
    id: projectionIconIds.CONICAL_ICON,
    base: '/images/projection_icons/conical_icon_base@3x.png',
    active: '/images/projection_icons/conical_icon_active@3x.png',
  },
  {
    id: projectionIconIds.DOUBLE_HEMISPHERE_ICON,
    base: '/images/projection_icons/doublehemisphere_icon_base@3x.png',
    active: '/images/projection_icons/doublehemisphere_icon_active@3x.png',
  },
  {
    id: projectionIconIds.ZICKZACK_ICON,
    base: '/images/projection_icons/zickzack_icon_base@3x.png',
    active: '/images/projection_icons/zickzack_icon_active@3x.png',
  },
  {
    id: projectionIconIds.CURVED_ICON,
    base: '/images/projection_icons/curved_icon_base@3x.png',
    active: '/images/projection_icons/curved_icon_active@3x.png',
  },
  {
    id: projectionIconIds.STAR_ICON,
    base: '/images/projection_icons/star_icon_base@3x.png',
    active: '/images/projection_icons/star_icon_active@3x.png',
  },
  {
    id: projectionIconIds.CIRCLE_ICON,
    base: '/images/projection_icons/circle_icon_base@3x.png',
    active: '/images/projection_icons/circle_icon_active@3x.png',
  },
  {
    id: projectionIconIds.CONCAVE_ICON,
    base: '/images/projection_icons/concave_icon_base@3x.png',
    active: '/images/projection_icons/concave_icon_active@3x.png',
  },
  {
    id: projectionIconIds.HEART_ICON,
    base: '/images/projection_icons/heart_icon_base@3x.png',
    active: '/images/projection_icons/heart_icon_active@3x.png',
  },
  {
    id: projectionIconIds.CURVED_PARALLEL_ICON,
    base: '/images/projection_icons/curvedparallel_icon_base@3x.png',
    active: '/images/projection_icons/curvedparallel_icon_active@3x.png',
  },
  {
    id: projectionIconIds.OVAL_ICON,
    base: '/images/projection_icons/oval_icon_base@3x.png',
    active: '/images/projection_icons/oval_icon_active@3x.png',
  },
  {
    id: projectionIconIds.SINUSOIDAL_ICON,
    base: '/images/projection_icons/sinusoidal_icon_base@3x.png',
    active: '/images/projection_icons/sinusoidal_icon_active@3x.png',
  },
  {
    id: projectionIconIds.BUTTERFLY_ICON,
    base: '/images/projection_icons/butterfly_icon_base@3x.png',
    active: '/images/projection_icons/butterfly_icon_active@3x.png',
  },
  {
    id: projectionIconIds.RECTANGULAR_ICON,
    base: '/images/projection_icons/square_icon_base@3x.png',
    active: '/images/projection_icons/square_icon_active@3x.png',
  },
]

export const projectionList = [
  {
    id: projectionIds.AITOFF,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.AITOFF}.label`,
    description: `data_model.projection.${projectionIds.AITOFF}.description`,
    projectionMethodLoader: async () => {
      const { default: module, aitoffRaw: raw } = await import('d3-geo-projection/src/aitoff')
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.OVAL_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.OVAL_ICON,
  },
  // TODO: The armadillo projection. The default center assumes the default parallel of 20° and should be changed if a different parallel is used. Note: requires clipping to the sphere.
  // @link https://github.com/d3/d3-geo-projection#geoArmadillo
  {
    id: projectionIds.ARMADILLO,
    isMorphable: false,
    label: `data_model.projection.${projectionIds.ARMADILLO}.label`,
    description: `data_model.projection.${projectionIds.ARMADILLO}.description`,
    projectionMethodLoader: async () => {
      const { default: module, armadilloRaw: raw } = await import('d3-geo-projection/src/armadillo')
      return { module, raw: raw((20 * Math.PI) / 180) }
    },
    projectionShapeId: projectionShapeIds.CURVED_PARALLEL_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.CURVED_PARALLEL_ICON,
  },
  {
    id: projectionIds.AUGUST,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.AUGUST}.label`,
    description: `data_model.projection.${projectionIds.AUGUST}.description`,
    projectionMethodLoader: async () => {
      const { default: module, augustRaw: raw } = await import('d3-geo-projection/src/august')
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.DOUBLE_HEMISPHERE_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.DOUBLE_HEMISPHERE_ICON,
  },
  {
    id: projectionIds.AZIMUTHAL_EQUAL_AREA,
    isMorphable: false,
    label: `data_model.projection.${projectionIds.AZIMUTHAL_EQUAL_AREA}.label`,
    description: `data_model.projection.${projectionIds.AZIMUTHAL_EQUAL_AREA}.description`,
    projectionMethodLoader: async () => {
      const { default: module, azimuthalEqualAreaRaw: raw } = await import(
        'd3-geo/src/projection/azimuthalEqualArea'
      )
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.CIRCLE_SHAPE,
    projectionTypeId: projectionTypeIds.AZIMUTHAL,
    projectionIconId: projectionIconIds.CIRCLE_ICON,
  },
  {
    id: projectionIds.AZIMUTHAL_EQUIDISTANT,
    isMorphable: false,
    label: `data_model.projection.${projectionIds.AZIMUTHAL_EQUIDISTANT}.label`,
    description: `data_model.projection.${projectionIds.AZIMUTHAL_EQUIDISTANT}.description`,
    projectionMethodLoader: async () => {
      const { default: module, azimuthalEquidistantRaw: raw } = await import(
        'd3-geo/src/projection/azimuthalEquidistant'
      )
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.CIRCLE_SHAPE,
    projectionTypeId: projectionTypeIds.AZIMUTHAL,
    projectionIconId: projectionIconIds.CIRCLE_ICON,
  },
  {
    id: projectionIds.BAKER,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.BAKER}.label`,
    description: `data_model.projection.${projectionIds.BAKER}.description`,
    projectionMethodLoader: async () => {
      const { default: module, bakerRaw: raw } = await import('d3-geo-projection/src/baker')
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.ANGULAR_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.ANGULAR_ICON,
  },
  // TODO: Berghaus’ star projection. The default center assumes the default lobe number of 5 and should be changed if a different number of lobes is used. Note: requires clipping to the sphere.
  // @link https://github.com/d3/d3-geo-projection#geoBerghaus
  {
    id: projectionIds.BERGHAUS,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.BERGHAUS}.label`,
    description: `data_model.projection.${projectionIds.BERGHAUS}.description`,
    projectionMethodLoader: async () => {
      const { default: module, berghausRaw: raw } = await import('d3-geo-projection/src/berghaus')
      return { module, raw: raw(5) }
    },
    projectionShapeId: projectionShapeIds.STAR_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.STAR_ICON,
  },
  {
    id: projectionIds.BONNE,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.BONNE}.label`,
    description: `data_model.projection.${projectionIds.BONNE}.description`,
    projectionMethodLoader: async () => {
      const { default: module, bonneRaw: raw } = await import('d3-geo-projection/src/bonne')
      return { module, raw: raw(Math.PI / 4) }
    },
    projectionShapeId: projectionShapeIds.HEART_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.HEART_ICON,
  },
  {
    id: projectionIds.CONIC_EQUAL_AREA,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.CONIC_EQUAL_AREA}.label`,
    description: `data_model.projection.${projectionIds.CONIC_EQUAL_AREA}.description`,
    projectionMethodLoader: async () => {
      const { default: module, conicEqualAreaRaw: raw } = await import(
        'd3-geo/src/projection/conicEqualArea'
      )
      return { module, raw: raw(0, Math.PI / 3) }
    },
    projectionShapeId: projectionShapeIds.CONICAL_SHAPE,
    projectionTypeId: projectionTypeIds.CONICAL,
    projectionIconId: projectionIconIds.CONICAL_ICON,
  },
  {
    id: projectionIds.CONIC_EQUIDISTANT,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.CONIC_EQUIDISTANT}.label`,
    description: `data_model.projection.${projectionIds.CONIC_EQUIDISTANT}.description`,
    projectionMethodLoader: async () => {
      const { default: module, conicEquidistantRaw: raw } = await import(
        'd3-geo/src/projection/conicEquidistant'
      )
      return { module, raw: raw(0, Math.PI / 3) }
    },
    projectionShapeId: projectionShapeIds.CONICAL_SHAPE,
    projectionTypeId: projectionTypeIds.CONICAL,
    projectionIconId: projectionIconIds.CONICAL_ICON,
  },
  {
    id: projectionIds.CUBIC,
    isMorphable: false,
    label: `data_model.projection.${projectionIds.CUBIC}.label`,
    description: `data_model.projection.${projectionIds.CUBIC}.description`,
    projectionMethodLoader: () => {
      return {
        module: cubic,
        raw: null,
      }
    },
    projectionShapeId: projectionShapeIds.BUTTERFLY_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.BUTTERFLY_ICON,
  },
  {
    id: projectionIds.CYLINDRICAL_EQUAL_AREA,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.CYLINDRICAL_EQUAL_AREA}.label`,
    description: `data_model.projection.${projectionIds.CYLINDRICAL_EQUAL_AREA}.description`,
    projectionMethodLoader: async () => {
      const { default: module, cylindricalEqualAreaRaw: raw } = await import(
        'd3-geo-projection/src/cylindricalEqualArea'
      )
      return { module, raw: raw((38.58 / 180) * Math.PI) }
    },
    projectionShapeId: projectionShapeIds.RECTANGULAR_SHAPE,
    projectionTypeId: projectionTypeIds.CYLINDRICAL,
    projectionIconId: projectionIconIds.RECTANGULAR_ICON,
  },
  {
    id: projectionIds.CYLINDRICAL_STEREOGRAPHIC,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.CYLINDRICAL_STEREOGRAPHIC}.label`,
    description: `data_model.projection.${projectionIds.CYLINDRICAL_STEREOGRAPHIC}.description`,
    projectionMethodLoader: async () => {
      const { default: module, cylindricalStereographicRaw: raw } = await import(
        'd3-geo-projection/src/cylindricalStereographic'
      )
      return { module, raw: raw(0) }
    },
    projectionShapeId: projectionShapeIds.RECTANGULAR_SHAPE,
    projectionTypeId: projectionTypeIds.CYLINDRICAL,
    projectionIconId: projectionIconIds.RECTANGULAR_ICON,
  },
  {
    id: projectionIds.ECKERT_1,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.ECKERT_1}.label`,
    description: `data_model.projection.${projectionIds.ECKERT_1}.description`,
    projectionMethodLoader: async () => {
      const { default: module, eckert1Raw: raw } = await import('d3-geo-projection/src/eckert1')
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.ANGULAR_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.ANGULAR_ICON,
  },
  {
    id: projectionIds.ECKERT_2,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.ECKERT_2}.label`,
    description: `data_model.projection.${projectionIds.ECKERT_2}.description`,
    projectionMethodLoader: async () => {
      const { default: module, eckert2Raw: raw } = await import('d3-geo-projection/src/eckert2')
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.ANGULAR_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.ANGULAR_ICON,
  },
  {
    id: projectionIds.ECKERT_3,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.ECKERT_3}.label`,
    description: `data_model.projection.${projectionIds.ECKERT_3}.description`,
    projectionMethodLoader: async () => {
      const { default: module, eckert3Raw: raw } = await import('d3-geo-projection/src/eckert3')
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.CURVED_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.CURVED_ICON,
  },
  {
    id: projectionIds.ECKERT_4,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.ECKERT_4}.label`,
    description: `data_model.projection.${projectionIds.ECKERT_4}.description`,
    projectionMethodLoader: async () => {
      const { default: module, eckert4Raw: raw } = await import('d3-geo-projection/src/eckert4')
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.CURVED_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.CURVED_ICON,
  },
  {
    id: projectionIds.ECKERT_5,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.ECKERT_5}.label`,
    description: `data_model.projection.${projectionIds.ECKERT_5}.description`,
    projectionMethodLoader: async () => {
      const { default: module, eckert5Raw: raw } = await import('d3-geo-projection/src/eckert5')
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.CURVED_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.CURVED_ICON,
  },
  {
    id: projectionIds.ECKERT_6,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.ECKERT_6}.label`,
    description: `data_model.projection.${projectionIds.ECKERT_6}.description`,
    projectionMethodLoader: async () => {
      const { default: module, eckert6Raw: raw } = await import('d3-geo-projection/src/eckert6')
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.CURVED_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.CURVED_ICON,
  },
  {
    id: projectionIds.EISENLOHR,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.EISENLOHR}.label`,
    description: `data_model.projection.${projectionIds.EISENLOHR}.description`,
    projectionMethodLoader: async () => {
      const { default: module, eisenlohrRaw: raw } = await import('d3-geo-projection/src/eisenlohr')
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.DOUBLE_HEMISPHERE_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.DOUBLE_HEMISPHERE_ICON,
  },
  {
    id: projectionIds.EQUIRECTANGULAR,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.EQUIRECTANGULAR}.label`,
    description: `data_model.projection.${projectionIds.EQUIRECTANGULAR}.description`,
    projectionMethodLoader: async () => {
      const { default: module, equirectangularRaw: raw } = await import(
        'd3-geo/src/projection/equirectangular'
      )
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.RECTANGULAR_SHAPE,
    projectionTypeId: projectionTypeIds.CYLINDRICAL,
    projectionIconId: projectionIconIds.RECTANGULAR_ICON,
  },
  {
    id: projectionIds.FAHEY,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.FAHEY}.label`,
    description: `data_model.projection.${projectionIds.FAHEY}.description`,
    projectionMethodLoader: async () => {
      const { default: module, faheyRaw: raw } = await import('d3-geo-projection/src/fahey')
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.OVAL_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.OVAL_ICON,
  },
  {
    id: projectionIds.FOUCAUT_SINUSOIDAL,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.FOUCAUT_SINUSOIDAL}.label`,
    description: `data_model.projection.${projectionIds.FOUCAUT_SINUSOIDAL}.description`,
    projectionMethodLoader: async () => {
      const { default: module, foucautSinusoidalRaw: raw } = await import(
        'd3-geo-projection/src/foucautSinusoidal'
      )
      return { module, raw: raw(0.5) }
    },
    projectionShapeId: projectionShapeIds.OVAL_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.OVAL_ICON,
  },
  {
    id: projectionIds.GILBERT,
    isMorphable: false,
    label: `data_model.projection.${projectionIds.GILBERT}.label`,
    description: `data_model.projection.${projectionIds.GILBERT}.description`,
    projectionMethodLoader: async () => {
      const { default: module } = await import('d3-geo-projection/src/gilbert')
      return { module, raw: null }
    },
    projectionShapeId: projectionShapeIds.CIRCLE_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.CIRCLE_ICON,
  },
  // TODO: The U.S.-centric Gingery world projection, as inspired by Cram’s Air Age. Note: requires clipping to the sphere.
  // @link https://github.com/d3/d3-geo-projection#geoGingery
  {
    id: projectionIds.GINGERY,
    isMorphable: false,
    label: `data_model.projection.${projectionIds.GINGERY}.label`,
    description: `data_model.projection.${projectionIds.GINGERY}.description`,
    projectionMethodLoader: async () => {
      const { default: module, gingeryRaw: raw } = await import('d3-geo-projection/src/gingery')
      return { module, raw: raw((30 * Math.PI) / 180, 6) }
    },
    projectionShapeId: projectionShapeIds.STAR_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.STAR_ICON,
  },
  {
    id: projectionIds.GINZBURG_5,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.GINZBURG_5}.label`,
    description: `data_model.projection.${projectionIds.GINZBURG_5}.description`,
    projectionMethodLoader: async () => {
      const { default: module, ginzburg5Raw: raw } = await import('d3-geo-projection/src/ginzburg5')
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.CONCAVE_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.CONCAVE_ICON,
  },
  {
    id: projectionIds.GRINGORTEN,
    isMorphable: false,
    label: `data_model.projection.${projectionIds.GRINGORTEN}.label`,
    description: `data_model.projection.${projectionIds.GRINGORTEN}.description`,
    projectionMethodLoader: async () => {
      const { default: module, gringortenRaw: raw } = await import(
        'd3-geo-projection/src/gringorten'
      )
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.RECTANGULAR_SHAPE,
    projectionTypeId: projectionTypeIds.CYLINDRICAL,
    projectionIconId: projectionIconIds.RECTANGULAR_ICON,
  },
  {
    id: projectionIds.GUYOU,
    isMorphable: false,
    label: `data_model.projection.${projectionIds.GUYOU}.label`,
    description: `data_model.projection.${projectionIds.GUYOU}.description`,
    projectionMethodLoader: async () => {
      const { default: module, guyouRaw: raw } = await import('d3-geo-projection/src/guyou')
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.RECTANGULAR_SHAPE,
    projectionTypeId: projectionTypeIds.CYLINDRICAL,
    projectionIconId: projectionIconIds.RECTANGULAR_ICON,
  },
  // COMMENT: hi Rouvi Darling, I guess like that you write comments, right? Coolio!
  {
    id: projectionIds.HAMMER,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.HAMMER}.label`,
    description: `data_model.projection.${projectionIds.HAMMER}.description`,
    projectionMethodLoader: async () => {
      const { default: module, hammerRaw: raw } = await import('d3-geo-projection/src/hammer')
      return { module, raw: raw(2) }
    },
    projectionShapeId: projectionShapeIds.OVAL_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.OVAL_ICON,
  },
  // TODO: The HEALPix projection: a Hierarchical Equal Area isoLatitude Pixelisation of a 2-sphere. In this implementation, the parameter K is fixed at 3. Note: requires clipping to the sphere.
  // @link https://github.com/d3/d3-geo-projection#geoHealpix
  {
    id: projectionIds.HEALPIX,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.HEALPIX}.label`,
    description: `data_model.projection.${projectionIds.HEALPIX}.description`,
    projectionMethodLoader: async () => {
      const { default: module, healpixRaw: raw } = await import('d3-geo-projection/src/healpix')
      return { module, raw: raw(4) }
    },
    projectionShapeId: projectionShapeIds.ZICKZACK_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.ZICKZACK_ICON,
  },
  {
    id: projectionIds.KAVRAYSKIY_7,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.KAVRAYSKIY_7}.label`,
    description: `data_model.projection.${projectionIds.KAVRAYSKIY_7}.description`,
    projectionMethodLoader: async () => {
      const { default: module, kavrayskiy7Raw: raw } = await import(
        'd3-geo-projection/src/kavrayskiy7'
      )
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.CURVED_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.CURVED_ICON,
  },
  {
    id: projectionIds.MERCATOR,
    isMorphable: false,
    label: `data_model.projection.${projectionIds.MERCATOR}.label`,
    description: `data_model.projection.${projectionIds.MERCATOR}.description`,
    projectionMethodLoader: async () => {
      const { default: module, mercatorRaw: raw } = await import('d3-geo/src/projection/mercator')
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.RECTANGULAR_SHAPE,
    projectionTypeId: projectionTypeIds.CYLINDRICAL,
    projectionIconId: projectionIconIds.RECTANGULAR_ICON,
  },
  {
    id: projectionIds.MILLER,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.MILLER}.label`,
    description: `data_model.projection.${projectionIds.MILLER}.description`,
    projectionMethodLoader: async () => {
      const { default: module, millerRaw: raw } = await import('d3-geo-projection/src/miller')
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.RECTANGULAR_SHAPE,
    projectionTypeId: projectionTypeIds.CYLINDRICAL,
    projectionIconId: projectionIconIds.RECTANGULAR_ICON,
  },
  {
    id: projectionIds.MOLLWEIDE,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.MOLLWEIDE}.label`,
    description: `data_model.projection.${projectionIds.MOLLWEIDE}.description`,
    projectionMethodLoader: async () => {
      const { default: module, mollweideRaw: raw } = await import('d3-geo-projection/src/mollweide')
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.OVAL_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.OVAL_ICON,
  },
  {
    id: projectionIds.NATURAL_EARTH_1,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.NATURAL_EARTH_1}.label`,
    description: `data_model.projection.${projectionIds.NATURAL_EARTH_1}.description`,
    projectionMethodLoader: async () => {
      const { default: module, naturalEarth1Raw: raw } = await import(
        'd3-geo/src/projection/naturalEarth1'
      )
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.CURVED_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.CURVED_ICON,
  },
  {
    id: projectionIds.NATURAL_EARTH_2,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.NATURAL_EARTH_2}.label`,
    description: `data_model.projection.${projectionIds.NATURAL_EARTH_2}.description`,
    projectionMethodLoader: async () => {
      const { default: module, naturalEarth2Raw: raw } = await import(
        'd3-geo-projection/src/naturalEarth2'
      )
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.CURVED_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.CURVED_ICON,
  },
  {
    id: projectionIds.ORTHOGRAPHIC,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.ORTHOGRAPHIC}.label`,
    description: `data_model.projection.${projectionIds.ORTHOGRAPHIC}.description`,
    projectionMethodLoader: async () => {
      const { default: module, orthographicRaw: raw } = await import(
        'd3-geo/src/projection/orthographic'
      )
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.CIRCLE_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.CIRCLE_ICON,
  },
  {
    id: projectionIds.PATTERSON,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.PATTERSON}.label`,
    description: `data_model.projection.${projectionIds.PATTERSON}.description`,
    projectionMethodLoader: async () => {
      const { default: module, pattersonRaw: raw } = await import('d3-geo-projection/src/patterson')
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.RECTANGULAR_SHAPE,
    projectionTypeId: projectionTypeIds.CYLINDRICAL,
    projectionIconId: projectionIconIds.RECTANGULAR_ICON,
  },
  {
    id: projectionIds.ROBINSON,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.ROBINSON}.label`,
    description: `data_model.projection.${projectionIds.ROBINSON}.description`,
    projectionMethodLoader: async () => {
      const { default: module, robinsonRaw: raw } = await import('d3-geo-projection/src/robinson')
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.CURVED_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.CURVED_ICON,
  },
  {
    id: projectionIds.SINUSOIDAL,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.SINUSOIDAL}.label`,
    description: `data_model.projection.${projectionIds.SINUSOIDAL}.description`,
    projectionMethodLoader: async () => {
      const { default: module, sinusoidalRaw: raw } = await import(
        'd3-geo-projection/src/sinusoidal'
      )
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.SINUSOIDAL_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.SINUSOIDAL_ICON,
  },
  {
    id: projectionIds.STEREOGRAPHIC,
    isMorphable: false,
    label: `data_model.projection.${projectionIds.STEREOGRAPHIC}.label`,
    description: `data_model.projection.${projectionIds.STEREOGRAPHIC}.description`,
    projectionMethodLoader: async () => {
      const { default: module, stereographicRaw: raw } = await import(
        'd3-geo/src/projection/stereographic'
      )
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.CIRCLE_SHAPE,
    projectionTypeId: projectionTypeIds.AZIMUTHAL,
    projectionIconId: projectionIconIds.CIRCLE_ICON,
  },
  {
    id: projectionIds.VAN_DER_GRINTEN_4,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.VAN_DER_GRINTEN_4}.label`,
    description: `data_model.projection.${projectionIds.VAN_DER_GRINTEN_4}.description`,
    projectionMethodLoader: async () => {
      const { default: module, vanDerGrinten4Raw: raw } = await import(
        'd3-geo-projection/src/vanDerGrinten4'
      )
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.DOUBLE_HEMISPHERE_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.DOUBLE_HEMISPHERE_ICON,
  },
  {
    id: projectionIds.WAGNER,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.WAGNER}.label`,
    description: `data_model.projection.${projectionIds.WAGNER}.description`,
    projectionMethodLoader: async () => {
      const { default: module, wagnerRaw: raw } = await import('d3-geo-projection/src/wagner')
      return { module, raw: raw((65 / 180) * Math.PI, (60 / 180) * Math.PI, 0, 200) }
    },
    projectionShapeId: projectionShapeIds.CURVED_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.CURVED_ICON,
  },
  {
    id: projectionIds.WAGNER_4,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.WAGNER_4}.label`,
    description: `data_model.projection.${projectionIds.WAGNER_4}.description`,
    projectionMethodLoader: async () => {
      const { default: module, wagner4Raw: raw } = await import('d3-geo-projection/src/wagner4')
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.CURVED_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.CURVED_ICON,
  },
  {
    id: projectionIds.WAGNER_6,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.WAGNER_6}.label`,
    description: `data_model.projection.${projectionIds.WAGNER_6}.description`,
    projectionMethodLoader: async () => {
      const { default: module, wagner6Raw: raw } = await import('d3-geo-projection/src/wagner6')
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.CURVED_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.CURVED_ICON,
  },
  {
    id: projectionIds.WATERMAN,
    isMorphable: false,
    label: `data_model.projection.${projectionIds.WATERMAN}.label`,
    description: `data_model.projection.${projectionIds.WATERMAN}.description`,
    projectionMethodLoader: async () => {
      const { default: module } = await import('d3-geo-projection/src/polyhedral/waterman')
      return { module, raw: null }
    },
    projectionShapeId: projectionShapeIds.BUTTERFLY_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.BUTTERFLY_ICON,
  },
  {
    id: projectionIds.WINKEL_3,
    isMorphable: true,
    label: `data_model.projection.${projectionIds.WINKEL_3}.label`,
    description: `data_model.projection.${projectionIds.WINKEL_3}.description`,
    projectionMethodLoader: async () => {
      const { default: module, winkel3Raw: raw } = await import('d3-geo-projection/src/winkel3')
      return { module, raw }
    },
    projectionShapeId: projectionShapeIds.CURVED_SHAPE,
    projectionTypeId: projectionTypeIds.COMPROMISE,
    projectionIconId: projectionIconIds.CURVED_ICON,
  },
]
