<template>
  <dialog-card class="overlay-donation-content">
    <template #header>
      {{ $t('donation_content.title') }}
    </template>
    <template #default>
      <b>{{ $t('donation_content.sub_title') }}</b>
      <br />
      <br />
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="$t('donation_content.text')" />
    </template>
    <template #footer="{ close }">
      <n-link
        :to="localePath({ name: 'page', params: { page: 'donate' } })"
        class="overlay-donation-content__btn btn btn--primary btn--small"
        @click.native="close"
      >
        {{ $t('donation_content.donate_link_label') }}
      </n-link>
      <n-link
        :to="localePath({ name: 'wizard-step-step', params: { step: 'end' } })"
        class="overlay-donation-content__btn btn btn--primary btn--small"
        @click.native="close"
      >
        {{ $t('donation_content.end_link_label') }}
      </n-link>
    </template>
  </dialog-card>
</template>

<script>
  import DialogCard from '@/components/dialog-card/dialog-card'

  export default {
    components: { DialogCard },
    props: {},
    donationHref: `https://opencollective.com/worldmapgenerator/contribute/travel-around-the-world-reise-um-die-welt-23589/checkout`,
  }
</script>

<style lang="scss" src="./_overlay-donation-content.scss" />
