import { layerIds } from '@/assets/js/model/layer'
import { styleAttributeIds } from '@/assets/js/model/style-attributes'
import { mapThemeTypeIds } from '@/assets/js/model/map-theme-type'

export const mapThemeIds = {
  BLUE_TONES_1: 'BLUE_TONES_1',
  BLUE_TONES_2: 'BLUE_TONES_2',
  BLUE_TONES_3: 'BLUE_TONES_3',
  BLUE_TONES_4: 'BLUE_TONES_4',
  BLUE_TONES_5: 'BLUE_TONES_5',
}

export const mapThemeList = [
  {
    id: mapThemeIds.BLUE_TONES_1,
    mapThemeType: mapThemeTypeIds.BLUE_TONES,
    label: `data_model.map_theme.${mapThemeIds.BLUE_TONES_1}`,
    layerStyleList: [
      {
        layerIds: [layerIds.BACKGROUND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#382f32',
        },
      },
      {
        layerIds: [layerIds.SPHERE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#fffcdb',
          [styleAttributeIds.LINE_WIDTH]: 4,
        },
      },
      {
        layerIds: [layerIds.LINES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#382f32',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.GRATICULES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#382f32',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
          [styleAttributeIds.LINE_DASH]: [2, 2],
        },
      },
      {
        layerIds: [layerIds.LAND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#fffcdb',
        },
      },
      {
        layerIds: [layerIds.OCEAN],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#ccebf8',
        },
      },
      {
        layerIds: [layerIds.COASTLINE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#382f32',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
        },
      },
      {
        layerIds: [layerIds.LAKES],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#ccebf8',
          [styleAttributeIds.LINE_COLOR]: '#ccebf8',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.RIVERS],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#ccebf8',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.COUNTRIES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#382f32',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.TISSOT_INDICATRIX],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#000000cc',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
          [styleAttributeIds.FILL_COLOR]: '#0000004d',
        },
      },
    ],
  },
  {
    id: mapThemeIds.BLUE_TONES_2,
    mapThemeType: mapThemeTypeIds.BLUE_TONES,
    label: `data_model.map_theme.${mapThemeIds.BLUE_TONES_2}`,
    layerStyleList: [
      {
        layerIds: [layerIds.BACKGROUND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#382f32',
        },
      },
      {
        layerIds: [layerIds.SPHERE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#fffcdb',
          [styleAttributeIds.LINE_WIDTH]: 4,
        },
      },
      {
        layerIds: [layerIds.LINES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#382f32',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.GRATICULES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#382f32',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
          [styleAttributeIds.LINE_DASH]: [2, 2],
        },
      },
      {
        layerIds: [layerIds.LAND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#fffcdb',
        },
      },
      {
        layerIds: [layerIds.OCEAN],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#99d7f1',
        },
      },
      {
        layerIds: [layerIds.COASTLINE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#382f32',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
        },
      },
      {
        layerIds: [layerIds.LAKES],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#99d7f1',
          [styleAttributeIds.LINE_COLOR]: '#99d7f1',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.RIVERS],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#99d7f1',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.COUNTRIES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#382f32',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.TISSOT_INDICATRIX],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#000000cc',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
          [styleAttributeIds.FILL_COLOR]: '#0000004d',
        },
      },
    ],
  },
  {
    id: mapThemeIds.BLUE_TONES_3,
    mapThemeType: mapThemeTypeIds.BLUE_TONES,
    label: `data_model.map_theme.${mapThemeIds.BLUE_TONES_3}`,
    layerStyleList: [
      {
        layerIds: [layerIds.BACKGROUND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#382f32',
        },
      },
      {
        layerIds: [layerIds.SPHERE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#fffcdb',
          [styleAttributeIds.LINE_WIDTH]: 4,
        },
      },
      {
        layerIds: [layerIds.LINES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#382f32',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.GRATICULES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#382f32',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
          [styleAttributeIds.LINE_DASH]: [2, 2],
        },
      },
      {
        layerIds: [layerIds.LAND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#fffcdb',
        },
      },
      {
        layerIds: [layerIds.OCEAN],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#009bdc',
        },
      },
      {
        layerIds: [layerIds.COASTLINE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#382f32',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
        },
      },
      {
        layerIds: [layerIds.LAKES],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#009bdc',
          [styleAttributeIds.LINE_COLOR]: '#009bdc',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.RIVERS],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#009bdc',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.COUNTRIES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#382f32',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.TISSOT_INDICATRIX],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#000000cc',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
          [styleAttributeIds.FILL_COLOR]: '#0000004d',
        },
      },
    ],
  },
  {
    id: mapThemeIds.BLUE_TONES_4,
    mapThemeType: mapThemeTypeIds.BLUE_TONES,
    label: `data_model.map_theme.${mapThemeIds.BLUE_TONES_4}`,
    layerStyleList: [
      {
        layerIds: [layerIds.BACKGROUND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#382f32',
        },
      },
      {
        layerIds: [layerIds.SPHERE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#fffcdb',
          [styleAttributeIds.LINE_WIDTH]: 4,
        },
      },
      {
        layerIds: [layerIds.LINES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#382f32',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.GRATICULES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#382f32',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
          [styleAttributeIds.LINE_DASH]: [2, 2],
        },
      },
      {
        layerIds: [layerIds.LAND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#fffcdb',
        },
      },
      {
        layerIds: [layerIds.OCEAN],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#0074a5',
        },
      },
      {
        layerIds: [layerIds.COASTLINE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#382f32',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
        },
      },
      {
        layerIds: [layerIds.LAKES],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#0074a5',
          [styleAttributeIds.LINE_COLOR]: '#0074a5',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.RIVERS],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#0074a5',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.COUNTRIES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#382f32',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.TISSOT_INDICATRIX],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#000000cc',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
          [styleAttributeIds.FILL_COLOR]: '#0000004d',
        },
      },
    ],
  },
  {
    id: mapThemeIds.BLUE_TONES_5,
    mapThemeType: mapThemeTypeIds.BLUE_TONES,
    label: `data_model.map_theme.${mapThemeIds.BLUE_TONES_5}`,
    layerStyleList: [
      {
        layerIds: [layerIds.BACKGROUND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#382f32',
        },
      },
      {
        layerIds: [layerIds.SPHERE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#fffcdb',
          [styleAttributeIds.LINE_WIDTH]: 4,
        },
      },
      {
        layerIds: [layerIds.LINES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#382f32',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.GRATICULES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#382f32',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
          [styleAttributeIds.LINE_DASH]: [2, 2],
        },
      },
      {
        layerIds: [layerIds.LAND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#fffcdb',
        },
      },
      {
        layerIds: [layerIds.OCEAN],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#004e6e',
        },
      },
      {
        layerIds: [layerIds.COASTLINE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#382f32',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
        },
      },
      {
        layerIds: [layerIds.LAKES],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#004e6e',
          [styleAttributeIds.LINE_COLOR]: '#004e6e',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.RIVERS],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#004e6e',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.COUNTRIES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#382f32',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.TISSOT_INDICATRIX],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#000000cc',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
          [styleAttributeIds.FILL_COLOR]: '#0000004d',
        },
      },
    ],
  },
]
