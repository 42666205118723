<template>
  <div class="language-menu">
    <ul class="u-reset language-menu__item-list">
      <li v-for="locale in $i18n.locales" :key="locale.code" class="language-menu__item">
        <n-link
          v-if="!isCurrentLocale(locale)"
          :class="getBtnClasses(locale)"
          :to="switchLocalePath(locale.code)"
          class="btn btn--medium btn--primary language-menu__link u-reset"
          @click.native="onClick(locale)"
        >
          {{ locale.code }}
        </n-link>
        <span
          v-else
          class="btn btn--medium language-menu__link language-menu__link--is-current u-reset"
        >
          {{ locale.code }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import bemMixin from '@/assets/js/mixin/bem'

  export default {
    mixins: [bemMixin('language-menu')],
    methods: {
      ...mapActions('navigation', ['setMenu']),
      getBtnClasses(locale) {
        return [this.bemAdd(this.isCurrentLocale(locale) ? 'is-current' : '', 'btn')]
      },
      isCurrentLocale(locale) {
        return locale.code === this.$i18n.locale
      },
      onClick(locale) {
        this.setMenu({ isOpen: false })
        this.$i18n.setLocale(locale.code)
      },
    },
  }
</script>

<style lang="scss" src="./_language-menu.scss" />
