import { wizardStepIds, wizardStepParams } from '@/assets/js/model/route'
import { personaIds } from '@/assets/js/model/persona'
import { mapConfigIds } from '@/assets/js/model/map-config'
import { layerIds } from '@/assets/js/model/layer'
import { projectionIds } from '@/assets/js/model/projection'
import { renderingAdapterIds } from '@/assets/js/model/export'
import { mapThemeIds } from '@/assets/js/model/map-theme'

// NOTE: When applying waterman projection, we need to add 45° to the longitude to center by rotation
export const wizardStepList = [
  {
    id: wizardStepIds.PERSONA_WIZARD_MERIAN_STEP_1,
    personaId: personaIds.MERIAN,
    label: `data_model.persona.${personaIds.MERIAN}.stations[0]`,
    param: wizardStepParams[wizardStepIds.PERSONA_WIZARD_MERIAN_STEP_1],
    icon: '/images/wizard_step_icons/merian/01_nav_merian_schmetterling_50_50@3x.png',
    inNav: true,
    component: () => import('@/components/wizard-step-persona-content/wizard-step-persona-content'),
    mapConfig: {
      id: mapConfigIds.PERSONA_WIZARD,
      layerIds: [
        layerIds.CLIP_PATH,
        layerIds.SPHERE,
        layerIds.GRATICULES,
        layerIds.LAND,
        layerIds.OCEAN,
        layerIds.COASTLINE,
        layerIds.PERSONA_MERIAN_JOURNEY,
        layerIds.PERSONA_MERIAN_STATIONS,
      ],
      projectionId: projectionIds.FAHEY,
      renderingAdapterId: renderingAdapterIds.SVG,
      mapThemeId: mapThemeIds.PERSONA_MERIAN_INITIAL,
      center: [0, 0],
      rotation: [-8.684005737304688, -50.10957035047152, 0],
      zoom: 1,
      wizardStepProgressIdx: 0,
      version: '1.0.0',
    },
  },
  {
    id: wizardStepIds.PERSONA_WIZARD_MERIAN_STEP_2,
    personaId: personaIds.MERIAN,
    label: `data_model.persona.${personaIds.MERIAN}.stations[1]`,
    param: wizardStepParams[wizardStepIds.PERSONA_WIZARD_MERIAN_STEP_2],
    icon: '/images/wizard_step_icons/merian/02_nav_merian_buch_50_50@3x.png',
    inNav: true,
    component: () => import('@/components/wizard-step-persona-content/wizard-step-persona-content'),
    mapConfig: {
      id: mapConfigIds.PERSONA_WIZARD,
      layerIds: [
        layerIds.CLIP_PATH,
        layerIds.SPHERE,
        layerIds.GRATICULES,
        layerIds.LAND,
        layerIds.OCEAN,
        layerIds.COASTLINE,
        layerIds.PERSONA_MERIAN_JOURNEY,
        layerIds.PERSONA_MERIAN_STATIONS,
      ],
      projectionId: projectionIds.WATERMAN,
      renderingAdapterId: renderingAdapterIds.SVG,
      mapThemeId: mapThemeIds.PERSONA_MERIAN_INITIAL,
      center: [0, 0],
      rotation: [-5.690145492553711, -53.10930343761107 + 45 /* waterman center */, 0],
      zoom: 1,
      wizardStepProgressIdx: 0,
      version: '1.0.0',
    },
  },
  {
    id: wizardStepIds.PERSONA_WIZARD_MERIAN_STEP_3,
    personaId: personaIds.MERIAN,
    label: `data_model.persona.${personaIds.MERIAN}.stations[2]`,
    param: wizardStepParams[wizardStepIds.PERSONA_WIZARD_MERIAN_STEP_3],
    icon: '/images/wizard_step_icons/merian/03_nav_merian_ananans_50_50@3x.png',
    inNav: true,
    component: () => import('@/components/wizard-step-persona-content/wizard-step-persona-content'),
    mapConfig: {
      id: mapConfigIds.PERSONA_WIZARD,
      layerIds: [
        layerIds.CLIP_PATH,
        layerIds.SPHERE,
        layerIds.GRATICULES,
        layerIds.LAND,
        layerIds.OCEAN,
        layerIds.COASTLINE,
        layerIds.PERSONA_MERIAN_JOURNEY,
        layerIds.PERSONA_MERIAN_STATIONS,
      ],
      projectionId: projectionIds.WATERMAN,
      renderingAdapterId: renderingAdapterIds.SVG,
      mapThemeId: mapThemeIds.PERSONA_MERIAN,
      center: [0, 0],
      rotation: [53.349609375, -8.347388083076348 + 45 /* waterman center */, 0],
      zoom: 1.5,
      wizardStepProgressIdx: 0,
      version: '1.0.0',
    },
  },
  {
    id: wizardStepIds.PERSONA_WIZARD_MERIAN_STEP_4,
    personaId: personaIds.MERIAN,
    label: `data_model.persona.${personaIds.MERIAN}.stations[3]`,
    param: wizardStepParams[wizardStepIds.PERSONA_WIZARD_MERIAN_STEP_4],
    icon: '/images/wizard_step_icons/merian/04_nav_merian_glasglocken_50_50@3x.png',
    inNav: true,
    component: () => import('@/components/wizard-step-persona-content/wizard-step-persona-content'),
    mapConfig: {
      id: mapConfigIds.PERSONA_WIZARD,
      layerIds: [
        layerIds.CLIP_PATH,
        layerIds.SPHERE,
        layerIds.GRATICULES,
        layerIds.LAND,
        layerIds.OCEAN,
        layerIds.COASTLINE,
        layerIds.COUNTRIES,
        layerIds.PERSONA_MERIAN_JOURNEY,
        layerIds.PERSONA_MERIAN_STATIONS,
      ],
      projectionId: projectionIds.WATERMAN,
      renderingAdapterId: renderingAdapterIds.SVG,
      mapThemeId: mapThemeIds.PERSONA_MERIAN,
      center: [0, 0],
      rotation: [55.17333984375, -5.7908968128719565 + 45 /* waterman center */, 0],
      zoom: 9.5,
      wizardStepProgressIdx: 0,
      version: '1.0.0',
    },
  },
  {
    id: wizardStepIds.PERSONA_WIZARD_MERIAN_STEP_5,
    personaId: personaIds.MERIAN,
    label: `data_model.persona.${personaIds.MERIAN}.stations[4]`,
    param: wizardStepParams[wizardStepIds.PERSONA_WIZARD_MERIAN_STEP_5],
    icon: '/images/wizard_step_icons/merian/05_nav_merian_glasglocken_50_50@3x.png',
    inNav: true,
    component: () => import('@/components/wizard-step-persona-content/wizard-step-persona-content'),
    mapConfig: {
      id: mapConfigIds.PERSONA_WIZARD,
      layerIds: [
        layerIds.CLIP_PATH,
        layerIds.SPHERE,
        layerIds.GRATICULES,
        layerIds.LAND,
        layerIds.OCEAN,
        layerIds.COASTLINE,
        layerIds.LAKES,
        layerIds.RIVERS,
        layerIds.COUNTRIES,
        layerIds.PERSONA_MERIAN_JOURNEY,
        layerIds.PERSONA_MERIAN_STATIONS,
      ],
      projectionId: projectionIds.WATERMAN,
      renderingAdapterId: renderingAdapterIds.SVG,
      mapThemeId: mapThemeIds.PERSONA_MERIAN,
      center: [0, 0],
      rotation: [-4.896812438964843, -52.373083994540266 + 45 /* waterman center */, 0],
      zoom: 2,
      wizardStepProgressIdx: 0,
      version: '1.0.0',
    },
  },
  {
    id: wizardStepIds.PERSONA_WIZARD_MERIAN_STEP_6,
    personaId: personaIds.MERIAN,
    label: `data_model.persona.${personaIds.MERIAN}.stations[5]`,
    param: wizardStepParams[wizardStepIds.PERSONA_WIZARD_MERIAN_STEP_6],
    icon: '/images/wizard_step_icons/merian/06_nav_merian_karte_50_50@3x.png',
    inNav: true,
    component: () => import('@/components/wizard-step-persona-content/wizard-step-persona-content'),
    mapConfig: {
      id: mapConfigIds.PERSONA_WIZARD,
      layerIds: [
        layerIds.CLIP_PATH,
        layerIds.SPHERE,
        layerIds.GRATICULES,
        layerIds.LAND,
        layerIds.OCEAN,
        layerIds.COASTLINE,
        layerIds.LAKES,
        layerIds.RIVERS,
        layerIds.COUNTRIES,
        layerIds.PERSONA_MERIAN_JOURNEY,
        layerIds.PERSONA_MERIAN_STATIONS,
      ],
      projectionId: projectionIds.WATERMAN,
      renderingAdapterId: renderingAdapterIds.SVG,
      mapThemeId: mapThemeIds.PERSONA_MERIAN,
      center: [0, 0],
      rotation: [-8.684005737304688, -50.10957035047152 + 45 /* waterman center */, 0],
      zoom: 1,
      wizardStepProgressIdx: 0,
      version: '1.0.0',
    },
  },
]
