export default ({ app }) => {
  let popStateDetected = false
  const { router, store } = app

  window.addEventListener('popstate', () => {
    popStateDetected = true
  })

  router.beforeEach((_, __, next) => {
    if (!popStateDetected || !store.getters['overlay/hasRouteRelevantOverlays']) {
      return next()
    }

    Object.values(store.state.overlay.overlays).forEach(({ id }) => {
      store.dispatch('navigation/setMenu', { isOpen: false })
      store.dispatch('overlay/closeOverlay', { id, disableScroll: false }, { root: true })
    })

    popStateDetected = false

    return next(false)
  })
}
