import cube from 'd3-geo-polygon/src/polyhedral/cube'
import voronoi from 'd3-geo-polygon/src/polyhedral/voronoi'

export function cubic() {
  const polygons = {
    type: 'FeatureCollection',
    features: cube.map(function (face) {
      face = face.slice()
      face.push(face[0])
      return {
        geometry: {
          type: 'Polygon',
          coordinates: [face],
        },
      }
    }),
  }

  const parents = [-1, 2, 0, 2, 3, 2]

  return voronoi()
    .polygons(polygons)
    .parents(parents)
    .angle(0)
    .scale(96.8737)
    .center([135, -45])
    .rotate([120, 0])
}
