<template>
  <div class="dialog-card">
    <div class="dialog-card__header">
      <div class="dialog-card__title">
        <slot name="header" />
      </div>
      <button class="dialog-card__close-btn" @click="onClose">
        <ui-icon name="close" />
      </button>
    </div>
    <div class="dialog-card__body">
      <slot name="default" />
    </div>
    <div class="dialog-card__footer">
      <slot name="footer" :close="onClose" />
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import UiIcon from '@/components/ui-icon/ui-icon'
  import { overlayInstanceIds } from '@/assets/js/model/overlay-instance'

  export default {
    components: { UiIcon },
    props: {},
    methods: {
      ...mapActions('overlay', ['closeOverlay']),
      onClose() {
        this.closeOverlay({
          id: overlayInstanceIds.DONATION_CONTENT,
          transition: 'trs-simple-fade',
        })
      },
    },
  }
</script>

<style lang="scss" src="./_dialog-card.scss" />
