<template>
  <nav class="social-media-navigation">
    <ul class="u-reset social-media-navigation__groups">
      <li
        v-for="(socialMediaGroup, socialMediaGroupId) in socialMediaGroupList"
        :key="socialMediaGroupId"
        class="social-media-navigation__group"
        :class="getSocialMediaGroupClasses(socialMediaGroupId)"
      >
        <span class="social-media-navigation__group-title">
          {{ $t(getSocialMediaGroup(socialMediaGroupId).label) }}
        </span>
        <ul class="u-reset social-media-navigation__items">
          <li
            v-for="{ icon, id, link } in socialMediaGroup"
            :key="id"
            class="social-media-navigation__item"
          >
            <a
              :href="link"
              target="_blank"
              rel="noopener noreferrer"
              class="social-media-navigation__btn btn btn--bubble"
            >
              <responsive-img
                class="social-media-navigation__step-icon"
                :src="icon.base"
                :width="26"
                alt=""
              />
              <responsive-img
                class="social-media-navigation__step-icon social-media-navigation__step-icon--active"
                :src="icon.active"
                :width="26"
                alt=""
              />
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
  import { groupBy, kebabCase } from 'lodash-es'
  import bemMixin from '@/assets/js/mixin/bem'
  import { socialMediaGroupList, socialMediaList } from '@/assets/js/model/social-media'
  import { getImage } from '@/assets/js/util/misc/image'
  import ResponsiveImg from '~/components/responsive-img/responsive-img'

  export default {
    components: { ResponsiveImg },
    mixins: [bemMixin('social-media-navigation')],
    props: {},
    computed: {
      socialMediaGroupList() {
        const filteredList = this.$options.socialMediaList.filter((item) => {
          const { inNavigation } = this.getSocialMediaGroup(item.socialMediaGroupId)
          return inNavigation
        })
        return groupBy(filteredList, (item) => item.socialMediaGroupId)
      },
    },
    methods: {
      getSocialMediaGroup(id) {
        return socialMediaGroupList.find((socialMediaGroup) => {
          return socialMediaGroup.id === id
        })
      },
      getSocialMediaGroupClasses(id) {
        const socialMediaGroup = this.getSocialMediaGroup(id)
        return [this.bemAdd(kebabCase(socialMediaGroup.id), 'group')]
      },
    },
    getImage,
    socialMediaList,
  }
</script>

<style lang="scss" src="./_social-media-navigation.scss" />
