import Vue from 'vue'
import * as actions from './actions'
import {
  SET_LAYER_DATA,
  SET_MAP_CONFIG,
  SET_PROJECTION_DATA,
  SET_RENDERING_ADAPTER_DATA,
  UPDATE_MAP_CONFIG,
} from '@/store/world-map/mutation-types'
import { populateMapConfigs } from '@/store/world-map/util'

const mutations = {
  [SET_MAP_CONFIG]({ mapConfigs }, config) {
    Vue.set(mapConfigs, config.id, config)
  },
  [SET_LAYER_DATA]({ layerData }, { id, data }) {
    Vue.set(layerData, id, data)
  },
  [SET_PROJECTION_DATA]({ projectionData }, { id, ...rest }) {
    Vue.set(projectionData, id, { id, ...rest })
  },
  [SET_RENDERING_ADAPTER_DATA]({ renderingAdapterData }, { id, data }) {
    Vue.set(renderingAdapterData, id, data)
  },
  [UPDATE_MAP_CONFIG]({ mapConfigs }, config) {
    Vue.set(mapConfigs, config.id, { ...mapConfigs[config.id], ...config })
  },
}

const mapConfigs = populateMapConfigs()

const state = () => {
  return {
    layerData: {},
    projectionData: {},
    renderingAdapterData: {},
    mapConfigs,
  }
}

export default {
  namespaced: true,
  mutations,
  actions,
  state,
}
