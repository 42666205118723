import Cookies from 'js-cookie'
import { COOKIE_BANNER_COOKIE_NAME, parseCookieBannerCookie } from '@/store/cookie-banner/util'
import { overlayInstanceIds } from '@/assets/js/model/overlay-instance'
import { SET_COOKIE_ACCEPTED } from '~/store/cookie-banner/mutation-types'

export function nuxtClientInit({ commit }) {
  commit(SET_COOKIE_ACCEPTED, { isAccepted: parseCookieBannerCookie() })
}

export function showCookieBanner({ dispatch }) {
  if (parseCookieBannerCookie()) {
    return
  }

  dispatch(
    'overlay/openOverlay',
    {
      id: overlayInstanceIds.COOKIE_BANNER,
      transition: 'trs-simple-fade',
      facets: ['cookie-banner'],
      component: 'overlay-cookie-banner-content',
    },
    { root: true },
  )
}

export function acceptCookies({ commit, dispatch }) {
  Cookies.set(COOKIE_BANNER_COOKIE_NAME, true)

  commit(SET_COOKIE_ACCEPTED, { isAccepted: true })
  dispatch(
    'overlay/closeOverlay',
    {
      id: overlayInstanceIds.COOKIE_BANNER,
      transition: 'trs-simple-fade',
    },
    { root: true },
  )
}
