import circleToPolygon from 'circle-to-polygon'
import { mapValue } from '~/assets/js/util/dom/map-value'

let x, y
const radius = 640000
const numLon = 12
const numLat = 6
const offset = 0.01

const result = {
  type: 'FeatureCollection',
  features: [],
}

for (x = 0; x < numLon; x += 1) {
  for (y = 0; y <= numLat; y += 1) {
    const lon = mapValue(x, [0, numLon], [-180 + offset, 180 - offset])
    const lat = mapValue(y, [0, numLat], [-90 + offset, 90 - offset])
    const geometry = circleToPolygon([lon, lat], radius, { numberOfEdges: 64, rightHandRule: true })
    const feature = { type: 'Feature', geometry }

    if (y === 0 || y === numLat) {
      if (x === 0) {
        result.features.push(feature)
      }
    } else {
      result.features.push(feature)
    }
  }
}

export default result
