import { render, staticRenderFns } from "./interstitial-content.vue?vue&type=template&id=2ecbb5fe&"
import script from "./interstitial-content.vue?vue&type=script&lang=js&"
export * from "./interstitial-content.vue?vue&type=script&lang=js&"
import style0 from "./_interstitial-content.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports