import { mapConfigList } from '@/assets/js/model/map-config'

/**
 * Round a number to a certain precision
 * @param {number} number
 * @param {number} decimalPlaces
 * @return {number}
 */
function roundAccurately(number, decimalPlaces) {
  return Number(Math.round(parseFloat(`${number}e${decimalPlaces}`)) + `e-${decimalPlaces}`)
}

/**
 * Clip all rotational floating point numbers to a certain precision
 * @param {number[]} rotation - The rotational number
 * @return {number[]}
 */
export function reduceRotationPrecision(rotation) {
  return rotation.map((rotationParam) => roundAccurately(rotationParam, 8))
}

/**
 * Clip the zoom level floating point number to a certain precision
 * @param {number} zoom - The zoom level number
 * @return {number}
 */
export function reduceZoomPrecision(zoom) {
  return roundAccurately(zoom, 8)
}

export function populateMapConfigs() {
  return mapConfigList.reduce((acc, item) => {
    acc[item.id] = item
    return acc
  }, {})
}
