<template>
  <div :class="rootClasses" class="map-tooltip">
    <div :style="transformStyles" class="map-tooltip__btn-wrap">
      <button
        class="map-tooltip__btn"
        @mouseenter="isHovered = true"
        @mouseleave="isHovered = false"
      />
    </div>
    <transition name="trs-to-top">
      <ui-tooltip
        v-if="showTooltip"
        :style="transformStyles"
        class="map-tooltip__tooltip"
        origin-x="center"
        origin-y="start"
        overlay-x="center"
        overlay-y="end"
      >
        {{ $t(label) }}
      </ui-tooltip>
    </transition>
  </div>
</template>

<script>
  import UiTooltip from '@/components/ui-tooltip/ui-tooltip'
  import bemMixin from '@/assets/js/mixin/bem'

  export default {
    components: { UiTooltip },
    mixins: [bemMixin('map-tooltip')],
    props: {
      isCurrentStation: {
        type: Boolean,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      position: {
        type: process.client ? [SVGPoint, Object] : Object,
        required: true,
      },
      stationIdx: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        isHovered: false,
      }
    },
    computed: {
      rootClasses() {
        return [
          this.bemAdd(this.showTooltip ? 'is-shown' : ''),
          this.bemAdd(parseInt(this.$route.params.step, 10) === 1 ? 'is-first-step' : ''),
        ]
      },
      showTooltip() {
        return this.isHovered || this.isCurrentStation
      },
      transformStyles() {
        return {
          left: `${this.position.x}px`,
          top: `${this.position.y}px`,
        }
      },
    },
    watch: {
      isHovered(value) {
        this.$emit('tooltip-hovered', this.stationIdx, value)
      },
    },
  }
</script>

<style lang="scss" src="./_map-tooltip.scss"></style>
