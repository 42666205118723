export const mainMenuIds = {
  HOME: 'HOME',
  CONTACT: 'CONTACT',
  TEAM: 'TEAM',
  CREDITS: 'CREDITS',
  THEORY: 'THEORY',
  DONATE: 'DONATE',
  GALLERY: 'GALLERY',
  LICENCE: 'LICENCE',
  SHOP: 'SHOP',
}

export const mainMenuList = [
  {
    label: `data_model.main_menu.${mainMenuIds.HOME}`,
    link: { name: 'index' },
    image: {
      src: '/images/menu_icons/home@3x.png',
      width: 50,
    },
    id: mainMenuIds.HOME,
  },
  {
    label: `data_model.main_menu.${mainMenuIds.CONTACT}`,
    link: { name: 'page', params: { page: 'contact' } },
    id: mainMenuIds.CONTACT,
  },
  {
    label: `data_model.main_menu.${mainMenuIds.TEAM}`,
    link: { name: 'page', params: { page: 'team' } },
    id: mainMenuIds.TEAM,
  },
  {
    label: `data_model.main_menu.${mainMenuIds.CREDITS}`,
    link: { name: 'page', params: { page: 'credits' } },
    id: mainMenuIds.CREDITS,
  },
  {
    label: `data_model.main_menu.${mainMenuIds.THEORY}`,
    link: { name: 'page', params: { page: 'theory' } },
    id: mainMenuIds.THEORY,
  },
  {
    label: `data_model.main_menu.${mainMenuIds.DONATE}`,
    link: { name: 'page', params: { page: 'donate' } },
    id: mainMenuIds.DONATE,
  },
  {
    label: `data_model.main_menu.${mainMenuIds.GALLERY}`,
    externalLink: 'https://maps.worldmapgenerator.com/list',
    id: mainMenuIds.GALLERY,
  },
  {
    label: `data_model.main_menu.${mainMenuIds.LICENCE}`,
    link: { name: 'page', params: { page: 'licence' } },
    id: mainMenuIds.LICENCE,
  },
  {
    label: `data_model.main_menu.${mainMenuIds.SHOP}`,
    link: { name: 'page', params: { page: 'shop' } },
    id: mainMenuIds.SHOP,
  },
]
