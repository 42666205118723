<template>
  <div class="overlay-tooltip-base-content">
    <ui-tooltip
      :origin-x="originPosition.x"
      :origin-y="originPosition.y"
      :overlay-x="overlayPosition.x"
      :overlay-y="overlayPosition.y"
      :style="tooltipStyles"
      class="overlay-tooltip-base-content__tooltip"
    >
      {{ $t(label) }}
    </ui-tooltip>
  </div>
</template>

<script>
  import UiTooltip from '@/components/ui-tooltip/ui-tooltip'

  export default {
    components: { UiTooltip },
    props: {
      position: {
        type: process.client ? [SVGPoint, Object] : Object,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      originPosition: {
        type: Object,
        required: true,
      },
      overlayPosition: {
        type: Object,
        required: true,
      },
    },
    computed: {
      tooltipStyles() {
        return {
          transform: `translate(${this.position.x}px, ${this.position.y}px)`,
        }
      },
    },
  }
</script>

<style lang="scss" src="./_overlay-tooltip-base-content.scss" />
