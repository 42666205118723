export const wizardStepIds = {
  PERSONA_WIZARD_MERIAN_STEP_1: 'PERSONA_WIZARD_MERIAN_STEP_1',
  PERSONA_WIZARD_MERIAN_STEP_2: 'PERSONA_WIZARD_MERIAN_STEP_2',
  PERSONA_WIZARD_MERIAN_STEP_3: 'PERSONA_WIZARD_MERIAN_STEP_3',
  PERSONA_WIZARD_MERIAN_STEP_4: 'PERSONA_WIZARD_MERIAN_STEP_4',
  PERSONA_WIZARD_MERIAN_STEP_5: 'PERSONA_WIZARD_MERIAN_STEP_5',
  PERSONA_WIZARD_MERIAN_STEP_6: 'PERSONA_WIZARD_MERIAN_STEP_6',
  PERSONA_WIZARD_MERIAN_STEP_7: 'PERSONA_WIZARD_MERIAN_STEP_7',
}

export const wizardStepParams = {
  [wizardStepIds.PERSONA_WIZARD_MERIAN_STEP_1]: '1',
  [wizardStepIds.PERSONA_WIZARD_MERIAN_STEP_2]: '2',
  [wizardStepIds.PERSONA_WIZARD_MERIAN_STEP_3]: '3',
  [wizardStepIds.PERSONA_WIZARD_MERIAN_STEP_4]: '4',
  [wizardStepIds.PERSONA_WIZARD_MERIAN_STEP_5]: '5',
  [wizardStepIds.PERSONA_WIZARD_MERIAN_STEP_6]: '6',
  [wizardStepIds.PERSONA_WIZARD_MERIAN_STEP_7]: '7',
}
