import tissot from '~/assets/js/util/layer/tissot'

export const layerGroupIds = {
  PRE_CLIP: 'PRE_CLIP',
  CLIP_ROOT: 'ROOT',
  POST_CLIP: 'POST_CLIP',
}

export const layerTypeIds = {
  META: 'META',
  BASIC: 'BASIC',
  CARTOGRAPHIC: 'CARTOGRAPHIC',
  PHYSICAL: 'PHYSICAL',
  CULTURAL: 'CULTURAL',
  PERSONA_JOURNEY: 'PERSONA_JOURNEY',
  PERSONA_STATIONS: 'PERSONA_STATIONS',
}

export const layerIds = {
  CLIP_PATH: 'CLIP_PATH',
  BACKGROUND: 'BACKGROUND',
  SPHERE: 'SPHERE',
  SPHERE_BACKGROUND: 'SPHERE_BACKGROUND',
  LINES: 'LINES',
  GRATICULES: 'GRATICULES',
  TISSOT_INDICATRIX: 'TISSOT_INDICATRIX',
  LAND: 'LAND',
  OCEAN: 'OCEAN',
  COASTLINE: 'COASTLINE',
  LAKES: 'LAKES',
  RIVERS: 'RIVERS',
  COUNTRIES: 'COUNTRIES',
  DISPUTED: 'DISPUTED',
  PERSONA_AMUNDSEN_JOURNEY: 'PERSONA_AMUNDSEN_JOURNEY',
  PERSONA_MERIAN_JOURNEY: 'PERSONA_MERIAN_JOURNEY',
  PERSONA_JACK_JOURNEY: 'PERSONA_JACK_JOURNEY',
  PERSONA_AMUNDSEN_STATIONS: 'PERSONA_AMUNDSEN_STATIONS',
  PERSONA_MERIAN_STATIONS: 'PERSONA_MERIAN_STATIONS',
  PERSONA_JACK_STATIONS: 'PERSONA_JACK_STATIONS',
  WATERMARK: 'WATERMARK',
}

export const layerGroupList = [
  {
    id: layerGroupIds.PRE_CLIP,
    groupId: 'pre_root',
    hasClipPath: false,
  },
  {
    id: layerGroupIds.CLIP_ROOT,
    groupId: 'root',
    hasClipPath: true,
  },
  {
    id: layerGroupIds.POST_CLIP,
    groupId: 'post_root',
    hasClipPath: false,
  },
]

export const layerTypeList = [
  {
    id: layerTypeIds.BASIC,
    label: `data_model.layer_type.${layerTypeIds.BASIC}`,
  },
  {
    id: layerTypeIds.CARTOGRAPHIC,
    label: `data_model.layer_type.${layerTypeIds.CARTOGRAPHIC}`,
  },
  {
    id: layerTypeIds.PHYSICAL,
    label: `data_model.layer_type.${layerTypeIds.PHYSICAL}`,
  },
  {
    id: layerTypeIds.CULTURAL,
    label: `data_model.layer_type.${layerTypeIds.CULTURAL}`,
  },
  {
    id: layerTypeIds.PERSONA_JOURNEY,
    label: null,
  },
  {
    id: layerTypeIds.PERSONA_STATIONS,
    label: null,
  },
]

export const layerList = [
  {
    id: layerIds.WATERMARK,
    pathId: layerIds.WATERMARK.toLowerCase(),
    label: '',
    layerIndex: -1,
    layerType: layerTypeIds.META,
    layerGroupId: layerGroupIds.POST_CLIP,
    styleLayer: false,
    clipLayer: false,
    layerDataLoader: () => JSON.parse('null'),
  },
  {
    id: layerIds.CLIP_PATH,
    pathId: layerIds.CLIP_PATH.toLowerCase(),
    label: '',
    layerIndex: -1,
    layerType: layerTypeIds.META,
    layerGroupId: layerGroupIds.CLIP_ROOT,
    styleLayer: false,
    clipLayer: false,
    layerDataLoader: () => JSON.parse('{"type":"Sphere"}'),
  },
  {
    id: layerIds.BACKGROUND,
    pathId: layerIds.BACKGROUND.toLowerCase(),
    label: `data_model.layer.${layerIds.BACKGROUND}`,
    layerIndex: 101,
    layerType: layerTypeIds.BASIC,
    layerGroupId: layerGroupIds.PRE_CLIP,
    styleLayer: true,
    clipLayer: false,
    layerDataLoader: () => JSON.parse('null'),
  },
  {
    id: layerIds.SPHERE,
    pathId: layerIds.SPHERE.toLowerCase(),
    label: `data_model.layer.${layerIds.SPHERE}`,
    layerIndex: 301,
    layerType: layerTypeIds.BASIC,
    layerGroupId: layerGroupIds.POST_CLIP,
    styleLayer: true,
    clipLayer: false,
    layerDataLoader: () => JSON.parse('{"type":"Sphere"}'),
  },
  {
    id: layerIds.SPHERE_BACKGROUND,
    pathId: layerIds.SPHERE_BACKGROUND.toLowerCase(),
    label: `data_model.layer.${layerIds.SPHERE}`,
    layerIndex: 201,
    layerType: layerTypeIds.META,
    layerGroupId: layerGroupIds.CLIP_ROOT,
    styleLayer: true,
    clipLayer: false,
    layerDataLoader: () => JSON.parse('{"type":"Sphere"}'),
  },
  {
    id: layerIds.OCEAN,
    pathId: layerIds.OCEAN.toLowerCase(),
    label: `data_model.layer.${layerIds.OCEAN}`,
    layerIndex: 202,
    layerType: layerTypeIds.PHYSICAL,
    layerGroupId: layerGroupIds.CLIP_ROOT,
    styleLayer: true,
    clipLayer: true,
    layerDataLoader: async () => await import('@/assets/mapdata/ne_110m_ocean.geojson'),
  },
  {
    id: layerIds.LINES,
    pathId: layerIds.LINES.toLowerCase(),
    label: `data_model.layer.${layerIds.LINES}`,
    layerIndex: 203,
    layerType: layerTypeIds.CARTOGRAPHIC,
    layerGroupId: layerGroupIds.CLIP_ROOT,
    styleLayer: true,
    clipLayer: true,
    layerDataLoader: async () => await import('@/assets/mapdata/ne_110m_geographic_lines.geojson'),
  },
  {
    id: layerIds.GRATICULES,
    pathId: layerIds.GRATICULES.toLowerCase(),
    label: `data_model.layer.${layerIds.GRATICULES}`,
    layerIndex: 204,
    layerType: layerTypeIds.CARTOGRAPHIC,
    layerGroupId: layerGroupIds.CLIP_ROOT,
    styleLayer: true,
    clipLayer: true,
    layerDataLoader: async () => await import('@/assets/mapdata/ne_110m_graticules_20.geojson'),
  },
  {
    id: layerIds.LAND,
    pathId: layerIds.LAND.toLowerCase(),
    label: `data_model.layer.${layerIds.LAND}`,
    layerIndex: 205,
    layerType: layerTypeIds.PHYSICAL,
    layerGroupId: layerGroupIds.CLIP_ROOT,
    styleLayer: true,
    clipLayer: true,
    layerDataLoader: async () => await import('@/assets/mapdata/ne_110m_land.geojson'),
  },
  {
    id: layerIds.COUNTRIES,
    pathId: layerIds.COUNTRIES.toLowerCase(),
    label: `data_model.layer.${layerIds.COUNTRIES}`,
    layerIndex: 206,
    layerType: layerTypeIds.CULTURAL,
    layerGroupId: layerGroupIds.CLIP_ROOT,
    styleLayer: true,
    clipLayer: true,
    layerDataLoader: async () => await import('@/assets/mapdata/ne_110m_admin_0_countries.geojson'),
  },
  {
    id: layerIds.COASTLINE,
    pathId: layerIds.COASTLINE.toLowerCase(),
    label: `data_model.layer.${layerIds.COASTLINE}`,
    layerIndex: 207,
    layerType: layerTypeIds.PHYSICAL,
    layerGroupId: layerGroupIds.CLIP_ROOT,
    styleLayer: true,
    clipLayer: true,
    layerDataLoader: async () => await import('@/assets/mapdata/ne_110m_coastline.geojson'),
  },
  {
    id: layerIds.LAKES,
    pathId: layerIds.LAKES.toLowerCase(),
    label: `data_model.layer.${layerIds.LAKES}`,
    layerIndex: 208,
    layerType: layerTypeIds.PHYSICAL,
    layerGroupId: layerGroupIds.CLIP_ROOT,
    styleLayer: true,
    clipLayer: true,
    layerDataLoader: async () => await import('@/assets/mapdata/ne_110m_lakes.geojson'),
  },
  {
    id: layerIds.RIVERS,
    pathId: layerIds.RIVERS.toLowerCase(),
    label: `data_model.layer.${layerIds.RIVERS}`,
    layerIndex: 209,
    layerType: layerTypeIds.PHYSICAL,
    layerGroupId: layerGroupIds.CLIP_ROOT,
    styleLayer: true,
    clipLayer: true,
    layerDataLoader: async () =>
      await import('@/assets/mapdata/ne_110m_rivers_lake_centerlines.geojson'),
  },
  {
    id: layerIds.TISSOT_INDICATRIX,
    pathId: layerIds.TISSOT_INDICATRIX.toLowerCase(),
    label: `data_model.layer.${layerIds.TISSOT_INDICATRIX}`,
    layerIndex: 210,
    layerType: layerTypeIds.CARTOGRAPHIC,
    layerGroupId: layerGroupIds.CLIP_ROOT,
    styleLayer: true,
    clipLayer: true,
    layerDataLoader: () => tissot,
  },
  {
    id: layerIds.PERSONA_AMUNDSEN_JOURNEY,
    pathId: layerIds.PERSONA_AMUNDSEN_JOURNEY.toLowerCase(),
    label: '',
    layerIndex: 211,
    layerType: layerTypeIds.PERSONA_JOURNEY,
    layerGroupId: layerGroupIds.CLIP_ROOT,
    styleLayer: true,
    clipLayer: true,
    layerDataLoader: async () => await import('@/assets/mapdata/persona_amundsen_journey.geojson'),
  },
  {
    id: layerIds.PERSONA_JACK_JOURNEY,
    pathId: layerIds.PERSONA_JACK_JOURNEY.toLowerCase(),
    label: '',
    layerIndex: 211,
    layerType: layerTypeIds.PERSONA_JOURNEY,
    layerGroupId: layerGroupIds.CLIP_ROOT,
    styleLayer: true,
    clipLayer: true,
    layerDataLoader: async () => await import('@/assets/mapdata/persona_jack_journey.geojson'),
  },
  {
    id: layerIds.PERSONA_MERIAN_JOURNEY,
    pathId: layerIds.PERSONA_MERIAN_JOURNEY.toLowerCase(),
    label: '',
    layerIndex: 211,
    layerType: layerTypeIds.PERSONA_JOURNEY,
    layerGroupId: layerGroupIds.CLIP_ROOT,
    styleLayer: true,
    clipLayer: true,
    layerDataLoader: async () => await import('@/assets/mapdata/persona_merian_journey.geojson'),
  },
  {
    id: layerIds.PERSONA_AMUNDSEN_STATIONS,
    pathId: layerIds.PERSONA_AMUNDSEN_STATIONS.toLowerCase(),
    label: '',
    layerIndex: 212,
    layerType: layerTypeIds.PERSONA_STATIONS,
    layerGroupId: layerGroupIds.CLIP_ROOT,
    styleLayer: false,
    clipLayer: false,
    layerDataLoader: async () => await import('@/assets/mapdata/persona_amundsen_stations.geojson'),
  },
  {
    id: layerIds.PERSONA_JACK_STATIONS,
    pathId: layerIds.PERSONA_JACK_STATIONS.toLowerCase(),
    label: '',
    layerIndex: 212,
    layerType: layerTypeIds.PERSONA_STATIONS,
    layerGroupId: layerGroupIds.CLIP_ROOT,
    styleLayer: false,
    clipLayer: false,
    layerDataLoader: async () => await import('@/assets/mapdata/persona_jack_stations.geojson'),
  },
  {
    id: layerIds.PERSONA_MERIAN_STATIONS,
    pathId: layerIds.PERSONA_MERIAN_STATIONS.toLowerCase(),
    label: '',
    layerIndex: 212,
    layerType: layerTypeIds.PERSONA_STATIONS,
    layerGroupId: layerGroupIds.CLIP_ROOT,
    styleLayer: false,
    clipLayer: false,
    layerDataLoader: async () => await import('@/assets/mapdata/persona_merian_stations.geojson'),
  },
]
