import { wizardStepIds } from '@/assets/js/model/route'
import { personaIds } from '@/assets/js/model/persona'

export const interstitialContentIds = {
  PERSONA_WIZARD_MERIAN_0: 'PERSONA_WIZARD_MERIAN_0',
  PERSONA_WIZARD_MERIAN_1: 'PERSONA_WIZARD_MERIAN_1',
  PERSONA_WIZARD_MERIAN_2: 'PERSONA_WIZARD_MERIAN_2',
  PERSONA_WIZARD_MERIAN_3: 'PERSONA_WIZARD_MERIAN_3',
  PERSONA_WIZARD_MERIAN_4: 'PERSONA_WIZARD_MERIAN_4',
  PERSONA_WIZARD_MERIAN_5: 'PERSONA_WIZARD_MERIAN_5',
  PERSONA_WIZARD_MERIAN_6: 'PERSONA_WIZARD_MERIAN_6',
}

export const interstitialContentList = [
  {
    id: interstitialContentIds.PERSONA_WIZARD_MERIAN_0,
    text: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_MERIAN_0}.text`,
    forwardLabel: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_MERIAN_0}.forwardLabel`,
    hasForwardBtn: false,
    image: '/images/wizard_interstitial_images/merian/00_eingangsbild_merian_641_757@3x.png',
    wizardStepId: null,
    wizardStepIdx: null,
    personaId: personaIds.MERIAN,
  },
  {
    id: interstitialContentIds.PERSONA_WIZARD_MERIAN_1,
    text: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_MERIAN_1}.text`,
    forwardLabel: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_MERIAN_1}.forwardLabel`,
    image: '/images/wizard_interstitial_images/merian/01_merian_karte_446_500@3x.png',
    wizardStepId: wizardStepIds.PERSONA_WIZARD_MERIAN_STEP_1,
    wizardStepIdx: 0,
    personaId: personaIds.MERIAN,
  },
  {
    id: interstitialContentIds.PERSONA_WIZARD_MERIAN_2,
    text: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_MERIAN_2}.text`,
    forwardLabel: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_MERIAN_2}.forwardLabel`,
    image: '/images/wizard_interstitial_images/merian/02_merian_buch_446_500@3x.png',
    wizardStepId: wizardStepIds.PERSONA_WIZARD_MERIAN_STEP_2,
    wizardStepIdx: 1,
    personaId: personaIds.MERIAN,
  },
  {
    id: interstitialContentIds.PERSONA_WIZARD_MERIAN_3,
    text: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_MERIAN_3}.text`,
    forwardLabel: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_MERIAN_3}.forwardLabel`,
    image: '/images/wizard_interstitial_images/merian/03_merian_ananas_446_500_copy@3x.png',
    wizardStepId: wizardStepIds.PERSONA_WIZARD_MERIAN_STEP_3,
    wizardStepIdx: 2,
    personaId: personaIds.MERIAN,
  },
  {
    id: interstitialContentIds.PERSONA_WIZARD_MERIAN_4,
    text: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_MERIAN_4}.text`,
    forwardLabel: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_MERIAN_4}.forwardLabel`,
    image: '/images/wizard_interstitial_images/merian/04_merian_doppelglaeser_446_500@3x.png',
    wizardStepId: wizardStepIds.PERSONA_WIZARD_MERIAN_STEP_4,
    wizardStepIdx: 3,
    personaId: personaIds.MERIAN,
  },
  {
    id: interstitialContentIds.PERSONA_WIZARD_MERIAN_5,
    text: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_MERIAN_5}.text`,
    forwardLabel: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_MERIAN_5}.forwardLabel`,
    image: '/images/wizard_interstitial_images/merian/05_merian_glas_446_500@3x.png',
    wizardStepId: wizardStepIds.PERSONA_WIZARD_MERIAN_STEP_5,
    wizardStepIdx: 4,
    personaId: personaIds.MERIAN,
  },
  {
    id: interstitialContentIds.PERSONA_WIZARD_MERIAN_6,
    text: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_MERIAN_6}.text`,
    forwardLabel: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_MERIAN_6}.forwardLabel`,
    image: '/images/wizard_interstitial_images/merian/06_merian_karte_446_500@3x.png',
    wizardStepId: wizardStepIds.PERSONA_WIZARD_MERIAN_STEP_6,
    wizardStepIdx: 5,
    personaId: personaIds.MERIAN,
  },
]
