<template>
  <div :class="rootClasses" class="ui-tooltip">
    <div class="ui-tooltip__transformer">
      <div class="ui-tooltip__inner">
        <slot name="default" />
      </div>
    </div>
  </div>
</template>

<script>
  import bemMixin from '@/assets/js/mixin/bem'

  export default {
    mixins: [bemMixin('ui-tooltip')],
    props: {
      overlayX: {
        type: String,
        required: true,
      },
      overlayY: {
        type: String,
        required: true,
      },
      originX: {
        type: String,
        required: true,
      },
      originY: {
        type: String,
        required: true,
      },
    },
    computed: {
      rootClasses() {
        let position = 'unknown'
        let alignment = 'unknown'

        const alignmentX = `${this.originX}-${this.overlayX}`
        const alignmentY = `${this.originY}-${this.overlayY}`

        if (alignmentX === 'end-start') {
          // tooltip is right of origin (--> left arrow)
          position = 'left'
          alignment = `y-${this.overlayY}`
        } else if (alignmentX === 'start-end') {
          // tooltip is left of origin (--> right arrow)
          position = 'right'
          alignment = `y-${this.overlayY}`
        } else if (alignmentY === 'start-end') {
          // tooltip is above (--> bottom tooltip)
          position = 'bottom'
          alignment = `x-${this.overlayX}`
        } else if (alignmentY === 'end-start') {
          // tooltip is below (--> top tooltip)
          position = 'top'
          alignment = `x-${this.overlayX}`
        } else {
          // not supported
        }

        return [
          //
          this.bemAdd(`arrow-pos-${position}`),
          this.bemAdd(`arrow-align-${alignment}`),
        ]
      },
    },
  }
</script>

<style lang="scss" src="./_ui-tooltip.scss" />
