import { SET_MENU, SET_META } from './mutation-types'
import * as actions from './actions'
import * as getters from './getters'

const mutations = {
  [SET_MENU](state, { isOpen }) {
    state.isOpen = isOpen
  },
  [SET_META](state, { meta }) {
    state.meta = meta
  },
}

const state = () => ({
  isOpen: false,
  meta: {},
})

export default {
  namespaced: true,
  mutations,
  actions,
  getters,
  state,
}
