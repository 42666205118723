import { layerIds } from '@/assets/js/model/layer'
import { styleAttributeIds } from '@/assets/js/model/style-attributes'
import { mapThemeTypeIds } from '@/assets/js/model/map-theme-type'

export const mapThemeIds = {
  COLORFUL_1: 'COLORFUL_1',
  COLORFUL_2: 'COLORFUL_2',
  COLORFUL_3: 'COLORFUL_3',
  COLORFUL_4: 'COLORFUL_4',
  COLORFUL_5: 'COLORFUL_5',
  COLORFUL_6: 'COLORFUL_6',
}

export const mapThemeList = [
  {
    id: mapThemeIds.COLORFUL_1,
    mapThemeType: mapThemeTypeIds.COLORFUL,
    label: `data_model.map_theme.${mapThemeIds.COLORFUL_1}`,
    layerStyleList: [
      {
        layerIds: [layerIds.BACKGROUND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#382f32',
        },
      },
      {
        layerIds: [layerIds.SPHERE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#ed1164',
          [styleAttributeIds.LINE_WIDTH]: 4,
        },
      },
      {
        layerIds: [layerIds.LINES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#ed1164',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.GRATICULES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#602d91',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
          [styleAttributeIds.LINE_DASH]: [2, 2],
        },
      },
      {
        layerIds: [layerIds.LAND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#b9f300',
        },
      },
      {
        layerIds: [layerIds.OCEAN],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#00aeef',
        },
      },
      {
        layerIds: [layerIds.COASTLINE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#ed1164',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
        },
      },
      {
        layerIds: [layerIds.LAKES],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#00aeef',
          [styleAttributeIds.LINE_COLOR]: '#00aeef',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.RIVERS],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#00aeef',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.COUNTRIES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#602d91',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.TISSOT_INDICATRIX],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#000000cc',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
          [styleAttributeIds.FILL_COLOR]: '#0000004d',
        },
      },
    ],
  },
  {
    id: mapThemeIds.COLORFUL_2,
    mapThemeType: mapThemeTypeIds.COLORFUL,
    label: `data_model.map_theme.${mapThemeIds.COLORFUL_2}`,
    layerStyleList: [
      {
        layerIds: [layerIds.BACKGROUND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#382f32',
        },
      },
      {
        layerIds: [layerIds.SPHERE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#00aeef',
          [styleAttributeIds.LINE_WIDTH]: 4,
        },
      },
      {
        layerIds: [layerIds.LINES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#00aeef',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.GRATICULES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#602d91',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
          [styleAttributeIds.LINE_DASH]: [2, 2],
        },
      },
      {
        layerIds: [layerIds.LAND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#b9f300',
        },
      },
      {
        layerIds: [layerIds.OCEAN],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#ed1164',
        },
      },
      {
        layerIds: [layerIds.COASTLINE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#602d91',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
        },
      },
      {
        layerIds: [layerIds.LAKES],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#ed1164',
          [styleAttributeIds.LINE_COLOR]: '#ed1164',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.RIVERS],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#ed1164',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.COUNTRIES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#602d91',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.TISSOT_INDICATRIX],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#000000cc',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
          [styleAttributeIds.FILL_COLOR]: '#0000004d',
        },
      },
    ],
  },
  {
    id: mapThemeIds.COLORFUL_3,
    mapThemeType: mapThemeTypeIds.COLORFUL,
    label: `data_model.map_theme.${mapThemeIds.COLORFUL_3}`,
    layerStyleList: [
      {
        layerIds: [layerIds.BACKGROUND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#382f32',
        },
      },
      {
        layerIds: [layerIds.SPHERE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#00aeef',
          [styleAttributeIds.LINE_WIDTH]: 4,
        },
      },
      {
        layerIds: [layerIds.LINES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#00aeef',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.GRATICULES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#00aeef',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
          [styleAttributeIds.LINE_DASH]: [2, 2],
        },
      },
      {
        layerIds: [layerIds.LAND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#602d91',
        },
      },
      {
        layerIds: [layerIds.OCEAN],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#b9f300',
        },
      },
      {
        layerIds: [layerIds.COASTLINE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#ed1164',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
        },
      },
      {
        layerIds: [layerIds.LAKES],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#b9f300',
          [styleAttributeIds.LINE_COLOR]: '#b9f300',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.RIVERS],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#b9f300',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.COUNTRIES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#b9f300',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.TISSOT_INDICATRIX],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#000000cc',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
          [styleAttributeIds.FILL_COLOR]: '#0000004d',
        },
      },
    ],
  },
  {
    id: mapThemeIds.COLORFUL_4,
    mapThemeType: mapThemeTypeIds.COLORFUL,
    label: `data_model.map_theme.${mapThemeIds.COLORFUL_4}`,
    layerStyleList: [
      {
        layerIds: [layerIds.BACKGROUND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#382f32',
        },
      },
      {
        layerIds: [layerIds.SPHERE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#b9f300',
          [styleAttributeIds.LINE_WIDTH]: 4,
        },
      },
      {
        layerIds: [layerIds.LINES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#ed1164',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.GRATICULES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#00aeef',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
          [styleAttributeIds.LINE_DASH]: [2, 2],
        },
      },
      {
        layerIds: [layerIds.LAND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#00aeef',
        },
      },
      {
        layerIds: [layerIds.OCEAN],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#602d91',
        },
      },
      {
        layerIds: [layerIds.COASTLINE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#b9f300',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
        },
      },
      {
        layerIds: [layerIds.LAKES],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#602d91',
          [styleAttributeIds.LINE_COLOR]: '#602d91',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.RIVERS],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#602d91',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.COUNTRIES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#b9f300',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.TISSOT_INDICATRIX],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#000000cc',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
          [styleAttributeIds.FILL_COLOR]: '#0000004d',
        },
      },
    ],
  },
  {
    id: mapThemeIds.COLORFUL_5,
    mapThemeType: mapThemeTypeIds.COLORFUL,
    label: `data_model.map_theme.${mapThemeIds.COLORFUL_5}`,
    layerStyleList: [
      {
        layerIds: [layerIds.BACKGROUND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#382f32',
        },
      },
      {
        layerIds: [layerIds.SPHERE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#b9f300',
          [styleAttributeIds.LINE_WIDTH]: 4,
        },
      },
      {
        layerIds: [layerIds.LINES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#ed1164',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.GRATICULES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#602d91',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
          [styleAttributeIds.LINE_DASH]: [2, 2],
        },
      },
      {
        layerIds: [layerIds.LAND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#ed1164',
        },
      },
      {
        layerIds: [layerIds.OCEAN],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#00aeef',
        },
      },
      {
        layerIds: [layerIds.COASTLINE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#b9f300',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
        },
      },
      {
        layerIds: [layerIds.LAKES],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#00aeef',
          [styleAttributeIds.LINE_COLOR]: '#00aeef',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.RIVERS],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#00aeef',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.COUNTRIES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#b9f300',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.TISSOT_INDICATRIX],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#000000cc',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
          [styleAttributeIds.FILL_COLOR]: '#0000004d',
        },
      },
    ],
  },
  {
    id: mapThemeIds.COLORFUL_6,
    mapThemeType: mapThemeTypeIds.COLORFUL,
    label: `data_model.map_theme.${mapThemeIds.COLORFUL_6}`,
    layerStyleList: [
      {
        layerIds: [layerIds.BACKGROUND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#382f32',
        },
      },
      {
        layerIds: [layerIds.SPHERE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#602d91',
          [styleAttributeIds.LINE_WIDTH]: 4,
        },
      },
      {
        layerIds: [layerIds.LINES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#00aeef',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.GRATICULES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#602d91',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
          [styleAttributeIds.LINE_DASH]: [2, 2],
        },
      },
      {
        layerIds: [layerIds.LAND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#ed1164',
        },
      },
      {
        layerIds: [layerIds.OCEAN],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#b9f300',
        },
      },
      {
        layerIds: [layerIds.COASTLINE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#602d91',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
        },
      },
      {
        layerIds: [layerIds.LAKES],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#b9f300',
          [styleAttributeIds.LINE_COLOR]: '#b9f300',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.RIVERS],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#b9f300',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.COUNTRIES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#b9f300',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.TISSOT_INDICATRIX],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#000000cc',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
          [styleAttributeIds.FILL_COLOR]: '#0000004d',
        },
      },
    ],
  },
]
