<template>
  <button :class="rootClasses" class="navigation-btn btn btn--bubble" v-on="$listeners">
    <span class="navigation-btn__root">
      <span v-for="n in 3" :key="n" class="navigation-btn__bar" />
    </span>
  </button>
</template>

<script>
  import { mapState } from 'vuex'
  import bemMixin from '@/assets/js/mixin/bem'
  import { pageIds } from '@/assets/js/model/page'

  const { PERSONA_WIZARD, PERSONA_WIZARD_INTRO } = pageIds

  export default {
    mixins: [bemMixin('navigation-btn')],
    props: {
      isActive: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapState('navigation', ['isOpen', 'meta']),
      rootClasses() {
        const isPersonaWizardIntro = this.meta.pageId === PERSONA_WIZARD_INTRO
        const isPersonaWizard = this.meta.pageId === PERSONA_WIZARD

        return [
          this.bemAdd(this.isActive ? 'is-active' : ''),
          this.bemAdd(isPersonaWizardIntro ? 'is-persona-wizard-intro' : ''),
          this.bemAdd(isPersonaWizard ? 'is-persona-wizard' : ''),
          this.bemAdd(this.isOpen ? 'has-open-menu' : 'has-closed-menu'),
        ]
      },
    },
  }
</script>

<style lang="scss" src="./_navigation-btn.scss" />
