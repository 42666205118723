<template>
  <div class="footer-navigation grid grid--primary">
    <div class="footer-navigation__aside footer-navigation__aside--start">
      <n-link class="u-reset footer-navigation__home-link" :to="localePath({ name: 'index' })">
        <responsive-img
          class="footer-navigation__home-icon"
          src="/images/menu_icons/home_small@3x.png"
          :width="32"
          alt=""
        />
        <span class="footer-navigation__home-text">
          {{ $t('footer_navigation.home_link') }}
        </span>
      </n-link>
    </div>
    <div class="footer-navigation__center">
      <social-media-navigation />
    </div>
    <div class="footer-navigation__aside footer-navigation__aside--end">
      <span class="footer-navigation__geo-coordinates-text">
        {{ $t('footer_navigation.geo_coordinates') }}
      </span>
    </div>
  </div>
</template>

<script>
  import SocialMediaNavigation from '@/components/social-media-navigation/social-media-navigation'
  import ResponsiveImg from '~/components/responsive-img/responsive-img'

  export default {
    components: { ResponsiveImg, SocialMediaNavigation },
  }
</script>

<style lang="scss" src="./_footer-navigation.scss" />
