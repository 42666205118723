import { pageThemeIds } from '@/assets/js/model/page-theme'

export const personaIds = {
  CUSTOM: 'CUSTOM',
  MERIAN: 'MERIAN',
  JACK: 'JACK',
  AMUNDSEN: 'AMUNDSEN',
}

export const personaList = [
  {
    id: personaIds.CUSTOM,
    icon: '/images/persona_navigation/00_nav_juli_500_500@3x.png',
    pageThemeId: pageThemeIds.CUSTOM,
    pageTheme: 'persona-custom',
    param: null,
    name: 'wizard_selector.custom_button_label',
    image: null,
    isPersona: false,
  },
  {
    id: personaIds.AMUNDSEN,
    icon: '/images/persona_navigation/00_nav_image_amundsen_seil_500_500@3x.png',
    pageThemeId: pageThemeIds.AMUNDSEN,
    pageTheme: 'persona-amundsen',
    theme: 'custom',
    param: 'amundsen',
    name: `data_model.persona.${personaIds.AMUNDSEN}.name`,
    image: {
      src: '/images/intro/02_Intro_Bilder_Amundsen_Desktop_764_600.png',
      width: 500,
    },
    isPersona: true,
  },
  {
    id: personaIds.JACK,
    icon: '/images/persona_navigation/00_nav_image_jack_steuerrad_i_500_500@3x.png',
    pageThemeId: pageThemeIds.JACK,
    pageTheme: 'persona-jack',
    param: 'jack',
    name: `data_model.persona.${personaIds.JACK}.name`,
    image: {
      src: '/images/intro/02_Intro_Bilder_Carsten_Desktop_764_600.png',
      width: 500,
    },
    isPersona: true,
  },
  {
    id: personaIds.MERIAN,
    icon: '/images/persona_navigation/00_nav_merian_glasglocke_500_500@3x.png',
    pageThemeId: pageThemeIds.MERIAN,
    pageTheme: 'persona-merian',
    param: 'merian',
    name: `data_model.persona.${personaIds.MERIAN}.name`,
    image: {
      src: '/images/intro/02_Intro_Bilder_Merian_Desktop_764_600.png',
      width: 500,
    },
    isPersona: true,
  },
]
