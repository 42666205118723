import { exportFormatIds, exportSizeIds } from '../js/model/export'
import { mapThemeIds } from '../js/model/map-theme'
import { layerIds, layerTypeIds } from '../js/model/layer'
import { mainMenuIds } from '../js/model/main-menu'
import { projectionIds, projectionShapeIds, projectionTypeIds } from '../js/model/projection'
import { socialMediaGroupIds } from '../js/model/social-media'
import { interstitialContentIds } from '../js/model/interstitial-content'
import { personaIds } from '@/assets/js/model/persona'
import { mapThemeTypeIds } from '~/assets/js/model/map-theme-type'

export default {
  meta: {
    suffix: 'Worldmapgenerator',
    default: {
      title: 'Worldmapgenerator',
      description: 'Generate world maps, challenge viewpoints and export your vector data!',
    },
    home: {
      title: 'Home',
    },
    wizard: {
      title: 'Your Viewpoint',
    },
    choose_viewpoint: {
      title: 'Choose a Viewpoint',
    },
    choose_persona: {
      title: 'Choose a Persona',
    },
    persona: {
      title: 'Persona Viewpoint',
    },
  },
  page_about: {
    title: 'About',
  },
  page_contact: {
    title: 'Contact',
  },
  page_licence: {
    title: 'Licence',
  },
  page_intro: {
    title: 'Worldmap&shy;generator',
    sub_title: 'Generate your own vector world map',
    coming_soon_title: 'Release: 26.02.21',
    coming_soon_sub_title: 'Generate, challenge and export your world map',
    button_label: `Continue`,
  },
  page_persona: {
    title: 'Choose a character',
  },
  page_shop: {
    title: 'Shop',
  },
  page_team: {
    title: 'Team',
  },
  page_theory: {
    title: 'Theory',
  },
  page_viewpoint: {
    title: 'Choose a viewpoint',
  },
  page_wizard: {
    title: 'Your viewpoint',
  },
  cookie_banner: {
    text: `This site uses cookies to improve the results of our bakery. With your acceptance we can add more honey, sugar and flour to improve the website.`,
    accept_label: 'Accept',
  },
  wizard_selector: {
    title: 'Choose a viewpoint',
    custom_button_label: 'Your viewpoint',
    persona_button_label: `Persona's viewpoint`,
    custom_button_description: 'Generate your own vector world map.',
    persona_button_description: 'Discover the world through a story of a persona.',
  },
  persona_selector: {
    [personaIds.AMUNDSEN]: {
      description: `I'll show you how the world looks like from the southern hemisphere.`,
    },
    [personaIds.MERIAN]: {
      description: `I'll show you the beautiful world of science, butterflys ans surinam.`,
    },
    [personaIds.JACK]: {
      description: `I'll show you the last discovered continent.`,
    },
  },
  persona_wizard: {
    back_btn: 'Back to the intro',
  },
  wizard_step: {
    centering: {
      title: 'Centering',
      move_center_title: 'Move center',
      shift_center_title: 'Shift center',
      set_center_title: 'Set center',
      set_center_input_placeholder: 'Enter a location',
      set_center_no_results: 'No results!',
      current_center_title: 'Current center',
      current_center_request_center: 'Get current center',
      current_center_no_permission: 'Please grant browser permissions to set the current center!',
    },
    projection: {
      title: 'Projection',
      type_select_title: 'Select type of projection',
      shape_select_title: 'Select shape of projection',
      projection_list_title: 'Select projection',
      projection_select_title: 'Choose projection from list',
      source_label: `Source: ESRI`,
      source_link: `https://desktop.arcgis.com/en/arcmap/latest/map/projections/list-of-supported-map-projections.htm`,
    },
    geo_layers: {
      title: 'Geo Layers',
    },
    zoom: {
      title: 'Zoom',
      adjust_zoom_title: 'Adjust zoom',
      reset_zoom_title: 'Reset zoom',
      reset_zoom_btn_label: 'Reset zoom level',
    },
    theme: {
      title: 'Design',
    },
    export: {
      title: 'Export',
      format_select_title: 'Export in your file format',
      size_select_title: 'Sizes',
      download_title: 'Download your world map',
      share_title: 'Share your world map',
      width_input_label: 'Width',
      height_input_label: 'Height',
      export_button_label: 'download',
      email_button_label: 'Email',
      facebook_button_label: 'Facebook',
      twitter_button_label: 'Twitter',
      email_subject: `Check out my generated world map on www.worldmapgenerator.com!`,
      email_body: `
Hey,
%0D%0A
%0D%0AI've generated my custom world map! See it here:
%0D%0A
%0D%0A{url}
%0D%0A
%0D%0AOr generate your own world map:
%0D%0A{baseurl}
%0D%0A
%0D%0ARegards, a map lover ...`,
      facebook_quote: `
Check out my world map and generate your own!
`,
      twitter_text: `
Check out my world map and generate your own!
`,
    },
    end: {
      title: 'Thank you!',
      go_back: 'Go back to...',
      custom_wizard_link_label: 'Generate your world map',
      persona_wizard_link_label: 'Choose a persona',
    },
  },
  main_menu: {
    title: 'Menu',
  },
  footer_navigation: {
    home_link: 'Worldmapgenerator',
    geo_coordinates: '47° 22′ 18″ N , 8° 32′ 32″ O, 2020',
  },
  donation_content: {
    title: 'Donation',
    sub_title: 'Merci, grazie and thank you for using the worldmapgenerator!',
    text: `By creating maps we try to give you a good mental world trip on maps. The download of the world maps is free of charge, but it still costs money and it’s a huge effort to write, support, and distribute it. If you enjoy using it, please consider a donation to the author of this software. It will help to keep the project alive!<br><br>Donate on <a href="https://opencollective.com/worldmapgenerator/contribute/travel-around-the-world-reise-um-die-welt-23589/checkout" target="_blank" rel="noopener noreferrer">OpenCollective</a>`,
    end_link_label: 'Later',
    donate_link_label: 'Donate',
  },
  data_model: {
    export_format: {
      [exportFormatIds.JPG]: 'jpg',
      [exportFormatIds.PNG]: 'png',
      [exportFormatIds.SVG]: 'svg',
    },
    export_size: {
      [exportSizeIds.TINY]: 'tiny',
      [exportSizeIds.SMALL]: 'small',
      [exportSizeIds.MEDIUM]: 'medium',
      [exportSizeIds.LARGE]: 'large',
    },
    interstitial_content: {
      [interstitialContentIds.CUSTOM_WIZARD_INTRODUCTION]: {
        text: `
Hi you, welcome! What does your world look like? Where's the center of your world? In what colours does your world appear? How do you imagine your world?
<br>
<b>Let's go generate a world map from your perspective. I'll guide you through each step.</b>`,
        forwardLabel: '',
      },
      [interstitialContentIds.CUSTOM_WIZARD_CENTERING]: {
        text: `
The hegemonic center of the world and thus the geographical centering of world maps has changed throughout history. From which perspective do you wish to contemplate your world? Which location is the center of your map?
<br>
<b>Spin the globe, shift the map, move the parameters, or insert a location to choose your world map's center.</b>
        `,
        forwardLabel: 'Centering, perspective, shift, rotate or move the globe',
      },
      [interstitialContentIds.CUSTOM_WIZARD_PROJECTION]: {
        text: `
To transform the surface of the globe into a world map, a projection is needed. Mostly, you can identify the projection by its graticules. Do you know, that the projection is responsible for the kind of distortions of the landmass? … or for the size and shape of it?
<br>
<b>Mercator-, Robinson-, or Butterfly projection, choose the one you like!</b>
        `,
        forwardLabel: 'Wagner-, Winkel-, Waterman projection, choose the one that suits you',
      },
      [interstitialContentIds.CUSTOM_WIZARD_GEOLAYERS]: {
        text: `
Our world maps are composed of different geographical features. Did Have you ever explored the different Geolayers on a map? Lakes, Countries and, Oceans.
<br>
<b>Display the Geolayers you need for your world map.</b>
        `,
        forwardLabel: 'Coastline, Graticules and Countries, choose the ones you like',
      },
      [interstitialContentIds.CUSTOM_WIZARD_THEME]: {
        text: `
In most maps, lakes, rivers, and oceans are colored in blue tones, land masses brownish and countries in pastel tones. How is your map designed? Is your world map colored in black and white tones? ... or even in an unconventional colour scheme?
<br>
<b>Break rules, challenge conventions. Design your map as you wish.</b>
        `,
        forwardLabel:
          'Purple oceans and colorful countries, deep black lakes and bright borderlines',
      },
      [interstitialContentIds.CUSTOM_WIZARD_ZOOM]: {
        text: `
Make your world smaller by zooming in on a specific point in on the worldmap. Regional maps or world maps, this depends on the zoom level.  Do you wish to depict the whole world or just a particular region?
<br>
<b>Change the zoom level and observe how it affects the distortion.</b>
        `,
        forwardLabel: '1:2.500.000 or 1:100.000, what scaling do you wish for your map?',
      },
      [interstitialContentIds.CUSTOM_WIZARD_EXPORT]: {
        text: `
Wow, you're done, you created your own personal world map. You can export your map as a .svg or .png and edit it in any desired program. Did you know that the individual geofeatures are organized on different layers?
<br>
<b>Let's go, pack your world map in a suitcase and travel around the world.</b>
        `,
        forwardLabel: 'Where do you go? I wish you a good journey.',
      },
      [interstitialContentIds.PERSONA_WIZARD_AMUNDSEN_0]: {
        text: `Perfect, you have chosen Roald Amundsen. He was a polar explorer, born 1872 in Norway, and the first man to put a foot on the South Pole. His initial plans had focused on the Arctic and the conquest of the North Pole. But he was too late, others were faster and therefore he hurried to the south pole.`,
        forwardLabel: '',
      },
      [interstitialContentIds.PERSONA_WIZARD_JACK_0]: {
        text: `Perfect, you have chosen Jack the Sailor, born 1753 in Britain. He sailed his whole life around the world. The highlight of his career was becoming a member of Captain Cook's Crew. He discovered New Zealand as well as many other places in the Pacific Ocean.`,
        forwardLabel: '',
      },
      [interstitialContentIds.PERSONA_WIZARD_MERIAN_0]: {
        text: `Perfect, you have chosen Sibylla Merian, born 1647 in Germany. She was a German naturalist, engraver, scientist, and artist. She was born in Germany, lived in the Netherlands, and finally travelled to Surinam to expand her research on butterflies and the tropical world.`,
        forwardLabel: '',
      },
    },
    layer: {
      [layerIds.BACKGROUND]: 'Background',
      [layerIds.SPHERE]: 'Sphere',
      [layerIds.LINES]: 'Lines',
      [layerIds.GRATICULES]: 'Graticules',
      [layerIds.TISSOT_INDICATRIX]: 'Tissot Indicatrix',
      [layerIds.LAND]: 'Land',
      [layerIds.OCEAN]: 'Ocean',
      [layerIds.COUNTRIES]: 'Countries',
      [layerIds.COASTLINE]: 'Coastline',
      [layerIds.LAKES]: 'Lakes',
      [layerIds.RIVERS]: 'Rivers',
    },
    layer_type: {
      [layerTypeIds.BASIC]: 'Basic',
      [layerTypeIds.CARTOGRAPHIC]: 'Cartographic',
      [layerTypeIds.PHYSICAL]: 'Physical',
      [layerTypeIds.CULTURAL]: 'Cultural',
    },
    map_theme: {
      [mapThemeIds.DEFAULT]: 'Default',
      [mapThemeIds.NEON_OUTLINE_BLUE]: 'Neon Outline Blue',
      [mapThemeIds.NEON_OUTLINE_MAGENTA]: 'Neon Outline Magenta',
      [mapThemeIds.NEON_OUTLINE_YELLOW]: 'Neon Outline Yellow',
      [mapThemeIds.NEON_PLAIN_GREEN]: 'Neon Plain Green',
      [mapThemeIds.NEON_PLAIN_MAGENTA]: 'Neon Plain Magenta',
      [mapThemeIds.NEON_PLAIN_YELLOW]: 'Neon Plain Yellow',
      [mapThemeIds.BLUE_TONES_1]: 'Blue Tones I',
      [mapThemeIds.BLUE_TONES_2]: 'Blue Tones II',
      [mapThemeIds.BLUE_TONES_3]: 'Blue Tones III',
      [mapThemeIds.BLUE_TONES_4]: 'Blue Tones IV',
      [mapThemeIds.BLUE_TONES_5]: 'Blue Tones V',
      [mapThemeIds.COLORFUL_1]: 'Colorful I',
      [mapThemeIds.COLORFUL_2]: 'Colorful II',
      [mapThemeIds.COLORFUL_3]: 'Colorful III',
      [mapThemeIds.COLORFUL_4]: 'Colorful IV',
      [mapThemeIds.COLORFUL_5]: 'Colorful V',
      [mapThemeIds.COLORFUL_6]: 'Colorful VI',
      [mapThemeIds.TONE_IN_TONE_1]: 'Tone In Tone Red',
      [mapThemeIds.TONE_IN_TONE_2]: 'Tone In Tone Orange',
      [mapThemeIds.TONE_IN_TONE_3]: 'Tone In Tone Green',
      [mapThemeIds.TONE_IN_TONE_4]: 'Tone In Tone Turquoise',
      [mapThemeIds.TONE_IN_TONE_5]: 'Tone In Tone Blue',
      [mapThemeIds.TONE_IN_TONE_6]: 'Tone In Tone Dark Magenta',
      [mapThemeIds.EARTH_TONES_1]: 'Earth Tones I',
      [mapThemeIds.EARTH_TONES_2]: 'Earth Tones II',
      [mapThemeIds.EARTH_TONES_3]: 'Earth Tones III',
      [mapThemeIds.EARTH_TONES_4]: 'Earth Tones IV',
      [mapThemeIds.EARTH_TONES_5]: 'Earth Tones V',
      [mapThemeIds.EARTH_TONES_6]: 'Earth Tones VI',
    },
    map_theme_type: {
      [mapThemeTypeIds.BLUE_TONES]: 'Blue Tones',
      [mapThemeTypeIds.CUSTOM_WIZARD]: 'Basic',
      [mapThemeTypeIds.NEON]: 'Neon',
      [mapThemeTypeIds.COLORFUL]: 'Colorful',
      [mapThemeTypeIds.TONE_IN_TONE]: 'Tone In Tone',
      [mapThemeTypeIds.EARTH_TONES]: 'Earth Tones',
      [mapThemeTypeIds.PERSONA_WIZARD]: 'Persona',
    },
    main_menu: {
      [mainMenuIds.HOME]: 'Home',
      [mainMenuIds.CONTACT]: 'Contact & Project',
      [mainMenuIds.TEAM]: 'Team',
      [mainMenuIds.CREDITS]: 'Credits',
      [mainMenuIds.THEORY]: 'Theory',
      [mainMenuIds.DONATE]: 'Donate',
      [mainMenuIds.GALLERY]: 'Gallery',
      [mainMenuIds.LICENCE]: 'Licence',
      [mainMenuIds.SHOP]: 'Shop',
    },
    persona: {
      [personaIds.AMUNDSEN]: {
        name: 'Roald Amundsen',
        stations: [
          'Oslo, Norway',
          'Funchal, Madeira',
          'Bay of Whales, Framheim, Antarctica',
          'South Pole, Antarctica',
          'Hobart, Australia',
          'Oslo, Norway',
        ],
      },
      [personaIds.JACK]: {
        name: 'Jack the Sailor',
        stations: [
          'Yorkshire, England',
          'Matavai Bay, Thaiti',
          'Poverty Bay, New Zealand',
          'Great Barrier Reef, Cooktown, Australia',
          'Batavia (Jakarta), Indonesia',
          'Dover, England',
        ],
      },
      [personaIds.MERIAN]: {
        name: 'Sibylla Merian',
        stations: [
          'Frankfurt, Germany',
          'Friesland, Waltha Castle, Netherland',
          'Ocean to Surinam, Netherlands – Surinam',
          'Paramaribo, Surinam',
          'Amsterdam, Netherlands',
          'Amsterdam, Netherlands',
        ],
      },
    },
    projection: {
      [projectionIds.AITOFF]: {
        label: 'Aitoff',
        description: `The projection is a modified azimuthal map projection proposed by David A. Aitoff in 1889. Three years later, Ernst Hammer suggested the use of the Hammer Projection in the same manner as Aitoff.`,
      },
      [projectionIds.ARMADILLO]: {
        label: 'Armadillo',
        description: `The armadillo projection is a map projection used for world maps. It is neither conformal nor equal-area but instead affords a view evoking a perspective projection while showing most of the globe instead of the half or less that a perspective would. The projection was presented in 1943 by Erwin Raisz (1893–1968).`,
      },
      [projectionIds.AUGUST]: {
        label: 'August',
        description: '',
      },
      [projectionIds.AZIMUTHAL_EQUAL_AREA]: {
        label: 'Azimuthal Equal-Area',
        description: `The Azimuthal Equal-Area Projection (also Lambert azimuthal equal-area projection) is a particular mapping from a sphere to a disk. It accurately represents area in all regions of the sphere, but it does not accurately represent angles. It is named for the Swiss mathematician Johann Heinrich Lambert, who announced it in 1772.`,
      },
      [projectionIds.AZIMUTHAL_EQUIDISTANT]: {
        label: 'Azimuthal Equidistant',
        description: `The Azimuthal Equidistant Projection has the properties that all points on the map are at proportionally correct distances from the center point, and that all points on the map are at the correct azimuth (direction) from the center point. A useful application for this type of projection is a polar projection which shows all meridians (lines of longitude) as straight, with distances from the pole represented correctly.`,
      },
      [projectionIds.BAKER]: {
        label: 'Baker',
        description: '',
      },
      [projectionIds.BERGHAUS]: {
        label: 'Berghaus',
        description: `The Berghaus star projection uses the azimuthal equidistant projection for the central hemisphere. The other half of the world is split into five triangular pieces, forming a star around the circular center. The projection was developed by Hermann Berghaus in 1879.`,
      },
      [projectionIds.BONNE]: {
        label: 'Bonne',
        description: `Bonne is an equal-area pseudoconic map projection. Its graticule takes a form of a heart, and it was frequently used to map continents. The projection was invented by Claudius Ptolemy about A.D. 100, but it was named after Rigobert Bonne who extensively used the projection in 1752.`,
      },
      [projectionIds.CONIC_EQUAL_AREA]: {
        label: 'Conic Equal-Area',
        description: `The Conic-Equal-Area (also Albers projection) is an equal area conic projection. The projection is best suited for land masses extending in an east-to-west orientation at mid-latitudes. It is often used for maps of the contiguous United States, Europe, and Australia. The Albers projection was introduced by Heinrich C. Albers in 1805.`,
      },
      [projectionIds.CONIC_EQUIDISTANT]: {
        label: 'Conic Equidistant',
        description: `The equidistant, or simple, conic projection preserves distances along all meridians and two standard parallels. This projection often serves as a compromise between Lambert conformal conic and Albers equal-area conic projections. It is best suited for land masses extending in an east-to-west orientation at mid-latitudes when area, directions, and angles do not need to be maintained. The basic projection form was first described by Claudius Ptolemy about A.D. 100.`,
      },
      [projectionIds.CUBIC]: {
        label: 'Cubic',
        description: '',
      },
      [projectionIds.CYLINDRICAL_EQUAL_AREA]: {
        label: 'Cylindrical Equal-Area',
        description: `With the equal-area Cylindric projection, the world is represented as a rectangle, whereby the relative areas on a map are preserved. The projection was first described by the Swiss mathematician Johann H. Lambert in 1772. Since then, numerous variations have been published over the years. The projection is suitable for creating large-scale maps of areas near the equator. It is generally not recommended for small-scale (world) maps.`,
      },
      [projectionIds.CYLINDRICAL_STEREOGRAPHIC]: {
        label: 'Cylindrical Stereographic',
        description: `The Cylindrical Stereographic Projection (also Gall projection) is a cylindrical map projection with two standard parallels at latitudes 45° north and south. It can be constructed geometrically by projecting the globe onto a secant cylinder from the point on the equator opposite the given central meridian. This projection was introduced by James Gall in 1855.`,
      },
      [projectionIds.ECKERT_1]: {
        label: 'Eckert 1',
        description: `The Eckert I projection is a compromise pseudocylindrical map projection with rectilinear meridians and an odd appearance. The projection is simple, but it has no practical use other than making a world map with an unusual shape. The projection was introduced by Max Eckert in 1906.`,
      },
      [projectionIds.ECKERT_2]: {
        label: 'Eckert 2',
        description: `The Eckert II projection is an equal-area pseudocylindrical map projection with rectilinear meridians and an odd appearance. It has no practical use other than making a thematic world map with an unusual shape. The projection was introduced by Max Eckert in 1906. `,
      },
      [projectionIds.ECKERT_3]: {
        label: 'Eckert 3',
        description: `The Eckert III is a compromise pseudocylindrical map projection for world maps. The lateral meridians are semicircles, which give the projection a rounded shape and smooth corners where the lateral meridians meet the pole lines. This projection was introduced by Max Eckert in 1906. `,
      },
      [projectionIds.ECKERT_4]: {
        label: 'Eckert 4',
        description: `Eckert IV is an equal-area pseudocylindrical map projection for world maps. The lateral meridians are semicircles, which give the projection a rounded shape and smooth corners where the lateral meridians meet the pole lines. This projection is commonly used for thematic and other world maps requiring accurate areas. The projection was introduced by Max Eckert in 1906. `,
      },
      [projectionIds.ECKERT_5]: {
        label: 'Eckert 5',
        description: `Eckert V is a compromise pseudocylindrical map projection for world maps. It is the arithmetic mean of projected coordinates of the Plate Carrée and sinusoidal projections. The meridians are sinusoidal curves, producing undesirable bulging along the equator on the western and eastern edges of the map. The projection was introduced by Max Eckert in 1906.`,
      },
      [projectionIds.ECKERT_6]: {
        label: 'Eckert 6',
        description: `Eckert VI is an equal-area pseudocylindrical map projection for world maps. The meridians are sinusoidal curves, producing undesirable bulging along the equator on the western and eastern edges of the map. The projection was introduced by Max Eckert in 1906, who preferred this projection over the more popular Eckert IV.`,
      },
      [projectionIds.EISENLOHR]: {
        label: 'Eisenlohr',
        description: '',
      },
      [projectionIds.EQUIRECTANGULAR]: {
        label: 'Equirectangular',
        description: `The equirectangular is also known as the Equidistant Cylindrical, simple cylindrical, rectangular, or when the standard parallel is the equator, plate carrée map projection. A grid of parallels and meridians forms equal rectangles from east to west and from pole to pole. It is one of the simplest cylindrical projections and therefore its usage was more common in the past. The equidistant cylindrical projection was invented by Marinus of Tyre about A.D. 100.`,
      },
      [projectionIds.FAHEY]: {
        label: 'Fahey',
        description: '',
      },
      [projectionIds.FOUCAUT_SINUSOIDAL]: {
        label: 'Foucaut Sinusoidal',
        description: '',
      },
      [projectionIds.GILBERT]: {
        label: 'Gilbert',
        description: '',
      },
      [projectionIds.GINGERY]: {
        label: 'Gingery',
        description: '',
      },
      [projectionIds.GINZBURG_5]: {
        label: 'Ginzburg 5',
        description: '',
      },
      [projectionIds.GRINGORTEN]: {
        label: 'Gringorten',
        description: '',
      },
      [projectionIds.GUYOU]: {
        label: 'Guyou',
        description: '',
      },
      [projectionIds.HAMMER]: {
        label: 'Hammer',
        description: `The Hammer projection is a modification of the Lambert azimuthal equal-area projection. It is an equal-area projection and its graticule takes a form of an ellipse. The projection is also known as the Hammer-Aitoff projection. The Hammer projection is appropriate for small-scale mapping. The Hammer projection was developed by Ernst von Hammer in 1892 after being inspired by the Russian cartographer, David A. Aitoff.`,
      },
      [projectionIds.HEALPIX]: {
        label: 'Healpix',
        description: '',
      },
      [projectionIds.KAVRAYSKIY_7]: {
        label: 'Kavrayskiy 7',
        description: '',
      },
      [projectionIds.MERCATOR]: {
        label: 'Mercator',
        description: `Mercator is a conformal cylindrical map projection that was originally created to display accurate compass bearings for sea travel. An additional feature of this projection is that all local shapes are accurate and correctly defined at infinitesimal scale. It was presented by Gerardus Mercator in 1569. The Web Mercator variant of the projection is the de facto standard for web maps and online services`,
      },
      [projectionIds.MILLER]: {
        label: 'Miller',
        description: `The Miller cylindrical projection is a compromise cylindrical map projection. The projection is a modification of the Mercator projection thus they are almost identical near the equator. Although the Miller projection does not project poles to infinity, distortion is still severe at the poles. The Miller cylindrical projection was developed by Osborn M. Miller in 1942.`,
      },
      [projectionIds.MOLLWEIDE]: {
        label: 'Mollweide',
        description: `The Mollweide projection is an equal-area pseudocylindrical map projection displaying the world in a form of an ellipse with axes in a 2:1 ratio. It is also known as Babinet, elliptical, homolographic, or homalographic projection. The projection is appropriate for thematic and other world maps requiring accurate areas. Mollweide was first introduced by Karl B. Mollweide in 1805.`,
      },
      [projectionIds.NATURAL_EARTH_1]: {
        label: 'Natural Earth 1',
        description: `The Natural Earth projection is a compromise pseudocylindrical map projection for world maps. The projection has rounded corners where lateral meridians meet the pole lines, which suggest that the Earth has a rounded shape. It was specifically designed by Tom Patterson for displaying physical data in 2007. Bojan Šavrič, Tom Patterson, and Bernhard Jenny published the math for the projection in 2011.`,
      },
      [projectionIds.NATURAL_EARTH_2]: {
        label: 'Natural Earth 2',
        description: `The Natural Earth II projection is a compromise pseudocylindrical map projection for world maps. It is distinctive from the Natural Earth projection by the meridians, which bend steeply toward a short pole line giving the map a unique appearance among compromise small-scale projections. It was designed by Tom Patterson. Bojan Šavrič, Tom Patterson, and Bernhard Jenny published the math for the projection in 2015.`,
      },
      [projectionIds.ORTHOGRAPHIC]: {
        label: ' Orthographic',
        description: '',
      },
      [projectionIds.PATTERSON]: {
        label: 'Patterson',
        description: `The Patterson projection is a compromise cylindrical map projection. It exaggerates high-latitude areas less than the Miller and Compact Miller projections. The projection maps the world in a rectangle with a height-to-width ratio of approximately 0.57. It was designed by Tom Patterson in 2014. Later that year, he published the math for the projection together with Bojan Šavrič and Bernhard Jenny.`,
      },
      [projectionIds.ROBINSON]: {
        label: 'Robinson',
        description: `The Robinson projection is perhaps the most commonly used compromise pseudocylindrical map projection for world maps. National Geographic used the Robinson projection for their world maps for about a decade until 1998. The projection was designed by Arthur H. Robinson in 1963 at the request of the Rand McNally Company using graphic design rather than mathematical equation development.`,
      },
      [projectionIds.SINUSOIDAL]: {
        label: 'Sinusoidal',
        description: `The sinusoidal projection is a pseudocylindrical equal-area projection displaying all parallels and the central meridian at true scale. The boundary meridians bulge outward excessively producing considerable shape distortion near the map outline. Alternative formats reduce the distortion along outer meridians by interrupting the continuity of the projection over the oceans and by centering the continents around their own central meridians, or vice versa. The sinusoidal projection is also known as the Sanson-Flamsteed and Mercator-Sanson projection after the cartographers who used it. The projection was developed in the 16th century.`,
      },
      [projectionIds.STEREOGRAPHIC]: {
        label: 'Stereographic',
        description: `Stereographic is a planar perspective projection, viewed from the point on the globe opposite the point of tangency. It projects points on a spheroid directly to the plane and it is the only azimuthal conformal projection. The projection is most commonly used in polar aspects for topographic maps of polar regions. The most well-known are Universal Polar Stereographic (UPS) maps showing areas north of 84° north and south of 80° south that aren't included in the Universal Transverse Mercator (UTM) coordinate system.`,
      },
      [projectionIds.VAN_DER_GRINTEN_4]: {
        label: 'Van Der Grinten 4',
        description: `The Van der Grinten I projection is a polyconic projection of the world in a circle. In this projection, the continents look similar to how they appear on the Mercator projection, except that the Van der Grinten I portrays the world with a curved graticule. Both the meridians and parallels are projected as circular arcs. National Geographic used the projection for their world maps between 1922 and 1988. The projection was invented by Alphons J. van der Grinten in 1898.`,
      },
      [projectionIds.WAGNER]: {
        label: 'Wagner',
        description: '',
      },
      [projectionIds.WAGNER_4]: {
        label: 'Wagner 4',
        description: `The Wagner IV is an equal-area pseudocylindrical projection for world maps. Its meridians follow a portion of ellipses compared to the Eckert IV projection whose meridians are semiellipses. The Wagner IV projection was introduced by Wagner in 1932.`,
      },
      [projectionIds.WAGNER_6]: {
        label: 'Wagner 6',
        description: `The Wagner VII or Hammer-Wagner projection is a modification of the Lambert azimuthal equal-area projection. All parallels are convex toward the equator, which gives the projection a unique appearance and relatively low distortion characteristic compared to some equal-area pseudocylindrical projections. The projection was introduced by Wagner in 1941.`,
      },
      [projectionIds.WATERMAN]: {
        label: 'Waterman',
        description: '',
      },
      [projectionIds.WINKEL_3]: {
        label: 'Winkel 3',
        description: `The Winkel Tripel is a compromise modified azimuthal projection for world maps. It is an arithmetic mean of projected coordinates of Aitoff and equidistant cylindrical projections. The projection is known to have one of the lowest mean scale and area distortions among compromise projections for small-scale mapping. It has been used by the National Geographic Society since 1998 for general world maps. The Winkel Tripel projection was introduced by Oswald Winkel in 1921.`,
      },
    },
    projection_shape: {
      [projectionShapeIds.ANGULAR_SHAPE]: 'Angular',
      [projectionShapeIds.BUTTERFLY_SHAPE]: 'Butterfly',
      [projectionShapeIds.CIRCLE_SHAPE]: 'Circle',
      [projectionShapeIds.CONCAVE_SHAPE]: 'Concave',
      [projectionShapeIds.CONICAL_SHAPE]: 'Conical',
      [projectionShapeIds.CURVED_PARALLEL_SHAPE]: 'Curved parallel',
      [projectionShapeIds.CURVED_SHAPE]: 'Curved',
      [projectionShapeIds.DOUBLE_HEMISPHERE_SHAPE]: 'Double hemisphere',
      [projectionShapeIds.HEART_SHAPE]: 'Heart',
      [projectionShapeIds.OVAL_SHAPE]: 'Oval',
      [projectionShapeIds.RECTANGULAR_SHAPE]: 'Square',
      [projectionShapeIds.SINUSOIDAL_SHAPE]: 'Sinusoidal',
      [projectionShapeIds.STAR_SHAPE]: 'Star',
      [projectionShapeIds.ZICKZACK_SHAPE]: 'Zig zag',
    },
    projection_type: {
      [projectionTypeIds.AZIMUTHAL]: {
        label: 'Azimuthal',
        description: '',
      },
      [projectionTypeIds.CONICAL]: {
        label: 'Conical',
        description: '',
      },
      [projectionTypeIds.COMPROMISE]: {
        label: 'Compromise',
        description: '',
      },
      [projectionTypeIds.CYLINDRICAL]: {
        label: 'Cylindrical',
        description: '',
      },
    },
    social_media_group: {
      [socialMediaGroupIds.CONTACT]: 'Contact',
      [socialMediaGroupIds.DOWNLOAD]: 'Download',
      [socialMediaGroupIds.NEWSLETTER]: 'Newsletter',
      [socialMediaGroupIds.SOCIAL_MEDIA]: 'Stay connected',
    },
  },
}
