export const mapThemeTypeIds = {
  BLUE_TONES: 'BLUE_TONES',
  CUSTOM_WIZARD: 'CUSTOM_WIZARD',
  NEON: 'NEON',
  COLORFUL: 'COLORFUL',
  TONE_IN_TONE: 'TONE_IN_TONE',
  EARTH_TONES: 'EARTH_TONES',
  PERSONA_WIZARD: 'PERSONA_WIZARD',
}

export const mapThemeTypeList = [
  {
    id: mapThemeTypeIds.BLUE_TONES,
    label: `data_model.map_theme_type.${mapThemeTypeIds.BLUE_TONES}`,
  },
  {
    id: mapThemeTypeIds.CUSTOM_WIZARD,
    label: `data_model.map_theme_type.${mapThemeTypeIds.CUSTOM_WIZARD}`,
  },
  {
    id: mapThemeTypeIds.NEON,
    label: `data_model.map_theme_type.${mapThemeTypeIds.NEON}`,
  },
  {
    id: mapThemeTypeIds.COLORFUL,
    label: `data_model.map_theme_type.${mapThemeTypeIds.COLORFUL}`,
  },
  {
    id: mapThemeTypeIds.TONE_IN_TONE,
    label: `data_model.map_theme_type.${mapThemeTypeIds.TONE_IN_TONE}`,
  },
  {
    id: mapThemeTypeIds.EARTH_TONES,
    label: `data_model.map_theme_type.${mapThemeTypeIds.EARTH_TONES}`,
  },
  {
    id: mapThemeTypeIds.PERSONA_WIZARD,
    label: `data_model.map_theme_type.${mapThemeTypeIds.PERSONA_WIZARD}`,
  },
]
