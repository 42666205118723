<template>
  <div :class="rootClasses" class="main-menu">
    <div :style="scrollLockStyles" class="main-menu__bar">
      <div class="main-menu__burger">
        <navigation-btn :is-active="isOpen" class="main-menu__btn" @click="onClick" />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import bemMixin from '@/assets/js/mixin/bem'
  import scrollLockHelperMixin from '@/assets/js/mixin/scroll-lock-helper'
  import NavigationBtn from '@/components/navigation-btn/navigation-btn'

  export default {
    components: { NavigationBtn },
    mixins: [bemMixin('main-menu'), scrollLockHelperMixin],
    computed: {
      ...mapState('navigation', ['isOpen']),
      rootClasses() {
        return [this.bemAdd(this.isOpen ? 'is-open' : '')]
      },
    },
    methods: {
      ...mapActions('navigation', ['setMenu']),
      onClick() {
        this.setMenu({ isOpen: !this.isOpen })
      },
    },
  }
</script>

<style lang="scss" src="./_main-menu.scss" />
