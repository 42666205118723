import { wizardStepIds } from '@/assets/js/model/route'
import { personaIds } from '@/assets/js/model/persona'

export const interstitialContentIds = {
  CUSTOM_WIZARD_INTRODUCTION: 'CUSTOM_WIZARD_INTRODUCTION',
  CUSTOM_WIZARD_CENTERING: 'CUSTOM_WIZARD_CENTERING',
  CUSTOM_WIZARD_PROJECTION: 'CUSTOM_WIZARD_PROJECTION',
  CUSTOM_WIZARD_GEOLAYERS: 'CUSTOM_WIZARD_GEOLAYERS',
  CUSTOM_WIZARD_ZOOM: 'CUSTOM_WIZARD_ZOOM',
  CUSTOM_WIZARD_THEME: 'CUSTOM_WIZARD_THEME',
  CUSTOM_WIZARD_EXPORT: 'CUSTOM_WIZARD_EXPORT',
}

export const interstitialContentList = [
  {
    id: interstitialContentIds.CUSTOM_WIZARD_INTRODUCTION,
    text: `data_model.interstitial_content.${interstitialContentIds.CUSTOM_WIZARD_INTRODUCTION}.text`,
    forwardLabel: `data_model.interstitial_content.${interstitialContentIds.CUSTOM_WIZARD_INTRODUCTION}.forwardLabel`,
    hasForwardBtn: false,
    image: '/images/wizard_interstitial_images/custom/01_Intro_Bilder_Overlay.png',
    wizardStepId: null,
    wizardStepIdx: null,
    personaId: personaIds.CUSTOM,
  },
  {
    id: interstitialContentIds.CUSTOM_WIZARD_CENTERING,
    text: `data_model.interstitial_content.${interstitialContentIds.CUSTOM_WIZARD_CENTERING}.text`,
    forwardLabel: `data_model.interstitial_content.${interstitialContentIds.CUSTOM_WIZARD_CENTERING}.forwardLabel`,
    image: '/images/wizard_interstitial_images/custom/01_Centering_Bilder_Overlay.png',
    wizardStepId: wizardStepIds.CUSTOM_WIZARD_CENTERING,
    wizardStepIdx: 0,
    personaId: personaIds.CUSTOM,
  },
  {
    id: interstitialContentIds.CUSTOM_WIZARD_PROJECTION,
    text: `data_model.interstitial_content.${interstitialContentIds.CUSTOM_WIZARD_PROJECTION}.text`,
    forwardLabel: `data_model.interstitial_content.${interstitialContentIds.CUSTOM_WIZARD_PROJECTION}.forwardLabel`,
    image: '/images/wizard_interstitial_images/custom/01_Projection_Bilder_Overlay.png',
    wizardStepId: wizardStepIds.CUSTOM_WIZARD_PROJECTION,
    wizardStepIdx: 1,
    personaId: personaIds.CUSTOM,
  },
  {
    id: interstitialContentIds.CUSTOM_WIZARD_GEOLAYERS,
    text: `data_model.interstitial_content.${interstitialContentIds.CUSTOM_WIZARD_GEOLAYERS}.text`,
    forwardLabel: `data_model.interstitial_content.${interstitialContentIds.CUSTOM_WIZARD_GEOLAYERS}.forwardLabel`,
    image: '/images/wizard_interstitial_images/custom/01_Geolayers_Bilder_Overlay.png',
    wizardStepId: wizardStepIds.CUSTOM_WIZARD_GEOLAYERS,
    wizardStepIdx: 2,
    personaId: personaIds.CUSTOM,
  },
  {
    id: interstitialContentIds.CUSTOM_WIZARD_ZOOM,
    text: `data_model.interstitial_content.${interstitialContentIds.CUSTOM_WIZARD_ZOOM}.text`,
    forwardLabel: `data_model.interstitial_content.${interstitialContentIds.CUSTOM_WIZARD_ZOOM}.forwardLabel`,
    image: '/images/wizard_interstitial_images/custom/01_Zoom_Bilder_Overlay.png',
    wizardStepId: wizardStepIds.CUSTOM_WIZARD_ZOOM,
    wizardStepIdx: 3,
    personaId: personaIds.CUSTOM,
  },
  {
    id: interstitialContentIds.CUSTOM_WIZARD_THEME,
    text: `data_model.interstitial_content.${interstitialContentIds.CUSTOM_WIZARD_THEME}.text`,
    forwardLabel: `data_model.interstitial_content.${interstitialContentIds.CUSTOM_WIZARD_THEME}.forwardLabel`,
    image: '/images/wizard_interstitial_images/custom/01_Design_Bilder_Overlay.png',
    wizardStepId: wizardStepIds.CUSTOM_WIZARD_THEME,
    wizardStepIdx: 4,
    personaId: personaIds.CUSTOM,
  },
  {
    id: interstitialContentIds.CUSTOM_WIZARD_EXPORT,
    text: `data_model.interstitial_content.${interstitialContentIds.CUSTOM_WIZARD_EXPORT}.text`,
    forwardLabel: `data_model.interstitial_content.${interstitialContentIds.CUSTOM_WIZARD_EXPORT}.forwardLabel`,
    image: '/images/wizard_interstitial_images/custom/01_Export_Bilder_Overlay.png',
    wizardStepId: wizardStepIds.CUSTOM_WIZARD_EXPORT,
    wizardStepIdx: 5,
    personaId: personaIds.CUSTOM,
  },
]
