import * as wizardCustom from './interstitial-content/wizard-custom'
import * as wizardPersonaAmundsen from './interstitial-content/wizard-persona-amundsen'
import * as wizardPersonaJack from './interstitial-content/wizard-persona-jack'
import * as wizardPersonaMerian from './interstitial-content/wizard-persona-merian'

export const interstitialContentIds = {
  ...wizardCustom.interstitialContentIds,
  ...wizardPersonaAmundsen.interstitialContentIds,
  ...wizardPersonaJack.interstitialContentIds,
  ...wizardPersonaMerian.interstitialContentIds,
}

export const interstitialContentList = [
  ...wizardCustom.interstitialContentList,
  ...wizardPersonaAmundsen.interstitialContentList,
  ...wizardPersonaJack.interstitialContentList,
  ...wizardPersonaMerian.interstitialContentList,
]
