import { layerIds } from '@/assets/js/model/layer'
import { styleAttributeIds } from '@/assets/js/model/style-attributes'
import { mapThemeTypeIds } from '@/assets/js/model/map-theme-type'

export const mapThemeIds = {
  DEFAULT: 'DEFAULT',
  SPLASH_SCREEN: 'SPLASH_SCREEN',
  WIZARD_STEP_CENTERING: 'WIZARD_STEP_CENTERING',
}

export const mapThemeList = [
  {
    id: mapThemeIds.DEFAULT,
    mapThemeType: mapThemeTypeIds.CUSTOM_WIZARD,
    label: `data_model.map_theme.${mapThemeIds.DEFAULT}`,
    layerStyleList: [
      {
        layerIds: [layerIds.BACKGROUND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#382f32',
        },
      },
      {
        layerIds: [layerIds.SPHERE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#fffcdb',
          [styleAttributeIds.LINE_WIDTH]: 4,
        },
      },
      {
        layerIds: [layerIds.LINES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#d1e78e',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.GRATICULES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#d1e78e',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
          [styleAttributeIds.LINE_DASH]: [2, 2],
        },
      },
      {
        layerIds: [layerIds.LAND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#fffcdb',
        },
      },
      {
        layerIds: [layerIds.OCEAN],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#2a2326',
        },
      },
      {
        layerIds: [layerIds.COASTLINE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#382f32',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
        },
      },
      {
        layerIds: [layerIds.LAKES],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#2a2326',
          [styleAttributeIds.LINE_COLOR]: '#2a2326',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.RIVERS],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#2a2326',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.COUNTRIES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#d1e78e',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.TISSOT_INDICATRIX],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#00ff00cc',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
          [styleAttributeIds.FILL_COLOR]: '#00ff004d',
        },
      },
    ],
    cssVariables: {
      mapStationColor: '#ff00ff',
    },
  },
  {
    id: mapThemeIds.SPLASH_SCREEN,
    mapThemeType: mapThemeTypeIds.CUSTOM_WIZARD,
    label: `data_model.map_theme.${mapThemeIds.DEFAULT}`,
    layerStyleList: [
      {
        layerIds: [layerIds.SPHERE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#fffcdb',
          [styleAttributeIds.LINE_WIDTH]: 4,
        },
      },
      {
        layerIds: [layerIds.SPHERE_BACKGROUND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#2a2326',
        },
      },
      {
        layerIds: [layerIds.GRATICULES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#d1e78e',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
          [styleAttributeIds.LINE_DASH]: [2, 2],
        },
      },
      {
        layerIds: [layerIds.LAND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#fffcdb',
        },
      },
    ],
    cssVariables: {
      mapStationColor: '#ff00ff',
    },
  },
  {
    id: mapThemeIds.WIZARD_STEP_CENTERING,
    mapThemeType: mapThemeTypeIds.CUSTOM_WIZARD,
    label: null,
    layerStyleList: [
      {
        layerIds: [layerIds.BACKGROUND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#382f32',
        },
      },
      {
        layerIds: [layerIds.SPHERE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#382f32',
          [styleAttributeIds.LINE_WIDTH]: 4,
        },
      },
      {
        layerIds: [layerIds.LINES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#d1e78e',
          [styleAttributeIds.LINE_WIDTH]: 1,
        },
      },
      {
        layerIds: [layerIds.GRATICULES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#d1e78e',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
          [styleAttributeIds.LINE_DASH]: [2, 2],
        },
      },
      {
        layerIds: [layerIds.LAND],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#fffcdb',
        },
      },
      {
        layerIds: [layerIds.OCEAN],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#2a2326',
        },
      },
      {
        layerIds: [layerIds.COASTLINE],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#382f32',
          [styleAttributeIds.LINE_WIDTH]: 0.75,
        },
      },
      {
        layerIds: [layerIds.LAKES],
        styles: {
          [styleAttributeIds.FILL_COLOR]: '#2a2326',
          [styleAttributeIds.LINE_COLOR]: '#2a2326',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.RIVERS],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#2a2326',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
      {
        layerIds: [layerIds.COUNTRIES],
        styles: {
          [styleAttributeIds.LINE_COLOR]: '#d1e78e',
          [styleAttributeIds.LINE_WIDTH]: 0.5,
        },
      },
    ],
  },
]
