<template>
  <div class="overlay-menu">
    <div ref="wrap" class="overlay-menu__wrap">
      <div class="overlay-menu__header">
        <h1>
          {{ $t('main_menu.title') }}
        </h1>
      </div>

      <nav class="overlay-menu__nav layout layout--primary">
        <ul class="layout__container overlay-menu__items u-reset">
          <li class="overlay-menu__item">
            <language-menu />
          </li>
          <li v-for="item in $options.mainMenuList" :key="item.id" class="overlay-menu__item">
            <n-link
              v-if="item.link"
              :class="getButtonClasses(item.image)"
              :to="getButtonLink(item.link)"
              class="btn btn--medium btn--primary overlay-menu__link u-reset"
              @click.native="onClick"
            >
              <template v-if="item.image">
                <responsive-img
                  v-bind="item.image"
                  alt=""
                  class="overlay-menu__btn-image"
                  loading="lazy"
                />
              </template>
              <template v-else>
                {{ $t(item.label) }}
              </template>
            </n-link>
            <a
              v-else
              class="btn btn--medium btn--primary overlay-menu__link u-reset"
              :class="getButtonClasses(item.image)"
              :href="item.externalLink"
            >
              {{ $t(item.label) }}
            </a>
          </li>
        </ul>
      </nav>

      <div class="overlay-menu__footer">
        <footer-navigation />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import bemMixin from '@/assets/js/mixin/bem'
  import { mainMenuList } from '@/assets/js/model/main-menu'
  import FooterNavigation from '@/components/footer-navigation/footer-navigation'
  import { getImage } from '@/assets/js/util/misc/image'
  import LanguageMenu from '@/components/language-menu/language-menu'
  import ResponsiveImg from '~/components/responsive-img/responsive-img'

  export default {
    components: { ResponsiveImg, LanguageMenu, FooterNavigation },
    mixins: [bemMixin('overlay-menu')],
    props: {},
    mounted() {
      document.body.addEventListener('click', this.onClickAway)
    },
    beforeDestroy() {
      document.body.removeEventListener('click', this.onClickAway)
    },
    methods: {
      ...mapActions('navigation', ['setMenu']),
      getButtonLink(link) {
        return this.localePath(link)
      },
      getButtonClasses(image) {
        return [this.bemAdd(image ? 'has-image' : '', 'link')]
      },
      onClick() {
        this.setMenu({ isOpen: false })
      },
      onClickAway({ target }) {
        const { wrap } = this.$refs
        const isInside = wrap.contains(target) || target === wrap

        if (isInside) {
          return
        }

        this.onClick()
      },
    },
    getImage,
    mainMenuList,
  }
</script>

<style lang="scss" src="./_overlay-menu.scss" />
