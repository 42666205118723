import { wizardStepIds } from '@/assets/js/model/route'
import { personaIds } from '@/assets/js/model/persona'

export const interstitialContentIds = {
  PERSONA_WIZARD_JACK_0: 'PERSONA_WIZARD_JACK_0',
  PERSONA_WIZARD_JACK_1: 'PERSONA_WIZARD_JACK_1',
  PERSONA_WIZARD_JACK_2: 'PERSONA_WIZARD_JACK_2',
  PERSONA_WIZARD_JACK_3: 'PERSONA_WIZARD_JACK_3',
  PERSONA_WIZARD_JACK_4: 'PERSONA_WIZARD_JACK_4',
  PERSONA_WIZARD_JACK_5: 'PERSONA_WIZARD_JACK_5',
  PERSONA_WIZARD_JACK_6: 'PERSONA_WIZARD_JACK_6',
}

export const interstitialContentList = [
  {
    id: interstitialContentIds.PERSONA_WIZARD_JACK_0,
    text: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_JACK_0}.text`,
    forwardLabel: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_JACK_0}.forwardLabel`,
    hasForwardBtn: false,
    image: '/images/wizard_interstitial_images/jack/00_eingangsbild_amundsen_641_757@3x.png',
    wizardStepId: null,
    wizardStepIdx: null,
    personaId: personaIds.JACK,
  },
  {
    id: interstitialContentIds.PERSONA_WIZARD_JACK_1,
    text: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_JACK_1}.text`,
    forwardLabel: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_JACK_1}.forwardLabel`,
    image: '/images/wizard_interstitial_images/jack/01_jack_bier_446_500@3x.png',
    wizardStepId: wizardStepIds.PERSONA_WIZARD_JACK_STEP_1,
    wizardStepIdx: 0,
    personaId: personaIds.JACK,
  },
  {
    id: interstitialContentIds.PERSONA_WIZARD_JACK_2,
    text: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_JACK_2}.text`,
    forwardLabel: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_JACK_2}.forwardLabel`,
    image: '/images/wizard_interstitial_images/jack/02_jack_bier_446_500_copy@3x.png',
    wizardStepId: wizardStepIds.PERSONA_WIZARD_JACK_STEP_2,
    wizardStepIdx: 1,
    personaId: personaIds.JACK,
  },
  {
    id: interstitialContentIds.PERSONA_WIZARD_JACK_3,
    text: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_JACK_3}.text`,
    forwardLabel: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_JACK_3}.forwardLabel`,
    image: '/images/wizard_interstitial_images/jack/03_jack_steuerrad_446_500@3x.png',
    wizardStepId: wizardStepIds.PERSONA_WIZARD_JACK_STEP_3,
    wizardStepIdx: 2,
    personaId: personaIds.JACK,
  },
  {
    id: interstitialContentIds.PERSONA_WIZARD_JACK_4,
    text: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_JACK_4}.text`,
    forwardLabel: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_JACK_4}.forwardLabel`,
    image: '/images/wizard_interstitial_images/jack/04_jack_steuerrad_446_500_copy@3x.png',
    wizardStepId: wizardStepIds.PERSONA_WIZARD_JACK_STEP_4,
    wizardStepIdx: 3,
    personaId: personaIds.JACK,
  },
  {
    id: interstitialContentIds.PERSONA_WIZARD_JACK_5,
    text: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_JACK_5}.text`,
    forwardLabel: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_JACK_5}.forwardLabel`,
    image: '/images/wizard_interstitial_images/jack/05_jack_kneipe_446_500@3x.png',
    wizardStepId: wizardStepIds.PERSONA_WIZARD_JACK_STEP_5,
    wizardStepIdx: 4,
    personaId: personaIds.JACK,
  },
  {
    id: interstitialContentIds.PERSONA_WIZARD_JACK_6,
    text: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_JACK_6}.text`,
    forwardLabel: `data_model.interstitial_content.${interstitialContentIds.PERSONA_WIZARD_JACK_6}.forwardLabel`,
    image: '/images/wizard_interstitial_images/jack/06_jack_pfeiffe_446_500@3x.png',
    wizardStepId: wizardStepIds.PERSONA_WIZARD_JACK_STEP_6,
    wizardStepIdx: 5,
    personaId: personaIds.JACK,
  },
]
