import Cookies from 'js-cookie'
import * as actions from '@/store/cookie-banner/actions'
import { SET_COOKIE_ACCEPTED } from '@/store/cookie-banner/mutation-types'
import { COOKIE_BANNER_COOKIE_NAME } from '~/store/cookie-banner/util'

const mutations = {
  [SET_COOKIE_ACCEPTED](state, { isAccepted }) {
    state.isAccepted = isAccepted
  },
}

const state = () => ({
  isAccepted: !!Cookies.get(COOKIE_BANNER_COOKIE_NAME),
})

export default {
  namespaced: true,
  mutations,
  actions,
  state,
}
