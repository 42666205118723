import { wizardStepIds, wizardStepParams } from '@/assets/js/model/route'
import { personaIds } from '@/assets/js/model/persona'
import { mapConfigIds } from '@/assets/js/model/map-config'
import { layerIds } from '@/assets/js/model/layer'
import { projectionIds } from '@/assets/js/model/projection'
import { renderingAdapterIds } from '@/assets/js/model/export'
import { mapThemeIds } from '@/assets/js/model/map-theme'

export const wizardStepList = [
  {
    id: wizardStepIds.PERSONA_WIZARD_JACK_STEP_1,
    personaId: personaIds.JACK,
    label: `data_model.persona.${personaIds.JACK}.stations[0]`,
    param: wizardStepParams[wizardStepIds.PERSONA_WIZARD_JACK_STEP_1],
    icon: '/images/wizard_step_icons/jack/01_nav_image_jack_bier_50_50@3x.png',
    inNav: true,
    component: () => import('@/components/wizard-step-persona-content/wizard-step-persona-content'),
    mapConfig: {
      id: mapConfigIds.PERSONA_WIZARD,
      layerIds: [
        layerIds.CLIP_PATH,
        layerIds.SPHERE,
        layerIds.GRATICULES,
        layerIds.LAND,
        layerIds.OCEAN,
        layerIds.COASTLINE,
        layerIds.PERSONA_JACK_JOURNEY,
        layerIds.PERSONA_JACK_STATIONS,
      ],
      projectionId: projectionIds.FAHEY,
      renderingAdapterId: renderingAdapterIds.SVG,
      mapThemeId: mapThemeIds.PERSONA_JACK_INITIAL,
      center: [0, 0],
      rotation: [2.96630859375, -51.55060496921001, 0],
      zoom: 1,
      wizardStepProgressIdx: 0,
      version: '1.0.0',
    },
  },
  {
    id: wizardStepIds.PERSONA_WIZARD_JACK_STEP_2,
    personaId: personaIds.JACK,
    label: `data_model.persona.${personaIds.JACK}.stations[1]`,
    param: wizardStepParams[wizardStepIds.PERSONA_WIZARD_JACK_STEP_2],
    icon: '/images/wizard_step_icons/jack/02_nav_image_jack_bier_50_50@3x.png',
    inNav: true,
    component: () => import('@/components/wizard-step-persona-content/wizard-step-persona-content'),
    mapConfig: {
      id: mapConfigIds.PERSONA_WIZARD,
      layerIds: [
        layerIds.CLIP_PATH,
        layerIds.SPHERE,
        layerIds.GRATICULES,
        layerIds.LAND,
        layerIds.OCEAN,
        layerIds.COASTLINE,
        layerIds.PERSONA_JACK_JOURNEY,
        layerIds.PERSONA_JACK_STATIONS,
      ],
      projectionId: projectionIds.MERCATOR,
      renderingAdapterId: renderingAdapterIds.SVG,
      mapThemeId: mapThemeIds.PERSONA_JACK_INITIAL,
      center: [0, 0],
      rotation: [149.44702148437497, 17.633552213674417, 0],
      zoom: 1,
      wizardStepProgressIdx: 0,
      version: '1.0.0',
    },
  },
  {
    id: wizardStepIds.PERSONA_WIZARD_JACK_STEP_3,
    personaId: personaIds.JACK,
    label: `data_model.persona.${personaIds.JACK}.stations[2]`,
    param: wizardStepParams[wizardStepIds.PERSONA_WIZARD_JACK_STEP_3],
    icon: '/images/wizard_step_icons/jack/03_nav_image_jack_steuerrad_50_50@3x.png',
    inNav: true,
    component: () => import('@/components/wizard-step-persona-content/wizard-step-persona-content'),
    mapConfig: {
      id: mapConfigIds.PERSONA_WIZARD,
      layerIds: [
        layerIds.CLIP_PATH,
        layerIds.SPHERE,
        layerIds.GRATICULES,
        layerIds.LAND,
        layerIds.OCEAN,
        layerIds.COASTLINE,
        layerIds.PERSONA_JACK_JOURNEY,
        layerIds.PERSONA_JACK_STATIONS,
      ],
      projectionId: projectionIds.MERCATOR,
      renderingAdapterId: renderingAdapterIds.SVG,
      mapThemeId: mapThemeIds.PERSONA_JACK,
      center: [0, 0],
      rotation: [-182.0592498779297, 38.710696742087286, 0],
      zoom: 1.5,
      wizardStepProgressIdx: 0,
      version: '1.0.0',
    },
  },
  {
    id: wizardStepIds.PERSONA_WIZARD_JACK_STEP_4,
    personaId: personaIds.JACK,
    label: `data_model.persona.${personaIds.JACK}.stations[3]`,
    param: wizardStepParams[wizardStepIds.PERSONA_WIZARD_JACK_STEP_4],
    icon: '/images/wizard_step_icons/jack/04_nav_image_jack_steuerrad_50_50@3x.png',
    inNav: true,
    component: () => import('@/components/wizard-step-persona-content/wizard-step-persona-content'),
    mapConfig: {
      id: mapConfigIds.PERSONA_WIZARD,
      layerIds: [
        layerIds.CLIP_PATH,
        layerIds.SPHERE,
        layerIds.GRATICULES,
        layerIds.LAND,
        layerIds.OCEAN,
        layerIds.COASTLINE,
        layerIds.COUNTRIES,
        layerIds.PERSONA_JACK_JOURNEY,
        layerIds.PERSONA_JACK_STATIONS,
      ],
      projectionId: projectionIds.MERCATOR,
      renderingAdapterId: renderingAdapterIds.SVG,
      mapThemeId: mapThemeIds.PERSONA_JACK,
      center: [0, 0],
      rotation: [214.73688125610352, 15.467412547766985, 0],
      zoom: 5.3,
      wizardStepProgressIdx: 0,
      version: '1.0.0',
    },
  },
  {
    id: wizardStepIds.PERSONA_WIZARD_JACK_STEP_5,
    personaId: personaIds.JACK,
    label: `data_model.persona.${personaIds.JACK}.stations[4]`,
    param: wizardStepParams[wizardStepIds.PERSONA_WIZARD_JACK_STEP_5],
    icon: '/images/wizard_step_icons/jack/05_nav_image_jack_kneipe_50_50@3x.png',
    inNav: true,
    component: () => import('@/components/wizard-step-persona-content/wizard-step-persona-content'),
    mapConfig: {
      id: mapConfigIds.PERSONA_WIZARD,
      layerIds: [
        layerIds.CLIP_PATH,
        layerIds.SPHERE,
        layerIds.GRATICULES,
        layerIds.LAND,
        layerIds.OCEAN,
        layerIds.COASTLINE,
        layerIds.LAKES,
        layerIds.RIVERS,
        layerIds.COUNTRIES,
        layerIds.PERSONA_JACK_JOURNEY,
        layerIds.PERSONA_JACK_STATIONS,
      ],
      projectionId: projectionIds.MERCATOR,
      renderingAdapterId: renderingAdapterIds.SVG,
      mapThemeId: mapThemeIds.PERSONA_JACK,
      center: [0, 0],
      rotation: [253.1634521484375, 6.203360005872792, 0],
      zoom: 3.3,
      wizardStepProgressIdx: 0,
      version: '1.0.0',
    },
  },
  {
    id: wizardStepIds.PERSONA_WIZARD_JACK_STEP_6,
    personaId: personaIds.JACK,
    label: `data_model.persona.${personaIds.JACK}.stations[5]`,
    param: wizardStepParams[wizardStepIds.PERSONA_WIZARD_JACK_STEP_6],
    icon: '/images/wizard_step_icons/jack/06_nav_image_jack_pfeiffe_50_50@3x.png',
    inNav: true,
    component: () => import('@/components/wizard-step-persona-content/wizard-step-persona-content'),
    mapConfig: {
      id: mapConfigIds.PERSONA_WIZARD,
      layerIds: [
        layerIds.CLIP_PATH,
        layerIds.SPHERE,
        layerIds.GRATICULES,
        layerIds.LAND,
        layerIds.OCEAN,
        layerIds.COASTLINE,
        layerIds.LAKES,
        layerIds.RIVERS,
        layerIds.COUNTRIES,
        layerIds.PERSONA_JACK_JOURNEY,
        layerIds.PERSONA_JACK_STATIONS,
      ],
      projectionId: projectionIds.MERCATOR,
      renderingAdapterId: renderingAdapterIds.SVG,
      mapThemeId: mapThemeIds.PERSONA_JACK,
      center: [0, 0],
      rotation: [-1.3135528564453125, -51.125290150637966, 0],
      zoom: 1,
      wizardStepProgressIdx: 0,
      version: '1.0.0',
    },
  },
]
